import * as React from 'react';
import { twMerge } from 'tailwind-merge';
import ErrorMessage from './ErrorMessage';

const LabeledInput = ({
  label,
  labelClassName,
  id,
  wrapperClassName,
  fieldName,
  register,
  errors,
  className,
  children,
  ...props
}) => {
  return (
    <div className={wrapperClassName}>
      {label && (
        <label
          htmlFor={id}
          className={twMerge(
            labelClassName,
            'text-[#3F3F3F] text-base font-medium mb-2 block'
          )}
        >
          {label}
        </label>
      )}

      <input
        id={id}
        className={twMerge(
          'input input-bordered rounded mt-0 border-[#DFEAF2] w-full',
          className
        )}
        {...register(fieldName)}
        {...props}
      />
      {children}
      <ErrorMessage
        message={errors[fieldName]?.message}
        isVisible={Boolean(errors[fieldName])}
      />
    </div>
  );
};

export default LabeledInput;

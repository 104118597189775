import { Outlet } from "react-router-dom";
import MobileNavBar from "../../Common/MobileNavBar/MobileNavBar";
import NavSideBar from "../../Common/NavSideBar";
import MobileAdminInfo from "./MobileAdminInfo";
import { AdminDataProvider } from "../../../providers/AdminDataProvider";

const AdminLayout = () => {
  return (
    <AdminDataProvider>
      <div className="bg-[#FAFBFF] gap-x-7 items-start flex tablet:flex-nowrap flex-wrap">
        <NavSideBar />
        <MobileNavBar>
          <MobileAdminInfo />
        </MobileNavBar>

        <div
          className={`relative z-[98] tablet:px-[2rem] px-[5%] w-[100%]  tablet:mt-[1.2rem] mt-[6rem] pb-[3rem] tablet:rounded-xl tablet:h-fit h-full min-h-[90vh] flex-1 overflow-hidden`}
        >
          <Outlet />
        </div>
      </div>
    </AdminDataProvider>
  );
};

export default AdminLayout;

import React, { useEffect } from 'react';
import CustomButton from '../../../../shared/CustomButton';
import LabeledInput from '../../../../shared/LabeledInput';
import SelectInput from '../../../../shared/SelectInput';
import DateTimePicker from '../AddWebinarForm/DateTimePicker';
import { useEditWebinarForm } from './useEditWebinarForm';
import { useWatch } from 'react-hook-form';
import { useGetSelectionWebinarAddData } from '../AddWebinarForm/useGetSelectionData';

export default function EditWebinarForm({ id, closeDialog, webinar, refetch }) {
  const {
    register,
    handleSubmit,
    setValue,
    control,
    formState: { errors },
    onSubmit,
    isPending,
  } = useEditWebinarForm({ id, webinar, closeDialog, refetch });

  const { selectionOptions } = useGetSelectionWebinarAddData();

  useEffect(() => {
    if (webinar) {
      setValue('name', webinar.name || '');
      setValue('link', webinar.link || '');
      setValue('date', webinar.date || '');
      setValue('time', webinar.time || '');
      setValue('instructor_id', webinar.instructor_id || '');
      setValue('job_profile_round', webinar.job_profile_round || '');
      setValue('group', webinar.group || '');
      // Set status based on is_finished (completed if true)
      setValue('status', webinar.is_finished ? 'completed' : 'upcoming');
    }
  }, [webinar, setValue]);

  // Watch status to decide what to render.
  const status = useWatch({
    control,
    name: 'status',
    defaultValue: webinar?.is_finished ? 'completed' : 'upcoming',
  });

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id='edit-webinar-form'>
        {/* Status Dropdown – disable if webinar is already completed */}
        <SelectInput
          items={[
            { name: 'Upcoming', value: 'upcoming' },
            { name: 'Completed', value: 'completed' },
          ]}
          name='status'
          register={register}
          placeholderText='Select Status'
          errors={errors}
          className='mb-4'
          disabled={webinar?.is_finished} // Prevent changing back if completed
        />
        {status === 'completed' ? (
          // When completed, only allow editing of the record link.
          <LabeledInput
            label='Record Link'
            id='link' // Using "link" for record link as expected by the API
            register={register}
            placeholder='Enter record link'
            fieldName='link'
            errors={errors}
            className='mb-4'
          />
        ) : (
          // For upcoming webinars, render all inputs.
          <>
            <LabeledInput
              id='name'
              register={register}
              placeholder='Webinar Topic'
              fieldName='name'
              errors={errors}
              className='mb-4'
            />
            <LabeledInput
              id='link'
              register={register}
              placeholder='Webinar Link'
              fieldName='link'
              errors={errors}
              className='mb-4'
            />
            <DateTimePicker
              control={control}
              name='date'
              errors={errors}
              type='date'
            />
            <DateTimePicker
              control={control}
              name='time'
              errors={errors}
              label='Start Time'
              type='time'
            />
            <SelectInput
              items={selectionOptions.coach}
              name='instructor_id'
              register={register}
              placeholderText='Choose Coach'
              errors={errors}
              className='mb-4'
            />
            <SelectInput
              items={selectionOptions.jobProfileRound}
              name='job_profile_round'
              register={register}
              placeholderText='Choose Job Profile Round'
              errors={errors}
              className='mb-4'
            />
            <SelectInput
              items={selectionOptions.group}
              name='group'
              register={register}
              placeholderText='Choose Group'
              errors={errors}
              className='mb-4'
            />
          </>
        )}
      </form>

      <div className='flex justify-center mt-6 gap-4'>
        <CustomButton
          form='edit-webinar-form'
          type='submit'
          className='font-bold px-4 text-sm block py-4 mt-5'
          isLoading={isPending}
        >
          Edit Webinar
        </CustomButton>
        <CustomButton
          onClick={() => closeDialog()}
          className='font-bold px-4 text-sm block py-4 mt-5 bg-[#FFFFFF] border border-solid border-[#EE201C] text-[#EE201C] hover:bg-red-100'
        >
          Cancel
        </CustomButton>
      </div>
    </div>
  );
}

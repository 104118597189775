import React from 'react';
import { useParams } from 'react-router-dom';
import useFetch from '../../../hooks/useFetch';
import { dashboardInstructorsEndPoint } from '../../../Services/endPoints';
import NotFoundPage from '../../Common/NotFoundPage';
import AllInstructorDetails from '../components/AllInstructorDetails';
import TechInstructorDetails from '../components/TechInstructorDetails';
import WebinarInstructorDetails from '../components/WebinarInstructorDetails';

export default function InstructorDetails() {
  const { id } = useParams();
  const {
    data: instructorDetails,
    loading: instructorDetailsLoading,
    error: instructorDetailsError,
  } = useFetch(`${dashboardInstructorsEndPoint}${id}/`);

  if (instructorDetailsLoading) return <p>Loading...</p>;
  if (instructorDetailsError) return <NotFoundPage />;

  switch (instructorDetails?.role) {
    case 'Q/A':
      return <TechInstructorDetails instructorDetails={instructorDetails} />;
    case 'Webinar':
      return <WebinarInstructorDetails instructorDetails={instructorDetails} />;
    case 'All':
      return <AllInstructorDetails instructorDetails={instructorDetails} />;
    default:
      return <p>Unknown instructor role.</p>;
  }
}

import { useState } from "react";
import { Link } from "react-router-dom";
import UnwatchedIcon from "../../../../Components/svgs/UnWatched";
import VideoIcon from "../../../../Components/svgs/VideoIcon";
import WatchedIcon from "../../../../Components/svgs/WatchedIcon";
import { CompleteLessonEndPoint } from "../../../../Services/endPoints";
import styles from "./CourseVideoLessons.module.css";
import axios from "axios";
const Lesson = ({ lesson, index, activeLesson }) => {
  const [isCompleted, setIsCompleted] = useState(false);
  const completeLesson = async (e, lesson) => {
    e.preventDefault();
    e.stopPropagation();
    setIsCompleted(true);
    await axios.post(CompleteLessonEndPoint, {
      lesson: lesson.id,
    });
  };
  return (
    <li
      key={lesson.id}
      className={`${styles.lessonItem} ${
        index === activeLesson ? styles.activeLesson : ""
      }`}
    >
      <Link to={`/course/lesson/${lesson.id}`} className="flex items-center">
        <span className={styles.icon}>
          {(lesson.progress && lesson.progress.completed) || isCompleted ? (
            <WatchedIcon className="w-6" />
          ) : (
            <button onClick={(e) => completeLesson(e, lesson)}>
              <UnwatchedIcon className="w-6" />
            </button>
          )}
        </span>

        <div className="flex gap-2 items-center">
          <VideoIcon className="block" />
          <p className="text-lg text-black-12 flex-1">{lesson.title}</p>
        </div>
      </Link>
    </li>
  );
};

export default Lesson;

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiUpdateJobStatus } from "../../../Services/Students/apiUpdateJobStatus";

export const useUpdateJobStatus = () => {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: apiUpdateJobStatus,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["gigs"] });
    },
  });
};

import CustomButton from "../../shared/CustomButton";
import { DialogProvider } from "../../shared/CustomDialog";
import { BsFillPlusSquareFill } from "react-icons/bs";
import CPPublishAnnouncementForm from "./CPPublishAnnouncementForm";

const CPPublishAnnouncementDialog = (props) => {
  return (
    <DialogProvider
      title="Publish Announcement"
      className="w-[90%] pb-[1rem] sm:w-[35rem] min-w-[20rem] flex-col"
      titleClassName="text-2xl font-bold text-[#3E3E3E] pb-2"
      contentClassName="flex-1 flex flex-col"
      renderOpenButton={(openDialog) => (
        <CustomButton
          className="w-fit px-6 py-7 flex items-center justify-center gap-2 whitespace-nowrap rounded-md "
          onClick={openDialog}
        >
          <BsFillPlusSquareFill className="size-[1.2rem]" />
          Publish Announcement
        </CustomButton>
      )}
    >
      <CPPublishAnnouncementForm {...props} />
    </DialogProvider>
  );
};

export default CPPublishAnnouncementDialog;

import React from 'react';
import { useSearchParams } from 'react-router-dom';
import GigsIcon from '../../../Components/svgs/GigsIcon';
import {
  dashboardGigsStatisticsEndpoint,
  dashboardGigsTableEndpoint,
} from '../../../Services/endPoints';
import useFetch from '../../../hooks/useFetch';
import usePaginationNumbers from '../../../hooks/usePaginationNumbers';
import SearchInput from '../Common/SearchInput/SearchInput';
import Table from '../Common/Table/Table';
import TableSkeleton from '../Common/Table/TableSkeleton';
import CardsInfo from '../components/CardsInfo';
import GigsDetails from '../components/GigsPage/GigsDetails';
import GigsFilter from '../components/GigsPage/GigsFilter';
import Header from '../components/Header';

const DashboardGigsPage = () => {
  const [searchParams] = useSearchParams();

  const { data: gigsStatisticsData } = useFetch(
    dashboardGigsStatisticsEndpoint
  );

  const dynamicGigsEndpoint = `${dashboardGigsTableEndpoint}?${searchParams.toString()}`;
  const { data: count } = useFetch(dynamicGigsEndpoint);

  const {
    data: dashboardGigsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicGigsEndpoint);

  const results = dashboardGigsData || [];

  // Table Columns
  const tableColumns = [
    { header: 'Student Name', accessor: 'name' },
    { header: 'Email', accessor: 'email' },
    { header: 'Platform', accessor: 'platform' },
    { header: 'Price', accessor: 'price' },
    { header: 'Gig title', accessor: 'gigTitle' },
    { header: 'Admin Status', accessor: 'adminStatus' },
    { header: 'Rate', accessor: 'rate' },
    { header: 'Student Status', accessor: 'status' },
    { header: 'View Details', accessor: 'details' },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((student) => ({
    name: student.student_name,
    email: student.student_email,
    platform: student.platform,
    price: student.price,
    gigTitle: student.title || 'N/A',
    adminStatus: student.admin_status,
    rate: student?.rate?.rate || '–	',
    status: student.status || 'None',
    details: (
      <GigsDetails
        name={student.student_name}
        email={student.student_email}
        url={student.url}
        proposal_file={student.proposal_file}
        status_proof_screenshot={student.status_proof_screenshot}
        title={student.title}
        date={student.created_on}
        platform={student.platform}
        price={student.price}
        buttonType='single'
        comment={student.comment}
        rate={student.rate}
      />
    ),
  }));

  const gigsCards = [
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Total gigs',
      value: gigsStatisticsData?.total_freelance_jobs || '0',
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Rejected by admin',
      value: gigsStatisticsData?.rejected_freelance || '0',
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Waiting for admin review',
      value: gigsStatisticsData?.pending_freelance,
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Approved by admin',
      value: (
        <span className='text-[#259800]'>
          {gigsStatisticsData?.completed_freelance}
        </span>
      ),
    },
  ];

  return (
    <>
      <Header>
        <CardsInfo cards={gigsCards} />
      </Header>
      {/* Filters Component */}
      <div className='w-full flex justify-between flex-wrap'>
        <GigsFilter count={count?.count} />
        <SearchInput
          name={'student_email'}
          placeholder='Search by student email'
        />
      </div>
      <div className='mt-6'>
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
};

export default DashboardGigsPage;

import CustomButton from "../../shared/CustomButton";
import { DialogProvider } from "../../shared/CustomDialog";
import CPEditAnnouncementForm from "./CPEditAnnouncementForm";

const CPEditAnnouncementDialog = (props) => {
  return (
    <DialogProvider
      title="Edit Announcement"
      className="w-[90%] pb-[1rem] sm:w-[35rem] min-w-[20rem] flex-col"
      titleClassName="text-2xl font-bold text-[#3E3E3E] pb-2"
      contentClassName="flex-1 flex flex-col"
      renderOpenButton={(openDialog) => (
        <CustomButton onClick={openDialog}>Edit</CustomButton>
      )}
    >
      <CPEditAnnouncementForm {...props} />
    </DialogProvider>
  );
};

export default CPEditAnnouncementDialog;

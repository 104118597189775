import React, { useEffect, useRef } from 'react';
import { MdOutlineTask } from 'react-icons/md';
import CustomButton from '../../../Components/shared/CustomButton';

const UploadProofDialog = ({
  isOpen,
  onClose,
  fileName,
  handleFileChangeStatusProofFile,
  fileUploadError,
  handleAcceptedFileSubmit,
  loading,
}) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={dialogRef}
      className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[35rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-7 overflow-visible'
    >
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-xl text-center text-[#273C97] font-bold'>
          Amazing Work! 🚀
        </h2>
        <button
          onClick={onClose}
          className='text-[#71747B] hover:text-gray-900'
        >
          ✖
        </button>
      </div>
      <p className='font-[1.5rem] text-center w-[85%] m-auto mb-4'>
        Upload proof of your client’s acceptance to celebrate this milestone and
        keep moving forward in your journey!
      </p>
      <div className='w-full max-w-xs mx-auto'>
        {fileName && (
          <p className='my-4 flex items-center'>
            <MdOutlineTask className='size-5 text-gray-7e' /> {fileName}
          </p>
        )}

        <label className='flex flex-col items-center px-4 py-2 bg-[#EBEBEB] text-black rounded-lg cursor-pointer'>
          <span className='text-base leading-normal'>
            {!fileName ? 'Upload File' : 'Upload Other File'}
          </span>
          <input
            type='file'
            accept='application/pdf,image/*'
            onChange={handleFileChangeStatusProofFile}
            className='hidden'
          />
        </label>

        <p className='text-center text-[.8rem] text-[#999898] mt-2'>
          You can upload a file (PDF, JPG, PNG, etc...)
        </p>

        {fileUploadError && (
          <p className='mt-2 text-sm text-red-600 text-center'>
            {fileUploadError}
          </p>
        )}
      </div>

      <div className='flex justify-end gap-2 mt-5'>
        <CustomButton
          onClick={handleAcceptedFileSubmit}
          className='py-3 text-sm'
          isLoading={loading}
        >
          Submit
        </CustomButton>

        <button
          style={{ border: '1px solid red' }}
          onClick={onClose}
          className='text-red-700 font-medium rounded-lg text-sm px-5 py-2.5'
        >
          Close
        </button>
      </div>
    </dialog>
  );
};

export default UploadProofDialog;

import { DialogProvider } from "../CustomDialog";
import DeleteDialogActions from "./DeleteDialogActions";

const DeleteConfirmationDialog = ({
  renderOpenButton,
  name,
  deleteMutation,
}) => {
  return (
    <DialogProvider
      title="Delete Confirmation"
      headerClassName="pt-[1rem] bg-transparent border-none"
      titleClassName="text-2xl font-bold text-[#3E3E3E]"
      className="w-full pt-2 min-h-[12rem]"
      contentClassName="ms-1 flex flex-col flex-1"
      renderOpenButton={renderOpenButton}
    >
      Are you sure you want to delete {name} ?
      <DeleteDialogActions deleteMutation={deleteMutation} />
    </DialogProvider>
  );
};

export default DeleteConfirmationDialog;

import React, { useRef, useState } from 'react';
import CustomButton from '../../../../Components/shared/CustomButton';
import { FaPlus } from 'react-icons/fa6';
import AddNewGigForm from './AddNewGigForm';

const AddJobDialog = () => {
  const dialogRef = useRef(null);
  const [openDialog, setOpenDialog] = useState(false);

  const openDialogHandle = () => {
    setOpenDialog(true);
    dialogRef.current?.showModal();
  };

  const closeDialogHandle = () => {
    setOpenDialog(false);
    dialogRef.current?.close();
  };

  return (
    <div>
      <CustomButton
        onClick={openDialogHandle}
        className='bg-[#eaf4ff] text-[#6c6c6c] text-sm font-bold p-8 rounded-lg w-[55%] hover:bg-[#d4e8ff] flex items-center justify-center flex-col gap-6'
      >
        <div className='bg-white w-fit rounded-full p-2 mt-[-3rem]'>
          <FaPlus className='size-5' />
        </div>
        Add any freelance opportunities you've had or completed!
      </CustomButton>
      <dialog
        ref={dialogRef}
        className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[35rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-7 overflow-visible'
      >
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-[#3F3F3F] font-bold text-base'>Add New Job</h1>
          <button
            onClick={closeDialogHandle}
            className='text-[#71747B] hover:text-gray-900'
          >
            ✖
          </button>
        </div>
        {openDialog && (
          <div className='w-full text-left'>
            <AddNewGigForm
              openDialog={openDialog}
              closeDialogHandle={closeDialogHandle}
            />
          </div>
        )}
      </dialog>
    </div>
  );
};

export default AddJobDialog;

import React from 'react';
import Details1 from '../../../Components/svgs/Details1';
import Details2 from '../../../Components/svgs/Details2';
import Details3 from '../../../Components/svgs/Details3';
import Details4 from '../../../Components/svgs/Details4';
import { useTranslation } from 'react-i18next';

export default function DetailsSection() {
    const { t } = useTranslation();

    return (
        <div id="about" className='custom-container sm:py-20 my-24 sm:text-center'>
            <h3 className='text-base font-bold text-[#8A898E] mb-4'>
                {t('detailsSubtitle')}
            </h3>
            <h2 className='text-3xl font-bold text-[#273C97] mb-12'>
                {t('detailsTitle')}
            </h2>

            <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 m-auto gap-8'>
                {[Details1, Details2, Details3, Details4].map((DetailIcon, index) => (
                    <div
                        key={index}
                        className='border border-[#FFC107] w-3/4 sm:w-auto m-auto p-4 rounded-3xl flex flex-col gap-7 items-center transition-all duration-300 hover:scale-105 hover:shadow-lg h-[100%] hover:border-[#FFC10766]'
                    >
                        <DetailIcon />
                        <h2 className='text-3xl font-bold text-[#273C97]'>
                            {t(`detailsTitle${index + 1}`)}
                        </h2>
                        <span className='text-base text-center font-normal text-[#000000]'>
                            {t(`detailsText${index + 1}`)}
                        </span>
                    </div>
                ))}
            </div>
        </div>
    );
}

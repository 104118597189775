// Student profile
export const UserProfileURL = "/user/profile/";
export const freelanceProfileLinksEndpoint = "/freelance-profile-links/";
export const freelancePlatformsEndpoint = "/platforms/";
export const CoachingRoundEndPoint = "/coaching-rounds/";
// next Webinars
export const NextWebinars = "/webinars/next/";
// QA
export const categoryEndPoint = "/category/";
export const questionEndPoint = "/question/";
// webinar
export const allWebinarsURL = "/webinars/all";
export const allWebinarsFeedbackURL = "/webinar-feedback/";
export const webinarAttendanceEndPoint = "/webinar-attendance/";
//Jobs
export const getJobsCategoryEndPoint = "/freelance-category/";
export const postFreelanceJobEndPoint = "/freelance-jobs/";
export const getFreelanceJobMyJobsEndPoint = "/freelance-jobs/";
export const patchStatusMyJobsEndPoint = "/freelance-jobs";
export const deleteFreelanceJobEndPoint = "/freelance-jobs/delete";
// Course student view
export const CourseHomeEndPoint = "/course/home/";
export const CourseStudentEndPoint = "/course/student/";
export const CompleteLessonEndPoint = "/course/complete-lesson/";

// Settings
export const UserChangeProfilePicture = "/user/change-profile-image/";
export const UserChangePassword = "/user/change-password/";
export const UserRequestSupportEndPoint = "/user/request-support/";

// Deliverables
export const UserDeliverablesStudents = "/deliverable-results/student/";
export const GetDeliverablesById = "/deliverables/";
export const PostDeliverableAttachments = "/deliverable-attachments/";
export const GetDeliverableResultsById = "/deliverable-feedback/";
export const TasksName = "/deliverable-needfeedback/";

//Ai Reviewer History
export const AiReviewerGetAll = "/proposal/";
// Instructor Deliverables
// Instructor Review
export const DeliverablesInstructorEndPoint = "/deliverable-results/";
export const DeliverablesFeedBackEndPoint = "/deliverable-feedback/";
export const DeliverablesUpdateFeedBackEndPoint =
  "/deliverable-feedback-details/";

// question Instructor
export const QAInstructorEndPoint = "/question/filter/";
export const QAAnsweEndPoint = "question/answers/";
export const qaDeleteQuestionEndPoint = "/question/delete/";

// instructor webinar
export const InstructorWebinar = "/webinars/";

// admin
export const dashboardInstructorsEndPoint = "/dashboard/instructors/";
export const dashboardInstructorsStatisticsEndPoint =
  "/dashboard/instructor-statistics/";
export const dashboardStudentsEndPoint = "/dashboard/students/";
export const dashboardSingleStudentStatisticsEndPoint =
  "/dashboard/single-student-statistics/";
export const jobProfileRoundsEndPoint = "/job-profile-rounds/";
export const dashboardStudentsTechProvider = "/student-technical-provider/";
export const dashboardStudentsTrack = "/track/";
export const dashboardStudentsStatisticsEndPoint =
  "/dashboard/student-statistics/";
export const dashboardWebinarsStatisticsEndPoint =
  "/dashboard/webinar-statistics/";

export const dashboardWebinars = "/dashboard/webinars/";
export const dashboardLeadsEndPoint = "/api/leads/";
export const dashboardLeadsStatisticsEndPoint = "/api/leads-statistics/";
export const dashboardAIInterveiwingEndPoint = "/api/leads-details/";
export const dashboardReviewsEndPoint = "/dashboard/reviews/";
export const dashboardReviewsStatisticsEndPoint =
  "/dashboard/reviews-statistics/";
export const dashboardQAStatisticsNumbersEndPoint =
  "/dashboard/questions-statistics/";
export const dashboardQATableEndPoint = "/dashboard/questions/";
export const dashboardSingleStudentDeliverablesEndPoint =
  "/dashboard/student/deliverables/";
export const dashboardSingleStudentFreelanceProfilesEndPoint =
  "/dashboard/student/freelance-profiles/";
export const dashboardSingleStudentDeliverableFeedbackEndPoint =
  "/dashboard/student/deliverablefeedback/";
export const dashboardSingleStudentWebinarEndPoint =
  "/dashboard/student/webinar/";
export const dashboardSingleStudentQuestionEndPoint =
  "/dashboard/student/question/";
export const dashboardSingleStudentGigsEndPoint =
  "/dashboard/student/freelance-jobs/";

export const controlPanelStudentDetailsEndpoint = "/user/student-profile/";
export const dashboardGigsStatisticsEndpoint =
  "/dashboard/freelance-jobs-statistics/";
export const dashboardGigsTableEndpoint = "/dashboard/freelance-jobs/";
export const dashboardControlPanelGigsTableEndpoint =
  "/dashboard/freelance-jobs-panel/";

export const dashboardGigsStatusEndpoint = "/dashboard/freelance-jobs-status/";
export const controlPanelInstructorDetailsEndpoint =
  "/user/instructor-profile/";
export const checkPasswordEndPoint = "/user/check-password/";
export const controlPanelChangePasswordEndPoint = "/user/change-password-user/";
export const controlPanelRejectGigEndpoint =
  "/dashboard/freelance-jobs/reject/";
export const controlPanelApproveGigEndpoint =
  "/dashboard/freelance-jobs/approve/";

export const leadsChangeStatusEndpoint = "/api/leads-change-status/";

export const instructorChoicesEndPoint = "/dashboard/instructor-choices/";
export const instructorJobRoundChoicesEndPoint =
  "/dashboard/instructor-job-round-choices/";
export const createJobProfileEndPoint = "dashboard/instructor-job-round/";

export const coachRoundCsv = "dashboard/instructor-coaching-round/";
export const uploadStudentsCsv = "user/student-register-csv/";

export const dashboardStats = "dashboard/statistics/";

export const city = "user/city/";

export const loginEndPoint = "user/api/token/";

export const announcementsEndpoint = "announcements/";

export const lastAnnouncementsEndpoint = "announcements/last/";

import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import { useCourseLayoutContext } from "../CourseLayoutProvider";
import styles from "./CourseVideoLessons.module.css";

import Lesson from "./Lesson";

function CourseVideoLessons() {
  const { id } = useParams();
  const [activeLesson, setActiveLesson] = useState(0);

  const { courseData } = useCourseLayoutContext();

  useEffect(() => {
    if (courseData?.lessons?.length > 0) {
      const lessonIndex = courseData.lessons.findIndex(
        (lesson) => lesson.id === parseInt(id)
      );
      setActiveLesson(lessonIndex);
    }
  }, [courseData, id]);

  return (
    <div className={styles.courseVideoLessons}>
      <h2 className="font-bold text-xl text-[#1F2029] text-left my-6">
        {courseData?.name || "Course"}
      </h2>
      <ul className={styles.lessonList}>
        {courseData?.lessons?.map((lesson, index) => (
          <Lesson
            key={lesson.id}
            lesson={lesson}
            index={index}
            activeLesson={activeLesson}
          />
        ))}
      </ul>
    </div>
  );
}

export default CourseVideoLessons;

import { useAdminDataContext } from "../../providers/AdminDataProvider";
import FilterGroup from "../AdminDashboard/shared/Filters/FilterGroup";

const CPAnnouncementsFilter = () => {
  const { coachingRounds, jobProfiles } = useAdminDataContext();
  const filters = [
    {
      name: "Coaching Round",
      key: "coaching_rounds",
      options: coachingRounds,
    },
    {
      name: "Job Profile Code",
      key: "job_profile_rounds",
      options: jobProfiles,
    },
    {
      name: "Status",
      key: "status",
      options: ["Active", "Expired"],
    },
  ];
  return <FilterGroup filters={filters} />;
};

export default CPAnnouncementsFilter;

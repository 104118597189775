import Rate from "./Rate";
import { useForm } from "react-hook-form";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiAddReview } from "../../../../Services/gigs-proposals";
import CustomButton from "../../../../Components/shared/CustomButton";
import { toast } from "react-toastify";

function AddRateForm({ onClose, id }) {
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    formState: { errors },
  } = useForm({
    comment: "",
    rate: 1,
  });

  const rate = watch("rate");
  const queryClient = useQueryClient();

  const { mutate, isPending } = useMutation({
    mutationFn: (data) => apiAddReview(id, data),
  });

  const onSubmit = handleSubmit((data) => {
    mutate(data, {
      onSuccess: (res) => {
        queryClient.invalidateQueries({ queryKey: ["gigs-proposals"] });
        onClose();
        toast.success("Rate added successfully");
      },
      onError: (err) => {
        toast.error("Error while adding your rate");
      },
    });
  });

  return (
    <form className="space-y-4 px-6 pb-4" onSubmit={onSubmit} noValidate>
      <div className="flex flex-col gap-2">
        <label className="font-bold">Add Your Rate</label>
        <Rate rate={rate} setRate={(rate) => setValue("rate", rate)} />

        <textarea
          dir="auto"
          className="w-full min-h-10 max-h-52 p-2 border border-gray-300 rounded-md"
          placeholder="Add your comments..."
          {...register("comment", {
            required: { value: true, message: "Comment is required" },
          })}
        />
        {errors.comment && (
          <p className="text-error">{errors.comment?.message}</p>
        )}
      </div>

      <div className="flex justify-end gap-4">
        <CustomButton
          isLoading={isPending}
          className="min-w-[138px] font-semibold tracking-wider"
        >
          Submit
        </CustomButton>
        <CustomButton
          onClick={onClose}
          type="button"
          className="min-w-[138px] font-semibold tracking-wider border-error text-error bg-transparent hover:bg-error hover:text-white"
          style={{ border: "2px solid" }}
        >
          Cancel
        </CustomButton>
      </div>
    </form>
  );
}

export default AddRateForm;

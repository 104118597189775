import { useInfiniteQuery } from "@tanstack/react-query";
import CardGigsProposals from "../../Components/GigsProposals/CardGigsProposals";
import GigsProposalsFilter from "../../Components/GigsProposals/GigsProposalsFilter";
import { apiGetGigs } from "../../../../Services/apiGetGigs";
import SkeletonCard from "../../../../Components/shared/SkelltonCard";
import { useSearchParams } from "react-router-dom";
import ModalGigDetails from "../../Components/GigsProposals/ModalGigDetails";
import { useState } from "react";

function GigsProposals() {
  const [searchParams] = useSearchParams();

  const couchingRound = searchParams.get("coaching_round");
  const status = searchParams.get("status");

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["gigs-proposals", couchingRound, status],
    queryFn: ({ pageParam = 1 }) =>
      apiGetGigs(pageParam, { coaching_round: couchingRound, status: status }),
    getNextPageParam: (lastPage) => {
      if (!lastPage.next) {
        return undefined;
      }
      const nextURL = new URL(lastPage.next);
      const nextPage = nextURL.searchParams.get("page");
      return nextPage;
    },
    retry: 0,
    refetchOnWindowFocus: false,
  });

  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [detailsData, setDetailsData] = useState(null);

  if (isError) {
    return <p>Something went wrong while loading Gigs.</p>;
  }

  return (
    <>
      <div className="space-y-6">
        <GigsProposalsFilter />

        {isLoading || isFetching ? (
          <div className="flex flex-row gap-4 lg:flex-nowrap flex-wrap">
            <div className="flex flex-row gap-4 mt-4 flex-grow basis-3/4">
              <div className="flex-grow basis-3/4">
                <SkeletonCard />
                <SkeletonCard />
                <SkeletonCard />
              </div>
            </div>
          </div>
        ) : (
          <>
            <div className="flex flex-wrap gap-4">
              {data.pages.map((page, index) =>
                page.results.map((gig, index) => (
                  <CardGigsProposals
                    key={index}
                    gig={gig}
                    onShowDetails={(data) => {
                      setShowDetailsModal(true);
                      setDetailsData(data);
                    }}
                  />
                ))
              )}
            </div>
            {hasNextPage && (
              <button
                onClick={() => fetchNextPage()}
                disabled={isFetchingNextPage}
                className="text-[#2D7CDE] text-base mt-2"
              >
                {isFetchingNextPage ? "Loading more..." : "Show more..."}
              </button>
            )}
          </>
        )}
      </div>

      <ModalGigDetails
        open={showDetailsModal}
        onClose={() => setShowDetailsModal(false)}
        data={detailsData}
      />
    </>
  );
}

export default GigsProposals;

import { useSearchParams } from 'react-router-dom';
import Dropdown from '../../../shared/Dropdown';
import ArrowDown from '../../../svgs/ArrowDown';

const Filter = ({ filter }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const handleSelectFilter = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };
  if (filter.component) {
    return filter.component;
  }
  return (
    <Dropdown
      renderOpenButton={(toggleMenu) => (
        <button
          onClick={toggleMenu}
          className='px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4'
        >
          <span className='block'>{filter.name}</span>
          <ArrowDown className='size-[1rem] text-[#868686]' />
        </button>
      )}
    >
      {filter?.options?.map((option) => (
        <div
          key={option?.value ?? option}
          onClick={() => handleSelectFilter(filter.key, option.value ?? option)}
          className='px-4 py-2 text-sm cursor-pointer hover:bg-gray-100'
        >
          {option.name ?? option}
        </div>
      ))}
    </Dropdown>
  );
};

export default Filter;

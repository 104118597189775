import { useInfiniteQuery, useQueryClient } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { apiGetGigs } from "../../../Services/apiGetGigs";
import CardSkeleton from "../../InsturctorDashboard/Components/Common/Card/CardSkeleton";
import AddJobDialog from "./AddJob/AddJobDialog";
import EditJobDialog from "./EditJobDialog";
import JobCard from "./JobCard";
import styles from "./Jobs.module.css";
import JobStatusGuide from "./JobStatusGuide";
import UploadProofDialog from "./UploadProofDialog";
import { useEditJob } from "./useEditJob";
import { useUpdateJobStatus } from "./useUpdateJobStatus";

function Jobs() {
  const queryClient = useQueryClient();
  const { ref, inView } = useInView();
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [fileDialogOpen, setFileDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const [selectedJobId, setSelectedJobId] = useState(null);
  const [statusProofFile, setStatusProofFile] = useState(null);
  const [fileNameStatusProofFile, setFileNameStatusProofFile] = useState("");
  const [fileUploadErrorStatusProofFile, setFileUploadErrorStatusProofFile] =
    useState("");
  const [editDialogOpen, setEditDialogOpen] = useState(false);
  const [selectedJob, setSelectedJob] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["gigs"],
    queryFn: ({ pageParam = "1" }) => apiGetGigs(pageParam),
    initialPageParam: "1",
    getNextPageParam: (lastPage) => {
      if (lastPage.next === null) return undefined;
      const parsedUrl = new URL(lastPage.next);
      const pageNumber = parsedUrl.searchParams.get("page");
      return pageNumber;
    },
  });
  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  const { mutate: editJob } = useEditJob();
  const { mutate: updateStatus } = useUpdateJobStatus();

  const handleCommentsOpen = () => {
    setCommentsOpen(true);
  };

  const handleStatusChange = (index, event, jobId) => {
    const newStatus = event.target.value;

    if (newStatus === "Accepted") {
      setFileDialogOpen(true);
      setSelectedJobId(jobId);
    } else {
      updateStatus(
        { jobId, newStatus },
        {
          onError: (err) => {
            console.error("Error updating status:", err);
          },
        }
      );
    }
  };

  const handleFileChangeStatusProofFile = (e) => {
    const file = e.target.files[0];
    if (file) {
      setFileNameStatusProofFile(file.name);
      setStatusProofFile(file);
      setFileUploadErrorStatusProofFile("");
    }
  };

  const handleAcceptedFileSubmit = async () => {
    if (!statusProofFile) {
      setFileUploadErrorStatusProofFile(
        "Please submit file to review for accepted Jobs."
      );
      return;
    }

    const formData = new FormData();
    formData.append("status", "Accepted");
    formData.append("status_proof_screenshot", statusProofFile);

    try {
      setLoading(true);
      editJob(
        { id: selectedJobId, formData },
        {
          onSuccess: () => {
            queryClient.invalidateQueries({ queryKey: ["gigs"] });
            setLoading(false);
            setFileDialogOpen(false);
            setStatusProofFile(null);
            setFileNameStatusProofFile("");
            setSelectedJobId(null);
            setFileUploadErrorStatusProofFile("");
          },
          onError: (err) => {
            setLoading(false);
            console.error("Error updating job status with file:", err);
          },
        }
      );
    } catch (error) {
      console.error("Error updating job status with file:", error);
    }
  };

  const handleEditClick = (job) => {
    setSelectedJob(job);
    setEditDialogOpen(true);
  };

  return (
    <>
      <div className="flex my-8 gap-4 justify-between items-center">
        <AddJobDialog />
        <JobStatusGuide />
      </div>

      <div className="grid sm:grid-cols-2 grid-cols-1 gap-[2rem]">
        {isLoading || isFetching ? (
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton key={index} />)
        ) : isError ? (
          <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            Something went wrong !
          </p>
        ) : (
          !isLoading &&
          !isFetching &&
          data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.map((job, index) => (
                <JobCard
                  key={job.id}
                  job={job}
                  onStatusChange={(e, jobId) =>
                    handleStatusChange(index, e, jobId)
                  }
                  onEditClick={handleEditClick}
                  onCommentsOpen={handleCommentsOpen}
                />
              ))}
            </React.Fragment>
          ))
        )}
        {isFetchingNextPage &&
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton />)}
        <div ref={ref} style={{ height: "20px", background: "transparent" }} />
      </div>

      <UploadProofDialog
        isOpen={fileDialogOpen}
        onClose={() => setFileDialogOpen(false)}
        fileName={fileNameStatusProofFile}
        fileUploadError={fileUploadErrorStatusProofFile}
        handleFileChangeStatusProofFile={handleFileChangeStatusProofFile}
        handleAcceptedFileSubmit={handleAcceptedFileSubmit}
        loading={loading}
      />

      <EditJobDialog
        isOpen={editDialogOpen}
        onClose={() => setEditDialogOpen(false)}
        selectedJob={selectedJob}
      />
    </>
  );
}

export default Jobs;

import React from "react";
import { Link } from "react-router-dom";
import BackBtn from "../../../Components/svgs/BackBtn";
import Clock from "../../../Components/svgs/Clock";
import Proposals from "../../../Components/svgs/Proposals";
import QAIconAdmin from "../../../Components/svgs/QAIconAdmin";
import InfoCard from "./InfoCard";
import StatisticsCard from "./StatisticsCard";
import StudentsRatings from "./StudentsRatings";

export default function AllInstructorDetails({ instructorDetails }) {
  const answeredData = [
    instructorDetails?.answers_count,
    instructorDetails?.not_answered_count,
  ];
  const answeredLabels = ["Answered", "Not Answered"];

  const reviewedData = [
    instructorDetails?.reviews_count,
    instructorDetails?.pending_count,
  ];
  const reviewedLabels = ["Reviewed", "Pending"];

  const webinarsData = [
    instructorDetails?.finished_webinars,
    instructorDetails?.upcoming_webinars,
  ];
  const webinarsLabel = ["Finished Webinars", "Upcoming Webinars"];

  const stats = [
    {
      icon: QAIconAdmin,
      label: "Q&A Answer",
      value: `${instructorDetails.answers_count} Out of ${instructorDetails.questions_count}`,
    },
    {
      icon: Clock,
      label: "Average Response Time",
      value: instructorDetails.answers_avg_response_time || "N/A",
    },
    {
      icon: Proposals,
      label: "Review Proposals",
      value: `${instructorDetails.reviews_count} Out of ${instructorDetails.proposal_count}`,
    },
    {
      icon: Clock,
      label: "Average Review Time",
      value: instructorDetails.reviews_avg_response_time || "N/A",
    },
  ];
  return (
    <>
      <div className="flex justify-between gap-4 items-center">
        <Link
          to="/instructors"
          className="flex gap-3 items-center btn-primary px-4 py-2 text-xl font-semibold"
        >
          <BackBtn />
          Back
        </Link>
      </div>

      <div className="grid grid-cols-1 sm:grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4 mt-8">
        <StatisticsCard
          title="Q & A Statistics"
          description="This section provides insights into instructor engagement by tracking the number of student questions answered, unanswered, and the average response time. Use this data to monitor performance and enhance student support."
          averageTimeTitle="Average Response Time"
          averageTimeValue={
            instructorDetails?.answers_avg_response_time || "N/A"
          }
          totalItemsLabel="Total number of Questions"
          totalItemsValue={instructorDetails?.questions_count}
          chartData={answeredData}
          chartLabels={answeredLabels}
          chartColors={["#273C97", "#FFC107"]}
        />

        <InfoCard
          profileImage={instructorDetails.profile_image}
          name={instructorDetails.name}
          phone={instructorDetails.phone}
          email={instructorDetails.user.email}
          stats={stats}
        />

        <StatisticsCard
          title="Review Proposals Statistics"
          description="This section highlights the progress of student proposal reviews. Track the percentage of reviewed and pending proposals, and monitor the average review time to ensure timely feedback."
          averageTimeTitle="Average Review Time"
          averageTimeValue={
            instructorDetails.reviews_avg_response_time || "N/A"
          }
          totalItemsLabel="Total number of Proposals"
          totalItemsValue={instructorDetails?.proposal_count}
          chartData={reviewedData}
          chartLabels={reviewedLabels}
          chartColors={["#273C97", "#FFC107"]}
        />

        <StatisticsCard
          title="Webinar Progress Tracker"
          description="Track instructor activity with a quick overview of finished and upcoming webinars, ensuring effective schedule management."
          totalItemsLabel="Total number of Questions"
          totalItemsValue={instructorDetails?.webinars_count}
          chartData={webinarsData}
          chartLabels={webinarsLabel}
          chartColors={["#273C97", "#FFC107"]}
        />

        <StudentsRatings
          averageRating={instructorDetails.average_feedbacks_rating}
          feedbackCount={instructorDetails.student_feedback_count}
          reviews={instructorDetails.student_reviews}
        />
      </div>
    </>
  );
}

import { useEffect, useState } from 'react';
import useFetch from '../../../../hooks/useFetch';
import FilterGroup from '../../../../Components/AdminDashboard/shared/Filters/FilterGroup';
import {
  CoachingRoundEndPoint,
  jobProfileRoundsEndPoint,
  dashboardGigsStatusEndpoint,
} from '../../../../Services/endPoints';

const initialFilters = [
  {
    name: 'Coaching Round',
    key: 'coaching_round',
  },
  {
    name: 'Job Profile',
    key: 'job_profile_round',
  },
  {
    name: 'Status',
    key: 'status',
  },
];

export default function GigsFilter({ count }) {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRounds } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);
  const { data: gigsStatus } = useFetch(dashboardGigsStatusEndpoint);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case 'coaching_round':
            return {
              ...filter,
              options: coachingRounds
                ? coachingRounds.map((round) => ({
                    name: round.code,
                    value: round.code,
                  }))
                : [],
            };
          case 'job_profile_round':
            return {
              ...filter,
              options: jobProfileRounds
                ? jobProfileRounds.map((round) => ({
                    name: round.code,
                    value: round.code,
                  }))
                : [],
            };
          case 'status':
            return {
              ...filter,
              options: gigsStatus
                ? gigsStatus.map((status) => ({ name: status, value: status }))
                : [],
            };
          default:
            return filter;
        }
      })
    );
  }, [coachingRounds, jobProfileRounds, gigsStatus]);

  return <FilterGroup filters={filters} count={count} />;
}

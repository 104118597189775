import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useCookies } from "react-cookie";
import { useForm } from "react-hook-form";
import { useNavigate } from "react-router-dom";
import * as yup from "yup";
import { pageRoutes } from "../../../constants";
import apiPostLogin from "./apiPostLogin";

const schema = yup.object({
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  password: yup.string().required("Password is required"),
});

const expireIn8Hours = 60 * 60 * 8;
const cookieOptions = {
  maxAge: expireIn8Hours,
  path: "/",
};

export const useLoginForm = () => {
  const navigate = useNavigate();
  const [_, setCookie] = useCookies([
    "access-token",
    "refresh-token",
    "role",
    "email",
  ]);

  const mutation = useMutation({
    mutationFn: apiPostLogin,
  });

  const { isPending } = mutation;

  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      email: "",
      password: "",
    },
  });

  const onSubmit = async (data) => {
    mutation.mutate(data, {
      onSuccess: (response) => {
        setCookie("access-token", response.access, cookieOptions);
        setCookie("refresh-token", response.refresh, cookieOptions);
        setCookie("role", response.role, cookieOptions);
        setCookie("email", data.email, cookieOptions);
        navigate(`/${pageRoutes.dashboard}`);
      },
      onError: (err) => {
        console.error("Login failed:", err);
        form.setError("root", {
          message: "Invalid credentials. Please try again.",
        });
      },
    });
  };
  return { ...form, onSubmit, isPending };
};

import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import {
  categoryEndPoint,
  CoachingRoundEndPoint,
} from '../../../../Services/endPoints';
import useFetch from '../../../../hooks/useFetch';

const QAFilter = ({ onFilterChange }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [selectedCategory, setSelectedCategory] = useState(
    searchParams.get('category') || ''
  );
  const [selectedAnswerStatus, setSelectedAnswerStatus] = useState(
    searchParams.get('answered') || ''
  );
  const [selectedCoachingRound, setSelectedCoachingRound] = useState(
    searchParams.get('coachingRound') || ''
  );
  const { data: categoriesData } = useFetch(categoryEndPoint);
  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const coachingRounds = Array.isArray(coachingRoundsData?.results)
    ? coachingRoundsData.results
    : [];

  const updateFilters = (newFilter) => {
    const currentParams = Object.fromEntries(searchParams.entries());
    const updatedFilters = { ...currentParams, ...newFilter };

    Object.keys(updatedFilters).forEach((key) => {
      if (!updatedFilters[key]) delete updatedFilters[key];
    });

    setSearchParams(updatedFilters);
    onFilterChange(updatedFilters);
  };

  const handleCategoryChange = (e) => {
    const value = e.target.value;
    setSelectedCategory(value);
    updateFilters({ category: value });
  };

  const handleCoachingRoundChange = (e) => {
    const value = e.target.value;
    setSelectedCoachingRound(value);
    updateFilters({ coachingRound: value });
  };

  const handleAnswerStatusChange = (e) => {
    const value = e.target.value;
    setSelectedAnswerStatus(value);
    updateFilters({ answered: value });
  };

  const clearFilters = () => {
    setSelectedCategory('');
    setSelectedAnswerStatus('');
    setSelectedCoachingRound('');
    setSearchParams({});
    onFilterChange({});
  };

  const removeFilter = (filterKey) => {
    const updatedFilters = {
      category: selectedCategory,
      coachingRound: selectedCoachingRound,
      answered: selectedAnswerStatus,
    };
    updatedFilters[filterKey] = '';
    if (filterKey === 'category') setSelectedCategory('');
    if (filterKey === 'coachingRound') setSelectedCoachingRound('');
    if (filterKey === 'answered') setSelectedAnswerStatus('');

    updateFilters(updatedFilters);
  };

  return (
    <div className='flex flex-col gap-4 mb-4'>
      <div className='flex gap-4'>
        <select
          value={selectedCategory}
          onChange={handleCategoryChange}
          className='border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl'
        >
          <option value=''>Category</option>
          {categoriesData?.map((category) => (
            <option key={category.id} value={category.id}>
              {category.name}
            </option>
          ))}
        </select>

        <select
          value={selectedCoachingRound}
          onChange={handleCoachingRoundChange}
          className='border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl'
        >
          <option value=''>Coaching Rounds</option>
          {coachingRounds &&
            coachingRounds?.map((round, index) => (
              <option key={index} value={round.code}>
                {round.code}
              </option>
            ))}
        </select>

        <select
          value={selectedAnswerStatus}
          onChange={handleAnswerStatusChange}
          className='border p-2 bg-white border-gray-300 text-gray-900 text-sm rounded-xl'
        >
          <option value=''>Answer Status</option>
          <option value='true'>Answered</option>
          <option value='false'>Not Answered</option>
        </select>

        <button
          onClick={clearFilters}
          className='bg-[#EFEFEF] text-black px-4 py-2 rounded-xl'
        >
          Clear All
        </button>
      </div>

      <div className='flex gap-2 flex-wrap mt-2'>
        {selectedCategory && (
          <span className='border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2'>
            Category:{' '}
            {
              categoriesData?.find(
                (cat) => cat.id === parseInt(selectedCategory)
              )?.name
            }
            <button
              onClick={() => removeFilter('category')}
              className='text-gray-500 hover:text-gray-800'
            >
              &times;
            </button>
          </span>
        )}

        {selectedCoachingRound && (
          <span className='border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2'>
            Coaching Round: {selectedCoachingRound}
            <button
              onClick={() => removeFilter('coachingRound')}
              className='text-gray-500 hover:text-gray-800'
            >
              &times;
            </button>
          </span>
        )}

        {selectedAnswerStatus && (
          <span className='border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2'>
            Answer Status:{' '}
            {selectedAnswerStatus === 'true' ? 'Answered' : 'Not Answered'}
            <button
              onClick={() => removeFilter('answered')}
              className='text-gray-500 hover:text-gray-800'
            >
              &times;
            </button>
          </span>
        )}
      </div>
    </div>
  );
};

export default QAFilter;

import axios from "axios"
import { dashboardGigsStatisticsEndpoint } from "../endPoints";

export const apiGetCpGigsStatsData = async () => {
  try {
    const response = await axios.get(dashboardGigsStatisticsEndpoint)
    return response.data || {};
  } catch (error) {
    console.error(error);
    return {};
  }
}
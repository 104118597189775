import React from "react";
import { useDialog } from "../../../shared/CustomDialog";
import CustomButton from "../../../shared/CustomButton";
import { useMutation } from "@tanstack/react-query";
import { apiGetStudents } from "../../../../Services/Students/apiGetStudents";
import { FiUpload } from "react-icons/fi";
import { useSearchParams } from "react-router-dom";

const ExportStudentButton = () => {
  const [searchParams] = useSearchParams();
  const { openDialog, closeDialog } = useDialog();
  const updatedParams = new URLSearchParams(searchParams);
  updatedParams.set("csv", "True");

  const mutation = useMutation({
    mutationFn: () => apiGetStudents(updatedParams.toString()),
    onSuccess: (data) => {
      openDialog();
    },
  });
  return (
    <CustomButton
      className="btn-primary ms-auto w-fit py-3 mb-3 flex items-center justify-center gap-2 whitespace-nowrap"
      onClick={() => mutation.mutate()}
      isLoading={mutation.isPending}
    >
      <FiUpload className="text-lg size-[1.3rem]" />
      Export Students
    </CustomButton>
  );
};

export default ExportStudentButton;

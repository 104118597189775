import axios from "axios";
import { postFreelanceJobEndPoint } from "../endPoints";

export const apiPostNewGigs = async (data) => {
  const response = await axios.post(postFreelanceJobEndPoint, data, {
    headers: {
      'Content-Type': 'multipart/form-data'
    }
  });
  return response;
}

import React, { useEffect, useState } from 'react';
import doneImage from '../../../assets/done-icon.png';
import GoodJob from '../../../Components/svgs/GoodJob';
import useFetch from '../../../hooks/useFetch';
import { GetDeliverablesById } from '../../../Services/endPoints';
import DeliverableFeedback from './DeliverableFeedback';
import SubmitTask from './SubmitTask';

function DeliverableDetails({ deliverable }) {
  const [isFeedbackVisible, setIsFeedbackVisible] = useState(false);

  const {
    data: deliverableDetails,
    loading: detailsLoading,
    error: detailsError,
  } = useFetch(`${GetDeliverablesById}${deliverable.id}/`);
  useEffect(() => {
    if (isFeedbackVisible) setIsFeedbackVisible(false);
  }, [deliverableDetails]);
  if (!deliverable.id) return null;
  if (detailsError) return <p>Error loading details</p>;

  // Handlers for toggling views
  const handleViewFeedback = () => {
    setIsFeedbackVisible(true);
  };

  const hideFeedback = () => {
    setIsFeedbackVisible(false);
  };
  return (
    <div className='border border-[#D9D9D9] rounded-lg p-4 md:p-5 my-10 md:w-3/4 m-auto w-full relative'>
      {deliverable?.completed && (
        <img
          className='absolute right-4 top-[1rem] h-[3.2rem] bg-white'
          src={doneImage}
          alt='done-icon'
        />
      )}
      {isFeedbackVisible && (
        <DeliverableFeedback
          hideFeedback={hideFeedback}
          deliverableResult={deliverableDetails?.deliverable_result}
          taskName={deliverableDetails?.task_name}
        />
      )}
      {!isFeedbackVisible && (
        <>
          <h2 className='text-3xl md:text-xl my-4 text-center text-[#202020] font-bold'>
            {deliverableDetails?.start_week} Deliverables
          </h2>
          <h3 className='text-xl md:text-base mt-4 mb-3 text-[#202020] font-semibold'>
            {deliverableDetails?.task_name}
          </h3>
          <p className='text-lg md:text-base text-[#828282] mt-4 whitespace-pre-wrap mb-3'>
            {deliverableDetails?.description}
          </p>
        </>
      )}
      {deliverableDetails?.submitted && !isFeedbackVisible && (
        <div className='text-green-600 font-semibold mt-6'>
          <div className='flex justify-center items-center flex-col gap-5'>
            <GoodJob />
            <h3 className='text-xl my-4 text-center text-[#202020] font-bold'>
              Your Task Submitted successfully
            </h3>
          </div>
          {deliverableDetails?.has_feedback && (
            <div className='flex justify-center items-center gap-5 mt-5'>
              <button
                className='btn-primary py-2 px-3 w-40'
                onClick={handleViewFeedback}
              >
                View Feedback
              </button>
            </div>
          )}
        </div>
      )}

      {deliverableDetails?.submitted === false && (
        <SubmitTask deliverableId={deliverable.id} />
      )}
    </div>
  );
}

export default DeliverableDetails;

import dateFormat from "dateformat";
import React from "react";
import { useSearchParams } from "react-router-dom";
import QAAllAnswer from "../../../Components/svgs/QAAllAnswer";
import QAanswered from "../../../Components/svgs/QAanswered";
import QAClock from "../../../Components/svgs/QAClock";
import useFetch from "../../../hooks/useFetch";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import {
  dashboardQAStatisticsNumbersEndPoint,
  dashboardQATableEndPoint,
} from "../../../Services/endPoints";
import CardsInfo from "../components/CardsInfo";
import Header from "../components/Header";
import QADetails from "../components/QAPage/QADetails";
import QAFilter from "../components/QAPage/QAFilter";
import QATableRenderer from "../components/QAPage/QATableRenderer";
import SearchInput from "../components/QAPage/QASearchInput";

export default function QA() {
  const [searchParams] = useSearchParams();
  const dynamicQAEndpoint = `${dashboardQATableEndPoint}?${searchParams.toString()}`;

  const { data: qaStatisticsData } = useFetch(
    dashboardQAStatisticsNumbersEndPoint
  );
  const qaStatisticsTime = qaStatisticsData?.average_answer_response_time
    .split(" ")
    .slice(0, 2)
    .join(" ");
  const qaCards = [
    {
      img: <QAAllAnswer className="cover-image" />,
      title: "All questions",
      value: qaStatisticsData?.questions_count,
    },
    {
      img: <QAanswered className="cover-image" />,
      title: "Answered",
      value: qaStatisticsData?.answer_count,
    },
    {
      img: <QAClock className="cover-image" />,
      title: "Average Answer Time",
      value: qaStatisticsTime,
    },
  ];

  const {
    data: dashboardQATableData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicQAEndpoint);

  const results = dashboardQATableData || [];

  // Table Columns
  const tableColumns = [
    { header: "Student ID", accessor: "id" },
    { header: "Student", accessor: "student" },
    { header: "Instructor ", accessor: "instructor" },
    { header: "Category", accessor: "category" },
    { header: "Response time", accessor: "time" },
    { header: "View Details", accessor: "details" },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((data) => ({
    id: data.id,
    student: data.student,
    instructor: data.instructor,
    category: data.category.name,
    time: dateFormat(data.created_at, "yyyy-mm-dd, HH:MM"),
    details: (
      <QADetails
        answer={data.answer?.answer}
        question={data.question_text}
        student={data.student_name}
      />
    ),
  }));

  return (
    <>
      <Header>
        <CardsInfo cards={qaCards} />
      </Header>
      <SearchInput />
      <div className="flex justify-between flex-wrap gap-y-5">
        <QAFilter />
      </div>

      <div className="mt-6">
        <QATableRenderer
          isLoading={isLoading}
          error={error}
          results={results}
          tableData={tableData}
          tableColumns={tableColumns}
          page={page}
          totalPages={totalPages}
          rowsPerPage={rowsPerPage}
          handlePageChange={handlePageChange}
          setCurrentPage={setCurrentPage}
          handleRowsPerPageChange={handleRowsPerPageChange}
          prevPage={prevPage}
          nextPage={nextPage}
        />
      </div>
    </>
  );
}

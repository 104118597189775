import ReactApexChart from "react-apexcharts";

const chartOptions = {
  chart: {
    type: "donut",
  },
  colors: ["#1e40af", "#859cff", "#fbbf24"],
  labels: [
    "Accepted Revenue Freelance",
    "Landed Revenue Freelance",
    "Pending Review Revenue Freelance",
  ],
  legend: {
    show: false,
  },
  plotOptions: {
    pie: {
      donut: {
        size: "55%",
      },
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    width: 0,
  },
};

const RevenueStats = ({ data }) => {
  const labels = [
    {
      name: "Accepted Revenue Freelance",
      value: parseInt(data?.accepted_revenue_freelance ?? 0, 10),
      color: "#1e40af",
    },
    {
      name: "Landed Revenue Freelance",
      value: parseInt(data?.landed_revenue_freelance ?? 0, 10),
      color: "#859cff",
    },
    {
      name: "Pending Review Revenue Freelance",
      value: parseInt(data?.waiting_revenue_freelance ?? 0, 10),
      color: "#fbbf24",
    },
  ];
  const series = [
    parseInt(data?.accepted_revenue_freelance ?? 0, 10),
    parseInt(data?.landed_revenue_freelance ?? 0, 10),
    parseInt(data?.waiting_revenue_freelance ?? 0, 10),
  ];

  return (
    <div className="max-w-[50rem] xl:w-[45%] lg:w-[55%] w-full bg-[#F9F9FA] p-6 shadow rounded">
      <div className="flex flex-row items-center justify-between">
        <h2 className="tablet:text-sm text-base font-semibold">Revenues</h2>
      </div>
      <div className="w-full flex items-center justify-between tablet:flex-nowrap flex-wrap gap-8 mt-3">
        <div className="flex flex-col gap-3 w-fit ">
          <p className="text-primary font-semibold mb-[1.5rem]">
            Total number of Revenues :
            <span className="text-[#263238] font-medium ms-2">
              {parseInt(data?.["total_revenue_freelance"], 10)}
            </span>
          </p>
          {labels.map((item, index) => (
            <div key={index} className="w-full flex items-center gap-2">
              <div
                className="w-3 h-3 rounded-full"
                style={{ backgroundColor: item.color }}
              />
              <span className="tablet:text-sm text-base text-gray-700 whitespace-nowrap">
                {item.name}
              </span>
              <span className="tablet:text-sm text-base font-semibold text-gray-900 ms-auto">
                {item.value}
              </span>
            </div>
          ))}
        </div>
        <div className="h-auto aspect-square max-w-[80%] sm:max-w-[60%] mx-auto">
          <ReactApexChart
            options={chartOptions}
            series={series}
            type="donut"
            width={"70%"}
            height={"70%"}
          />
        </div>
      </div>
    </div>
  );
};

export default RevenueStats;

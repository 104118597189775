import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import ArrowDown from '../../../../Components/svgs/ArrowDown';
import { useOutsideClick } from '../../../../hooks/useClickOutside';
import useFetch from '../../../../hooks/useFetch';
import { categoryEndPoint } from '../../../../Services/endPoints';

export default function QAFilter() {
  const [searchParams, setSearchParams] = useSearchParams();
  const categoryDropdownRef = useRef();
  const [isCategoryDropdownOpen, setIsCategoryDropdownOpen] = useState(false);
  const { data: categoryData } = useFetch(categoryEndPoint);

  // Handle outside click to close the dropdown
  useOutsideClick(categoryDropdownRef, () => setIsCategoryDropdownOpen(false));

  const handleSelect = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
    localStorage.setItem('filters', updatedParams.toString());
  };

  const toggleCategoryDropdown = () => {
    setIsCategoryDropdownOpen((prevState) => !prevState);
  };

  const selectedCategoryId = searchParams.get('category');

  const selectedCategoryName =
    categoryData?.find(
      (category) => category.id === parseInt(selectedCategoryId)
    )?.name || 'Category';

  useEffect(() => {
    if (localStorage.getItem('filters')) {
      setSearchParams(new URLSearchParams(localStorage.getItem('filters')));
    }
  }, []);

  return (
    <div className='w-fit'>
      <div className='w-[50vw] flex items-center gap-4 flex-wrap pb-[1.5rem] border-b border-[#CCD1D2]'>
        <div className='relative dropdown' ref={categoryDropdownRef}>
          {/* Category Dropdown Button */}
          <button
            onClick={toggleCategoryDropdown}
            className='px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4'
          >
            <span className='block'>Category</span>
            <ArrowDown className='size-[1rem] text-[#868686]' />
          </button>

          {/* Dropdown Options */}
          {isCategoryDropdownOpen && (
            <div className='absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10'>
              {categoryData?.map((category) => (
                <div
                  key={category.id}
                  onClick={() => {
                    handleSelect('category', category.id);
                    setIsCategoryDropdownOpen(false);
                  }}
                  className='px-4 py-2 text-sm cursor-pointer hover:bg-gray-100'
                >
                  {category.name}
                </div>
              ))}
            </div>
          )}
        </div>
      </div>

      {/* Selected Filter Display */}
      <div className='mt-4 flex items-center flex-wrap gap-3'>
        {selectedCategoryId && selectedCategoryName && (
          <div className='flex items-center px-3 py-2 text-sm border border-gray-300 rounded-full'>
            {selectedCategoryName}
            <button
              onClick={() => handleSelect('category', '')}
              className='ml-2 text-gray-500 hover:text-red-500'
            >
              ✖
            </button>
          </div>
        )}
      </div>
    </div>
  );
}

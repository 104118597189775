import { GoLink } from "react-icons/go";
import QuestionIcon from "../../Components/svgs/QuestionIcon";
import CalenderIcon from "../../Components/svgs/CalenderIcon";
import CustomButton from "../../Components/shared/CustomButton";
import dateFormat from "dateformat";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { toast } from "react-toastify";
import { apiDeleteAnnouncement } from "../../Services/Announcements";
import { queryKeys } from "../../constants";
import MutateAnnouncementsDialog from "./MutateAnnouncementsDialog";

const AnnouncementsCard = ({ announcement, codeName, dialogProps }) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: () =>
      apiDeleteAnnouncement(announcement?.id, {
        status: "Expired",
      }),
    onSuccess: () => {
      toast.success("Announcement Mark as Expired successfully");
      queryClient.invalidateQueries({
        queryKey: [queryKeys.getAllAnnouncements],
      });
    },
  });

  return (
    <div className="rounded-lg border-gray-300 border p-4 shadow-md relative mt-[1.5rem]">
      <span className="ms-auto block text-primary border border-primary rounded-md w-fit px-2 py-1 text-sm self-start">
        {announcement?.[codeName]}
      </span>
      <h2 className="text-xl font-semibold mt-2 mb-3">{announcement?.title}</h2>
      <div className="flex flex-col gap-5 w-full">
        <div className="flex items-center text-gray-82 text-sm">
          <CalenderIcon className="mr-2 size-[1.1rem]" />
          <p className="text-sm whitespace-nowrap">
            {dateFormat(announcement?.created_at, "dddd, dd mmmm yyyy")}
          </p>
        </div>

        <div className="flex items-center text-gray-82 text-sm">
          <QuestionIcon className="mr-2 size-[1.1rem]" />
          <p className="text-sm whitespace-nowrap">
            {announcement?.description}
          </p>
        </div>

        <a
          href={announcement?.link}
          target="_blank"
          rel="noreferrer"
          className="flex items-center text-gray-82 text-sm"
        >
          <GoLink className="mr-2 size-[1.1rem]" />
          <p className="text-sm text-primary underline font-light">View link</p>
        </a>

        <div className="w-full flex flex-wrap gap-3 justify-center items-stretch">
          <MutateAnnouncementsDialog
            dialogTitle="Edit Announcement"
            renderOpenButton={(openDialog) => (
              <CustomButton
                className="flex-1 min-w-[8rem]"
                onClick={openDialog}
              >
                Edit
              </CustomButton>
            )}
            announcement={announcement}
            {...dialogProps}
          />
          <CustomButton
            className="text-[#E22A2A] bg-white border border-solid border-[#EE201C] rounded-lg hover:bg-red-50 disabled:bg-red-50 flex-1 min-w-[8rem]"
            isLoading={mutation.isPending}
            onClick={() => mutation.mutate()}
          >
            Mark as Expired
          </CustomButton>
        </div>
      </div>
    </div>
  );
};

export default AnnouncementsCard;

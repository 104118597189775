import React, { useState } from "react";
import { useParams } from "react-router-dom";
import StudentDetailsGigs from "./StudentDetailsGigs";
import StudentDetailsWebinars from "./StudentDetailsWebinars";
import StudentDetailsQA from "./StudentDetailsQA";
import { dashboardSingleStudentStatisticsEndPoint } from "../../../../Services/endPoints";
import useFetch from "../../../../hooks/useFetch";

export default function StudentDetailsCharts() {
  const { id } = useParams();
  const [activeTab, setActiveTab] = useState(1);
  const { data: singleStudentStatistics, loading } = useFetch(
    `${dashboardSingleStudentStatisticsEndPoint}${id}/`
  );

  const renderTabContent = () => {
    switch (activeTab) {
      case 1:
        return (
          <StudentDetailsWebinars
            id={id}
            statistics={singleStudentStatistics}
            loadingChart={loading}
          />
        );
      case 2:
        return (
          <StudentDetailsQA
            id={id}
            statistics={singleStudentStatistics}
            loadingChart={loading}
          />
        );
      case 3:
        return (
          <StudentDetailsGigs
            id={id}
            statistics={singleStudentStatistics}
            loadingChart={loading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white rounded-xl shadow-lg p-6 mt-5">
      {/* Tabs */}
      <div role="tablist" className="tabs mb-4 flex justify-start">
        <button
          role="tab"
          className={`tab ${
            activeTab === 1 ? "tab-active text-[#273C97] font-semibold" : ""
          }`}
          onClick={() => setActiveTab(1)}
        >
          Webinars
        </button>
        <button
          role="tab"
          className={`tab ${
            activeTab === 2 ? "tab-active text-[#273C97] font-semibold" : ""
          }`}
          onClick={() => setActiveTab(2)}
        >
          Q&A
        </button>
        <button
          role="tab"
          className={`tab ${
            activeTab === 3 ? "tab-active text-[#273C97] font-semibold" : ""
          }`}
          onClick={() => setActiveTab(3)}
        >
          Gigs
        </button>
      </div>

      {/* Tab Content */}
      <div>{renderTabContent()}</div>
    </div>
  );
}

import { useQuery } from "@tanstack/react-query";
import React from "react";
import { Link, useParams } from "react-router-dom";
import { apiGetStudentProfile } from "../../../../../Services/ControlPanel/apiGetStudentProfile";
import Loading from "../../../../shared/Loading";
import BackBtn from "../../../../svgs/BackBtn";
import { EditStudentForm } from "./EditSudentForm/EditStudentForm";

const CPStudentDetailsPage = () => {
  const { id } = useParams();
  const { data, loading } = useQuery({
    queryKey: ["student-profile", id],
    queryFn: () => apiGetStudentProfile({ id }),
  });

  return (
    <div className="mt-[1.5rem]">
      <div className="flex justify-between gap-4 items-center">
        <Link
          to="/control-panel/cp-students"
          className="flex gap-3 items-center btn-primary px-4 py-2 text-xl font-semibold"
        >
          <BackBtn />
          Back
        </Link>
      </div>
      {loading && <Loading />}
      {!loading && data && (
        <EditStudentForm currentStudentData={data} isLoading={loading} />
      )}
    </div>
  );
};

export default CPStudentDetailsPage;

import { StarBorder, StarSharp } from "@mui/icons-material";
import { useState } from "react";

function Rate({ rate, setRate }) {
  const [stars] = useState([1, 2, 3, 4, 5]);

  return (
    <div>
      {stars.map((i) => (
        <button
          key={i}
          type="button"
          onClick={() => setRate(i)}
          className="text-3xl"
        >
          {rate >= i ? (
            <StarSharp className="text-[#F5BF03]" fontSize="inherit" />
          ) : (
            <StarBorder color="action" fontSize="inherit" />
          )}
        </button>
      ))}
    </div>
  );
}

export default Rate;

import ReactApexChart from "react-apexcharts";
import dateFormat from "dateformat";
import { Link } from "react-router-dom";
const options = {
  chart: {
    type: "bar",
    toolbar: {
      show: false,
    },
    stacked: false,
  },
  plotOptions: {
    bar: {
      horizontal: false,
      columnWidth: "25%",
      borderRadius: 4,
    },
  },
  dataLabels: {
    enabled: false,
  },
  stroke: {
    show: true,
    width: 2,
    colors: ["transparent"],
  },
  xaxis: {
    axisBorder: {
      show: false,
    },
    axisTicks: {
      show: false,
    },
    labels: {
      style: {
        fontSize: "12px",
      },
    },
  },
  yaxis: {
    title: {
      text: "",
    },
    min: 0,
    tickAmount: 5,
    labels: {
      formatter: function (value) {
        return value?.toString();
      },
    },
  },
  grid: {
    show: true,
    borderColor: "#f1f1f1",
    xaxis: {
      lines: {
        show: false,
      },
    },
    yaxis: {
      lines: {
        show: false,
      },
    },
  },
  colors: ["#ff4d4d"],

  fill: {
    opacity: 1,
  },
  tooltip: {
    y: {
      formatter: function (val) {
        return val + " students";
      },
    },
  },
};
export default function WebinarChart({ webinars }) {
  const series = [
    {
      name: "",
      data:
        webinars?.map((item) => ({
          x: dateFormat(item[0], "mmm dd yyyy"),
          y: item[1],
          fillColor:
            item[1] >= 300 ? "#273C97" : item[1] >= 100 ? "#9F9FF8" : "#F13E3E",
        })) ?? [],
    },
  ];
  const values = webinars?.map((item) => item[1]) ?? [];

  return (
    <div className="tablet:w-[70%] w-full  bg-[#F9F9FA] p-4 shadow rounded">
      <div className="flex gap-4 items-center">
        <h2 className="tablet:text-sm text-base font-semibold">Webinars</h2>
        <span className="text-[#00000066] tablet:text-sm text-base">
          attendance
        </span>
        <div className="w-[1px] bg-[#00000066] h-[1.2rem]" />
        <span className="text-[#00000066] tablet:text-sm text-base">
          webinar date
        </span>
        <Link
          to={"/webinars"}
          className="text-white text-center rounded-full bg-primary hover:bg-primary-light transition-all duration-300 ease-in-out py-1 px-6 ms-auto tablet:text-xs text-sm"
        >
          View All
        </Link>
      </div>
      <div>
        <div className="h-[400px] w-full">
          <ReactApexChart
            options={{
              ...options,
              yaxis: {
                ...options.yaxis,
                max: Math.ceil(Math.max(...values) / 100) * 100,
              },
            }}
            series={series}
            type="bar"
            height="100%"
          />
        </div>
      </div>
    </div>
  );
}

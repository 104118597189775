import React from 'react';

export default function SkeletonCard() {
  return (
    <div className='border rounded-md p-4 mb-4 shadow-sm animate-pulse'>
      <div className='h-4 bg-gray-200 rounded w-1/2 mb-2'></div>
      <div className='h-3 bg-gray-200 rounded w-1/4 mb-2'></div>
      <div className='h-3 bg-gray-200 rounded w-3/4 mb-2'></div>
    </div>
  );
}

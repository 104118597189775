import { Navigate, Route, Routes } from "react-router-dom";
import { pageRoutes } from "../constants";

import Webinars from "./InsturctorDashboard/Pages/Webinars/Webinars";
import InstructorLayout from "./InsturctorDashboard/Components/Common/InstructorLayout";
import WebinarInstructorDashboard from "./InsturctorDashboard/Pages/Dashboard/WebinarInstructorDashboard";
import Settings from "./Students/Settings/Settings";
import NotFoundPage from "./Common/NotFoundPage";
import AnnouncementsPage from "./InsturctorDashboard/Pages/Announcements/AnnouncementsPage";

const WebinarInstructorRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<InstructorLayout />}>
        <Route
          path={pageRoutes.dashboard}
          element={<WebinarInstructorDashboard />}
        />
        <Route path={pageRoutes.webinars} element={<Webinars />} />
        <Route
          path={pageRoutes.announcements}
          element={<AnnouncementsPage />}
        />
        <Route path={pageRoutes.settings} element={<Settings />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default WebinarInstructorRoutes;

import { useQuery } from '@tanstack/react-query';
import React, { useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import usePaginationNumbers from '../../../../hooks/usePaginationNumbers';
import SearchInput from '../../../../NewPages/AdminDashboard/Common/SearchInput/SearchInput';
import Table from '../../../../NewPages/AdminDashboard/Common/Table/Table';
import TableSkeleton from '../../../../NewPages/AdminDashboard/Common/Table/TableSkeleton';
import CardsInfo from '../../../../NewPages/AdminDashboard/components/CardsInfo';
import GigsDetails from '../../../../NewPages/AdminDashboard/components/GigsPage/GigsDetails';
import Header from '../../../../NewPages/AdminDashboard/components/Header';
import { apiGetCpGigsStatsData } from '../../../../Services/AdminDashboard/aoiGetGigsStatsData';
import { dashboardControlPanelGigsTableEndpoint } from '../../../../Services/endPoints';
import { queryKeys } from '../../../../Services/QueryKeys/queryKeys';
import GigsIcon from '../../../svgs/GigsIcon';

const CPGigsPage = () => {
  const [searchParams] = useSearchParams();
  const [refetchTrigger, setRefetchTrigger] = useState(false);

  const { data: gigsStatsData } = useQuery({
    queryKey: [queryKeys.CPGigsStats],
    queryFn: apiGetCpGigsStatsData,
  });

  const dynamicGigsEndpoint = `${dashboardControlPanelGigsTableEndpoint}?${searchParams.toString()}`;

  const {
    data: dashboardGigsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicGigsEndpoint, refetchTrigger);

  const results = dashboardGigsData || [];

  const handleRefetch = () => {
    setRefetchTrigger((prev) => !prev);
  };

  // Table Columns
  const tableColumns = [
    { header: 'Student Name', accessor: 'name' },
    { header: 'Email', accessor: 'email' },
    { header: 'Platform', accessor: 'platform' },
    { header: 'Price', accessor: 'price' },
    { header: 'Gig title', accessor: 'gigTitle' },
    { header: 'Rate', accessor: 'rate' },
    { header: 'View Details', accessor: 'details' },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((student) => ({
    name: student.student_name,
    email: student.student_email,
    platform: student.platform,
    price: student.price,
    gigTitle: student.title || 'N/A',
    rate: student?.rate?.rate || '–	',
    details: (
      <GigsDetails
        id={student.id}
        name={student.student_name}
        email={student.student_email}
        url={student.url}
        proposal_file={student.proposal_file}
        status_proof_screenshot={student.status_proof_screenshot}
        title={student.title}
        date={student.created_on}
        platform={student.platform}
        price={student.price}
        buttonType='double'
        handleRefetch={handleRefetch}
        rate={student.rate}
      />
    ),
  }));

  const gigsCards = [
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Total gigs',
      value: gigsStatsData?.total_freelance_jobs ?? '0',
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Rejected by admin',
      value: gigsStatsData?.rejected_freelance ?? '0',
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Waiting for admin review',
      value: gigsStatsData?.pending_freelance ?? '0',
    },
    {
      img: <GigsIcon className='cover-image' />,
      title: 'Approved by admin',
      value: (
        <span className='text-[#259800]'>
          {gigsStatsData?.completed_freelance ?? '0'}
        </span>
      ),
    },
  ];

  return (
    <div className='p-4'>
      <Header>
        <CardsInfo cards={gigsCards} />
      </Header>
      {/* Filters Component */}
      <div className='mt-4'>
        <SearchInput
          name={'student_email'}
          placeholder='Search by student email'
        />
      </div>
      <div className='mt-6'>
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
};

export default CPGigsPage;

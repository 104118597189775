import axios from "axios";
import { loginEndPoint } from "../../../Services/endPoints";

const apiPostLogin = async ({ email, password }) => {
  try {
    const response = await axios.post(
      loginEndPoint,
      { email, password },
    );
    return response.data;
  } catch (error) {
    console.error("Error at login:", error);
    throw error;
  }
}

export default apiPostLogin;
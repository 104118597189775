import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import * as yup from 'yup';

import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import { apiPatchInstructorProfile } from '../../../../../../Services/ControlPanel/apiPatchInstructorProfile';
import { getErrorMessage } from '../../../../../../Helpers/responseError';

const schema = yup.object({
  name: yup
    .string()
    .required('Full name is required')
    .min(3, 'Full name must be at least 3 characters'),
  email: yup
    .string()
    .required('Email is required')
    .email('Invalid email address'),
  coachingRounds: yup.string(),
  jobProfiles: yup.string(),
  phone: yup
    .string()
    .required('Phone number is required')
    .min(8, 'Phone number must be at least 8 characters')
    .max(15, 'Phone number must be at most 15 characters'),
  linkedin_profile: yup.string().nullable(),
  city: yup.string().nullable(),
  track: yup.string().nullable(),
});

export const useEditInstructorForm = () => {
  const { id } = useParams();
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: apiPatchInstructorProfile,
  });
  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: 'onChange',
  });
  const onSubmit = async (data) => {
    const requestBody = {
      id,
      email: data.email,
      name: data.name,
      phone: data.phone,
      linkedin_profile: data.linkedin_profile || '',
      city: data.city || '',
      track: data.track || '',
    };
    if (data.coachingRounds !== '') {
      requestBody.coaching_rounds_ = data?.coachingRounds?.split(',');
    }
    if (data.jobProfiles !== '') {
      requestBody.job_profile_rounds_ = data?.jobProfiles?.split(',');
    }

    mutation.mutate(
      {
        id,
        requestBody,
      },
      {
        onSuccess: () => {
          toast.success('Profile updated successfully');
          form.reset();
          queryClient.invalidateQueries({ queryKey: ['instructor-profile'] });
        },
        onError: (err) => {
          const errors = getErrorMessage(err);
          console.error('Error: ', errors);
          if (!errors?.length) return;
          errors.forEach((error) => {
            form.setError(error.key, { message: error.message });
          });
        },
      }
    );
  };
  return { ...form, isPending, onSubmit };
};

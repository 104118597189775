import { useState } from 'react';
import { FaEye, FaRegEyeSlash } from 'react-icons/fa';
import { twMerge } from 'tailwind-merge';

const PasswordInput = ({
  name,
  placeholder,
  register,
  passwordError,
  className,
}) => {
  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const togglePasswordVisibility = () =>
    setIsPasswordVisible(!isPasswordVisible);

  return (
    <>
      <div
        className={twMerge(
          `input border-0 border-b border-[#00000070] rounded-none flex items-center gap-2 mt-4 pe-3 focus:border-none focus-within:border-none focus-visible:border-none focus:outline-none focus-within:outline-none focus-visible:outline-none bg-transparent`,
          className
        )}
      >
        <label htmlFor={name}>{/* <MdLock /> */}</label>
        <input
          id={name}
          autoComplete={name}
          className='w-full'
          placeholder={placeholder}
          type={isPasswordVisible ? 'text' : 'password'}
          {...register(name)}
        />
        <button
          type='button'
          className='ms-auto'
          onClick={togglePasswordVisibility}
        >
          {!isPasswordVisible && <FaEye className='text-[#00000070]' />}
          {isPasswordVisible && <FaRegEyeSlash className='text-[#00000070]' />}
        </button>
      </div>
      {passwordError && (
        <p className='text-[red] ms-2 text-sm mt-3 w-full'>
          {passwordError.message}
        </p>
      )}
    </>
  );
};

export default PasswordInput;

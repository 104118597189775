import { useEffect } from "react";
import { Controller } from "react-hook-form";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";
import CustomButton from "../../../../../shared/CustomButton";
import LabeledInput from "../../../../../shared/LabeledInput";
import ChangePasswordDialog from "../../../../shared/ChangePassword/ChangePasswordDialog";
import CopyId from "../../../../shared/CopyId";
import ProfileImageViewer from "../../../../shared/ProfileImageViewer";
import SelectInputEdit from "./SelectInputEdit";
import { useEditInstructorForm } from "./useEditInstructorForm";
import SelectInput from "../../../../../shared/SelectInput";
import { useGetSelectionData } from "./useGetSelectionData";

const EditInstructorForm = ({ currentInstructorData, isLoading }) => {
  const {
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    control,
    formState: { errors },
    isPending,
    onSubmit,
  } = useEditInstructorForm();

  const { selectionOptions } = useGetSelectionData();

  useEffect(() => {
    const defaultInputValues = {
      name: currentInstructorData?.name ?? "",
      email: currentInstructorData?.user.email ?? "",
      linkedin_profile: currentInstructorData?.linkedin_profile ?? "",
      phone: currentInstructorData?.phone ?? "",
      coachingRounds: "",
      jobProfiles: "",
      city: currentInstructorData?.city ?? "",
      track: currentInstructorData?.track ?? "",
    };
    Object.entries(defaultInputValues).forEach(([key, value]) => {
      setValue(key, value);
    });
  }, [currentInstructorData, setValue]);

  const role = currentInstructorData?.role;

  return (
    <div className="mt-[2rem] tablet:w-[73%] w-full max-w-[90rem] rounded-lg shadow bg-white px-[1.5rem] py-[1.5rem] mx-auto">
      <div className="flex md:flex-nowrap flex-wrap items-start gap-[2rem]">
        <ProfileImageViewer
          role={"Instructor"}
          profileImage={currentInstructorData?.profile_image}
          isLoading={isLoading}
        />
        <div className="flex md:flex-row items-start gap-[2rem] flex-col-reverse flex-1">
          <form
            id="edit-student-form"
            onSubmit={handleSubmit(onSubmit)}
            className="max-w-[30rem] flex flex-col gap-[1.25rem]"
          >
            <LabeledInput
              id={"fullName"}
              label={"Name"}
              register={register}
              placeholder={"Full Name"}
              fieldName="name"
              errors={errors}
            />
            <LabeledInput
              id={"email"}
              label={"Email"}
              register={register}
              placeholder={"Email"}
              fieldName="email"
              errors={errors}
            />
            <LabeledInput
              id={"linkedin_profile"}
              label={"LinkedIn Profile"}
              register={register}
              placeholder={"LinkedIn Profile"}
              fieldName="linkedin_profile"
              errors={errors}
            />

            <div>
              <label
                htmlFor="phone"
                className="text-primary text-base font-medium mb-2 block"
              >
                Phone
              </label>
              <div className="input input-bordered rounded border-[#DFEAF2] w-full flex items-center">
                <Controller
                  control={control}
                  name="phone"
                  render={({ field: { onChange, onBlur, value } }) => (
                    <PhoneInput
                      className="w-full"
                      countrySelectProps={{ unicodeFlags: true }}
                      placeholder="Phone Number"
                      id="phone"
                      defaultCountry="EG"
                      onChange={onChange}
                      value={value}
                      onBlur={onBlur}
                    />
                  )}
                />
              </div>
              {errors.phone && (
                <p className="text-[red] ms-2 text-sm mt-2 w-full">
                  {errors.phone.message}
                </p>
              )}
            </div>

            {(role === "All" || role === "Q/A") && (
              <SelectInputEdit
                label={"Coaching Rounds"}
                placeholderText={"Add New Coaching Rounds"}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                inputKey={"coachingRounds"}
                apiCurrentValues={currentInstructorData?.coaching_rounds}
              />
            )}

            {(role === "All" || role === "Webinar") && (
              <SelectInputEdit
                label={"Job Profile"}
                placeholderText={"Add New Job Profile"}
                setValue={setValue}
                getValues={getValues}
                errors={errors}
                watch={watch}
                inputKey={"jobProfiles"}
                apiCurrentValues={currentInstructorData?.job_profile_rounds}
              />
            )}
            {/* Single-value Select for city (using your fetched data) */}
            <SelectInput
              name="city"
              label="City"
              placeholderText="Select City"
              items={selectionOptions.city}
              register={register}
              errors={errors}
            >
              {/* Optional "badge" displaying current value */}
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentInstructorData?.city}
              </span>
            </SelectInput>

            {/* Single-value Select for track */}
            <SelectInput
              name="track"
              label="Track"
              placeholderText="Select Track"
              items={selectionOptions.track}
              register={register}
              errors={errors}
            >
              <span className="bg-[#F8F8FD] w-fit shadow-sm px-3 py-1 text-primary text-sm mt-3 block font-medium rounded-sm ms-1">
                {currentInstructorData?.track}
              </span>
            </SelectInput>
          </form>
          <div className="flex flex-wrap ms-auto gap-x-2 gap-y-4 justify-end items-center">
            <CopyId id={currentInstructorData?.id} />
            <ChangePasswordDialog
              role={"Instructor"}
              email={currentInstructorData?.user?.email}
            />
          </div>
        </div>
      </div>
      <CustomButton
        form="edit-student-form"
        type="submit"
        className="font-bold px-4 text-sm block ms-auto py-4 mt-5"
        isLoading={isPending}
      >
        Save Changes
      </CustomButton>
    </div>
  );
};
export default EditInstructorForm;

import React, { useEffect, useRef, useState } from 'react';
import CustomButton from '../../../Components/shared/CustomButton';
import { useEditJob } from './useEditJob';

const EditJobDialog = ({ isOpen, onClose, selectedJob }) => {
  const dialogRef = useRef(null);

  const [price, setPrice] = useState(selectedJob?.price || '');
  const [file, setFile] = useState(null);
  const [fileName, setFileName] = useState('');
  const [errorMsg, setErrorMsg] = useState('');

  const { mutate: editJob, isLoading: editLoading } = useEditJob();

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  useEffect(() => {
    setPrice(selectedJob?.price || '');
    setFile(null);
    setFileName('');
    setErrorMsg('');
  }, [selectedJob]);

  const handleFileChangeStatusProofFile = (e) => {
    const chosenFile = e.target.files[0];
    if (chosenFile) {
      setFile(chosenFile);
      setFileName(chosenFile.name);
    }
  };

  const handleEditSubmit = () => {
    if (!price) {
      setErrorMsg('Price is required.');
      return;
    }

    const formData = new FormData();
    formData.append('price', price);

    if (file) {
      formData.append('status_proof_screenshot', file);
    }

    editJob(
      { id: selectedJob.id, formData },
      {
        onSuccess: () => {
          onClose();
        },
        onError: (err) => {
          setErrorMsg(err?.response?.data?.message || 'Something went wrong');
        },
      }
    );
  };

  return (
    <dialog
      ref={dialogRef}
      className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] 
                 w-[35rem] min-h-[15rem] border-none bg-white rounded-xl 
                 outline-none p-7 overflow-visible shadow-lg'
    >
      {/* Dialog Header */}
      <div className='flex justify-between items-center mb-4'>
        <h2 className='text-xl font-bold text-[#273C97]'>Edit Job</h2>
        <button
          onClick={onClose}
          className='text-[#71747B] hover:text-gray-900'
        >
          ✖
        </button>
      </div>

      {/* Form Fields */}
      <div className='my-5'>
        <label className='block font-semibold text-base mb-1'>Price</label>
        <input
          type='number'
          className='w-full border border-gray-300 rounded px-3 py-2'
          value={price}
          onChange={(e) => setPrice(e.target.value)}
          placeholder='Enter job price'
        />
      </div>

      <div className='my-5'>
        <label className='block font-semibold text-base mb-1'>
          Upload Proof Screenshot
        </label>
        <label className='flex flex-col items-center px-4 py-2 bg-blue-600 text-white rounded-lg cursor-pointer hover:bg-blue-700 transition duration-200'>
          <span className='text-base leading-normal'>
            {fileName || 'Select File'}
          </span>
          <input
            type='file'
            accept='application/pdf,image/*'
            onChange={handleFileChangeStatusProofFile}
            className='hidden'
          />
        </label>
      </div>

      {/* Error Message */}
      {errorMsg && <p className='text-red-500 text-sm mb-2'>{errorMsg}</p>}

      {/* Actions */}
      <div className='flex justify-end gap-2 mt-5'>
        <CustomButton
          onClick={handleEditSubmit}
          isLoading={editLoading}
          className='py-2 px-4 text-sm'
        >
          Save
        </CustomButton>
        <button
          onClick={onClose}
          className='border border-red-600 text-red-600 rounded-lg px-4 py-2 text-sm'
        >
          Cancel
        </button>
      </div>
    </dialog>
  );
};

export default EditJobDialog;

export const getErrorMessage = (error) => {
  const errorData = error?.response?.data;

  if (errorData && typeof errorData === "object") {
    for (const key of Object.keys(errorData)) {
      const message = errorData[key];
      if (typeof message === "object" && message !== null && !Array.isArray(message)) {
        return Object.keys(message).map((nestedKey) => {
          return {
            key: nestedKey,
            message: Array.isArray(message[nestedKey]) ? message[nestedKey][0] : message[nestedKey],
          };
        });
      } else {
        return [{ key: key, message: Array.isArray(message) ? message[0] : message }];
      }
    }
  } else {
    return [
      {
        key: "root",
        message: "An unexpected error occurred",
      },
    ];
  }
};

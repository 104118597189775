import { useEffect, useState } from 'react';
import useFetch from '../../../hooks/useFetch';

import FilterGroup from '../../../Components/AdminDashboard/shared/Filters/FilterGroup';
import {
  city,
  CoachingRoundEndPoint,
  dashboardStudentsTrack,
  jobProfileRoundsEndPoint,
} from '../../../Services/endPoints';

const initialFilters = [
  {
    name: 'Coaching Round',
    key: 'coaching_round',
  },
  {
    name: 'Job Profile Code',
    key: 'job_profile_round',
  },
  {
    name: 'City',
    key: 'city',
  },
  {
    name: 'Track',
    key: 'track',
  },
  {
    name: 'Coach Type',
    key: 'role',
    options: [
      {
        name: 'Webinar Coach',
        value: '1',
      },
      {
        name: 'Q/A Coach',
        value: '2',
      },
      {
        name: 'All',
        value: '3',
      },
    ],
  },
];

const InstructorFilters = ({ count }) => {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRounds } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);
  const { data: trackData } = useFetch(dashboardStudentsTrack);
  const { data: cities } = useFetch(city);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case 'coaching_round':
            return {
              ...filter,
              options: coachingRounds?.map((round) => round.code) || [],
            };
          case 'job_profile_round':
            return {
              ...filter,
              options: jobProfileRounds?.map((round) => round.code) || [],
            };
          case 'city':
            return {
              ...filter,
              options: cities?.map((city) => city.name) || [],
            };

          case 'track':
            return {
              ...filter,
              options: trackData?.map((track) => track.name) || [],
            };

          default:
            return filter;
        }
      })
    );
  }, [coachingRounds, jobProfileRounds, cities, trackData]);
  return <FilterGroup filters={filters} count={count} />;
};

export default InstructorFilters;

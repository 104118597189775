import { useSearchParams } from "react-router-dom";
import Filter from "./Filter";
import SelectedFilter from "./SelectedFilter";
import { twMerge } from "tailwind-merge";

const FilterGroup = ({
  wrapperClassName,
  filters,
  children,
  count,
  customIndicator,
}) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const handleClearFilters = () => {
    setSearchParams(new URLSearchParams());
  };
  const isClearAllVisible =
    searchParams.size > 1 &&
    !(
      searchParams.size === 1 &&
      (searchParams.has("email") || searchParams.has("student_email"))
    );

  return (
    <div>
      <div
        className={twMerge(
          "w-fit flex items-center gap-4 flex-wrap pb-[1.5rem] border-b border-[#CCD1D2]",
          wrapperClassName
        )}
      >
        {filters?.map((filter) => (
          <Filter key={filter.key} filter={filter} />
        ))}
        {children}
      </div>
      <div className="mt-4 flex items-center flex-wrap gap-3 ms-4">
        {filters?.map((filter) => (
          <SelectedFilter
            key={filter.key}
            filter={filter}
            options={filter.options}
          />
        ))}

        {customIndicator}

        {isClearAllVisible && (
          <>
            <h2 className="bg-primary text-base rounded-full text-center text-white px-4 py-2 flex items-center gap-2">
              Count : {count}
            </h2>
            <button
              onClick={handleClearFilters}
              className="px-4 py-2 text-sm font-medium bg-[#EFEFEF] border border-gray-300 rounded-full hover:bg-gray-200"
            >
              Clear All
            </button>
          </>
        )}
      </div>
    </div>
  );
};

export default FilterGroup;

import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import React from "react";
import { CookiesProvider } from "react-cookie";
import ReactDOM from "react-dom/client";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import App from "./App";
import "./index.css";
import "./Services/i18n";
import i18n from "./Services/i18n";

const queryClient = new QueryClient();

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  <QueryClientProvider client={queryClient}>
    <CookiesProvider>
      <App />
      <ToastContainer
        position="top-center"
        style={{ whiteSpace: "nowrap", width: "auto" }}
      />
    </CookiesProvider>
  </QueryClientProvider>
);

// Listen to language changes and adjust `dir` dynamically
i18n.on("languageChanged", (lng) => {
  const htmlElement = document.documentElement;
  htmlElement.lang = lng;
  htmlElement.dir = lng === "ar" ? "rtl" : "ltr"; // Set `dir` based on the language
});

import { yupResolver } from '@hookform/resolvers/yup';
import { useMutation } from '@tanstack/react-query';
import { useForm } from 'react-hook-form';
import { toast } from "react-toastify";
import * as yup from "yup";
import { apiPatchCPWebinars } from '../../../../../Services/ControlPanel/apiPatchCPWebinars';
import { apiPatchWebinarRecordLink } from '../../../../../Services/ControlPanel/apiPatchWebinarRecordLink';
import { apiPatchWebinarStatus } from '../../../../../Services/ControlPanel/apiPatchWebinarStatus';

const schema = yup.object({
    status: yup.string().oneOf(['upcoming', 'completed']).required(),
    name: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema.required('Webinar Topic is required').max(70, 'Maximum 70 characters allowed')
            : schema.notRequired()
    ),
    date: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema
                .required('Date is required')
                .matches(/^\d{4}-\d{2}-\d{2}$/, 'Invalid date format (YYYY-MM-DD)')
                .test('not-in-past', 'Date cannot be in the past', (value) => {
                    if (!value) return false;
                    const today = new Date();
                    const selectedDate = new Date(value);
                    today.setHours(0, 0, 0, 0);
                    return selectedDate >= today;
                })
            : schema.notRequired()
    ),
    time: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema
                .required('Time is required')
                .matches(/^\d{2}:\d{2}:\d{2}$/, 'Invalid time format (HH:mm:ss)')
                .test('not-in-past', 'Time cannot be in the past for the selected date', function (value) {
                    const { date } = this.parent;
                    if (!value || !date) return false;
                    const today = new Date();
                    today.setHours(0, 0, 0, 0);
                    const selectedDate = new Date(date);
                    if (selectedDate > today) {
                        return true;
                    }
                    const now = new Date();
                    const [hours, minutes, seconds] = value.split(':').map(Number);
                    const selectedTime = new Date();
                    selectedTime.setHours(hours, minutes, seconds, 0);
                    return selectedTime >= now;
                })
            : schema.notRequired()
    ),
    instructor_id: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema.required('Coach is required')
            : schema.notRequired()
    ),
    job_profile_round: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema.required('Job Profile Round is required')
            : schema.notRequired()
    ),
    group: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema.required('Group is required')
            : schema.notRequired()
    ),
    link: yup.string().when('status', (status, schema) =>
        status === 'upcoming'
            ? schema
                .url('Enter a valid URL starting with https://')
                .matches(/^https:\/\//, 'URL must start with https://')
                .required('Webinar Link is required')
            : schema
                .url('Enter a valid URL starting with https://')
                .matches(/^https:\/\//, 'URL must start with https://')
                .required('Record Link is required')
    ),
});

export function useEditWebinarForm({ id, webinar, closeDialog, refetch }) {
    // Mutation for upcoming webinars (full update)
    const mutationFull = useMutation({
        mutationFn: apiPatchCPWebinars,
    });
    // Mutation for updating status (completed)
    const mutationStatus = useMutation({
        mutationFn: apiPatchWebinarStatus,
    });
    // Mutation for updating record link
    const mutationRecordLink = useMutation({
        mutationFn: apiPatchWebinarRecordLink,
    });

    const isPending = mutationFull.isLoading || mutationStatus.isLoading || mutationRecordLink.isLoading;

    const form = useForm({
        resolver: yupResolver(schema),
        mode: 'onChange',
        defaultValues: {
            name: webinar?.name || '',
            link: webinar?.link || '',
            date: webinar?.date || '',
            time: webinar?.time || '',
            instructor_id: webinar?.instructor_id || '',
            job_profile_round: webinar?.job_profile_round || '',
            group: webinar?.group || '',
            // Set status based on the original is_finished flag.
            status: webinar?.is_finished ? 'completed' : 'upcoming',
        },
    });

    const onSubmit = async (data) => {
        if (data.status === 'completed') {
            // First, update the status.
            mutationStatus.mutate(
                { id, requestBody: { is_finished: true } },
                {
                    onSuccess: () => {
                        // Then update the record link.
                        mutationRecordLink.mutate(
                            { id, requestBody: { link: data.link } },
                            {
                                onSuccess: () => {
                                    toast.success('Webinar updated successfully');
                                    form.reset();
                                    closeDialog();
                                    refetch();
                                },
                                onError: (err) => {
                                    console.error('Record link update error:', err.response?.data || err);
                                },
                            }
                        );
                    },
                    onError: (err) => {
                        console.error('Status update error:', err.response?.data || err);
                    },
                }
            );
        } else {
            const requestBody = {
                name: data.name,
                link: data.link,
                date: data.date,
                time: data.time,
                instructor_id: data.instructor_id,
                job_profile_round: data.job_profile_round,
                group: data.group,
                is_finished: false,
            };
            mutationFull.mutate(
                { id, requestBody },
                {
                    onSuccess: () => {
                        toast.success('Webinar updated successfully');
                        form.reset();
                        closeDialog();
                        refetch();
                    },
                    onError: (err) => {
                        if (err.response?.data?.date) {
                            form.setError("date", { message: err.response.data.date[0] });
                        }
                        console.error("Submission error:", err.response?.data || err);
                    },
                }
            );
        }
    };

    return { ...form, isPending, onSubmit };
}

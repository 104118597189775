import { useSearchParams } from 'react-router-dom';
import CloseIcon from '../../../svgs/CloseIcon';

const SelectedFilter = ({ filter, options }) => {
  const [searchParams, setSearchParams] = useSearchParams();
  const selectedOption = searchParams.get(filter.key);
  const option =
    options?.find((option) => {
      if (option.value) {
        return option.value === selectedOption;
      }
      return option === selectedOption;
    }) || selectedOption;
  const deleteFilter = (e) => {
    e.stopPropagation();
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete(filter.key);
    setSearchParams(updatedParams);
  };
  if (!option) return null;
  return (
    <div className='flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full'>
      {filter.name}: {option?.name || option}
      <button
        onClick={deleteFilter}
        className='ml-2 text-gray-500 hover:text-red-500'
      >
        <CloseIcon className='size-[1rem]' />
      </button>
    </div>
  );
};

export default SelectedFilter;

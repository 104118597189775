import { TextField } from "@mui/material";
import { useState } from "react";
import { toast } from "react-toastify";
import postDeliverable from "../../../Services/apiPostDeliverable";
import styles from "./Tasks.module.css";

export default function SubmitTask({ deliverableId }) {
  const [attachment, setAttachment] = useState(null);
  const [url, setUrl] = useState("");
  const [fileError, setFileError] = useState(null);
  const [apiError, setApiError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      const maxSizeInBytes = 5 * 1024 * 1024; // 2 MB in bytes

      if (file.size > maxSizeInBytes) {
        setFileError("Max file size is 5 MB.");
        toast.error("Max file size is 5 MB.");
        return;
      }
      const isPdf =
        file.type === "application/pdf" || file.name.endsWith(".pdf");
      if (!isPdf) {
        setFileError("Only PDF files are allowed.");
        toast.error("Only PDF files are allowed.");
        return;
      }

      setAttachment(file);
      setFileError(null);
      setApiError(null);
    }
  };
  const handleUrlChange = (e) => {
    setUrl(e.target.value);
    setApiError(null);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!attachment) {
      setFileError("Please upload a file.");
      toast.error("Please upload a file.");
      return;
    }

    setApiError(null);

    try {
      setIsLoading(true);
      await postDeliverable({ deliverableId, attachment, url });
      setIsLoading(false);
      window.location.reload();
    } catch (error) {
      if (error.response && error.response.data) {
        setApiError(`Error: ${JSON.stringify(error.response.data)}`);
        toast.error(`Error: ${JSON.stringify(error.response.data)}`);
      } else {
        setApiError("Error submitting deliverable.");
        toast.error("Error submitting deliverable.");
      }
    }
  };
  return (
    <div className={styles.taskCard}>
      <div className={styles.uploadSection}>
        <form onSubmit={handleSubmit}>
          {attachment && (
            <span className="text-black block my-3 text-center text-sm font-medium">
              {attachment.name}
            </span>
          )}
          <label
            htmlFor="file-input"
            className="btn-secondary py-3 mx-auto px-6 block w-fit cursor-pointer"
          >
            {attachment ? "Upload Another File" : "Upload File"}
          </label>
          <input
            id="file-input"
            name="file-input"
            type="file"
            accept="application/pdf"
            hidden
            onChange={handleFileChange}
          />

          {fileError && (
            <p className="mt-3 ms-3 text-[red] text-sm font-medium text-center">
              {fileError}
            </p>
          )}
          <TextField
            fullWidth
            variant="outlined"
            placeholder="Enter URL"
            margin="normal"
            required
            className="bg-white"
            value={url}
            onChange={handleUrlChange}
          />
          <button
            className="btn-primary px-5 py-3 my-3  mx-auto flex items-center gap-2"
            type="submit"
            disabled={isLoading}
          >
            Submit{isLoading && "ting"} Task
            {isLoading && (
              <span className=" border-gray-300 h-5 w-5 animate-spin rounded-full border-2 border-t-blue-600 block"></span>
            )}
          </button>

          {apiError && (
            <p className="mt-3 ms-3 text-[red] text-sm font-medium">
              {apiError}
            </p>
          )}
        </form>
      </div>
    </div>
  );
}

import React, { forwardRef } from "react";
import { apiPatchAcceptGig } from "../../../../Services/ControlPanel/apiPatchAcceptGig";
import CustomButton from "../../../shared/CustomButton";
import { toast } from "react-toastify";
import { useMutation } from "@tanstack/react-query";

const ApproveDialog = forwardRef(({ onClose, id, handleRefetch }, ref) => {
  const mutation = useMutation({
    mutationFn: apiPatchAcceptGig,
  });

  const { isPending } = mutation;

  const handleAccept = () => {
    mutation.mutate(
      { id },
      {
        onSuccess: () => {
          toast.success("Gig accepted successfully");
          onClose();
          handleRefetch();
        },
        onError: (err) => {
          toast.error("Error accepting gig");
        },
      }
    );
  };

  return (
    <dialog
      ref={ref}
      className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible"
    >
      <div className="flex justify-end mb-4">
        <button
          onClick={onClose}
          className="text-[#71747B] hover:text-gray-900"
        >
          ✖
        </button>
      </div>
      <div className="flex flex-col items-start mt-4 gap-2">
        <span className="text-[#000000] text-base font-bold">
          Are you sure you want to proceed with this action?
        </span>
        <p className="whitespace-pre-wrap text-[#828282] text-left">
          You can either approve or reject this gig. Approving will mark the gig
          as completed and notify the student, while rejecting will require you
          to provide a reason for the rejection. Please confirm your decision.
        </p>
      </div>
      <div className="flex justify-center mt-6">
        <CustomButton
          type="submit"
          isLoading={isPending}
          onClick={handleAccept}
          className="btn-primary font-semibold text-base px-5 py-3 rounded-lg"
        >
          Confirm
        </CustomButton>
        <button
          onClick={onClose}
          className="font-semibold text-base px-5 py-3 rounded-lg ml-3 border text-[#71747B] border-solid border-gray-300 bg-white"
        >
          Cancel
        </button>
      </div>
    </dialog>
  );
});

export default ApproveDialog;

import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { useEffect } from "react";
import { Controller, useForm } from "react-hook-form";
import { FiInfo } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { apiPatchAnnouncement } from "../../../Services/Announcements";
import { useDialog } from "../../shared/CustomDialog";
import LabeledInput from "../../shared/LabeledInput";
import SelectInput from "../../shared/SelectInput";
import CustomButton from "../../shared/CustomButton";
import { isArabic } from "../../../Helpers/isArabic";

const CPEditAnnouncementForm = ({
  announcement,
  title,
  codes,
  paramName,
  refetch,
}) => {
  const mutation = useMutation({
    mutationFn: (data) => apiPatchAnnouncement(announcement?.id, data),
  });
  const { closeDialog } = useDialog();
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(100, "Title too long"),
    description: Yup.string()
      .required("Announcement text is required")
      .max(500, "Announcement must be at most 500 characters"),
    link: Yup.string()
      .url("Enter a valid URL")
      .matches(/^https:\/\//, "URL must start with https://"),
    [paramName]: Yup.string().required(`test is required`),
    status: Yup.string().required("Status is required"),
  });

  const {
    reset,
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
  });
  const onSubmit = (data) => {
    const requestBody = {
      ...data,
      [paramName]: [data[paramName]],
    };
    mutation.mutate(requestBody, {
      onSuccess: () => {
        toast.success(`Announcement Updated successfully`);
        refetch();
        closeDialog();
      },
      onError: (err) => {
        setError("root", {
          message: err.response.data.detail || "Some thing went wrong",
        });
        toast.error("Error updating announcement");
      },
    });
  };
  useEffect(() => {
    if (announcement) {
      reset({
        title: announcement?.title,
        description: announcement?.description,
        link: announcement?.link,
        [paramName]: announcement?.[paramName][0],
        status: announcement?.status,
      });
    }
  }, [announcement]);
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col gap-4 px-2 pb-4 py-5"
    >
      <div role="alert" className="alert bg-[#28499D17] rounded-md ">
        <FiInfo className="text-primary size-[1.2rem]" />
        <p className="text-base text-[#3E3E3E] lowercase whitespace-break-spaces">
          Only the most recent announcement will be visible to the {title}.
          Ensure your message is clear and concise
        </p>
      </div>
      <LabeledInput
        labelClassName={"text-start"}
        label={"Title"}
        placeholder={"Write your title"}
        fieldName={"title"}
        register={register}
        errors={errors}
      />
      <div>
        <label className="text-[#3F3F3F] text-start text-base font-medium mb-2 block">
          Announcement
        </label>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <textarea
              onChange={onChange}
              className={`textarea textarea-bordered resize-none block rounded w-full h-[10rem] ${
                errors["description"] ? "border-[red]" : ""
              } `}
              placeholder="Write your announcement"
              style={{
                direction: isArabic(value) ? "rtl" : "ltr",
                textAlign: isArabic(value) ? "right" : "left",
              }}
              defaultValue={value}
            />
          )}
        />

        {errors["description"] && (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {errors["description"].message}
          </div>
        )}
      </div>
      <LabeledInput
        labelClassName={"text-start"}
        label={"Link"}
        placeholder={"Insert link"}
        fieldName={"link"}
        register={register}
        errors={errors}
      />
      <SelectInput
        items={codes}
        className="border-[var(--fallback-bc,oklch(var(--bc)/0.2))]"
        name={paramName}
        defaultValue={""}
        label={title}
        register={register}
        placeholderText={`Select ${title}`}
        errors={errors}
      />
      <SelectInput
        items={["Active", "Expired"]}
        className="border-[var(--fallback-bc,oklch(var(--bc)/0.2))]"
        name={"status"}
        defaultValue={""}
        label={"Status"}
        register={register}
        placeholderText={`Select Status`}
        errors={errors}
      />

      <div className="w-full flex justify-end gap-4 items-stretch mt-4">
        <CustomButton
          className="py-3"
          type="submit"
          isLoading={mutation.isPending}
        >
          Update
        </CustomButton>
        <CustomButton
          className="text-[#E22A2A] border border-solid border-[#EE201C] bg-white hover:bg-red-50 py-3"
          onClick={closeDialog}
        >
          Cancel
        </CustomButton>
      </div>

      {errors.root && (
        <div className="border border-red-900 p-5 text-red-700">
          {errors.root.message}
        </div>
      )}
    </form>
  );
};

export default CPEditAnnouncementForm;

import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import GppMaybeOutlinedIcon from '@mui/icons-material/GppMaybeOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import UploadFileIcon from '@mui/icons-material/UploadFile';
import {
  Alert,
  CircularProgress,
  Snackbar,
  TextField,
  Typography,
} from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { TypeAnimation } from 'react-type-animation';
import { pageRoutes } from '../../../constants';
import styles from './AIReviewer.module.css';

function AIReviewer() {
  const [jobTitle, setJobTitle] = useState('');
  const [jobDescription, setJobDescription] = useState('');
  const [file, setFile] = useState(null);
  const [returnData, setReturnData] = useState('');
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });
  const [loading, setLoading] = useState(false);
  const isButtonDisabled = !jobTitle || !jobDescription || !file || loading;

  const handleFileChange = (e) => {
    setFile(e.target.files[0]);
  };

  const handleSubmit = async () => {
    if (!file || !jobTitle || !jobDescription) {
      setSnackbar({
        open: true,
        message: 'Please fill in all fields and select a file.',
        severity: 'error',
      });
      return;
    }

    setLoading(true);

    const formData = new FormData();
    formData.append('file', file);
    formData.append('job_title', jobTitle);
    formData.append('job_description', jobDescription);

    try {
      const response = await axios.post(
        'https://dfppback.eyouthlearning.com/proposal/enhance/',
        formData
      );
      setReturnData(response.data.enhanced_text);
    } catch (error) {
      console.error('Error uploading file:', error);
      setSnackbar({
        open: true,
        message: 'There was an error uploading the file.',
        severity: 'error',
      });
    } finally {
      setLoading(false);
    }
  };

  const handleCloseSnackbar = () => {
    setSnackbar({ ...snackbar, open: false });
  };

  return (
    <div className={styles.container}>
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert
          onClose={handleCloseSnackbar}
          severity={snackbar.severity}
          variant='filled'
        >
          {snackbar.message}
        </Alert>
      </Snackbar>

      <div className='flex justify-end'>
        <Link
          to={`/${pageRoutes.aiReviewer}/${pageRoutes.history}`}
          className='btn-primary p-5'
        >
          View Reviews History
        </Link>
      </div>
      <div className={styles.infoSections}>
        <div className={styles.infoCard}>
          <QuestionAnswerOutlinedIcon className={styles.icon} />
          <Typography variant='h6'>Examples</Typography>
          <Typography variant='body2' className={styles.firstbody}>
            Upload your proposal draft to get AI feedback on structure,
            language, and content.
          </Typography>
          <Typography variant='body2' className={styles.firstbody}>
            Test different versions of your proposal to see which one receives
            the best AI suggestions.
          </Typography>
        </div>
        <div className={styles.infoCard}>
          <AutoAwesomeOutlinedIcon className={styles.icon} />
          <Typography variant='h6'>Capabilities</Typography>
          <Typography variant='body2' className={styles.firstbody}>
            AI provides detailed feedback on clarity, grammar, and
            professionalism.
          </Typography>
          <Typography variant='body2' className={styles.firstbody}>
            Get insights on areas for improvement before presenting to your
            client.
          </Typography>
        </div>
        <div className={styles.infoCard}>
          <GppMaybeOutlinedIcon className={styles.icon} />
          <Typography variant='h6'>Limitations</Typography>
          <Typography variant='body2' className={styles.firstbody}>
            Limited to 20 reviews per user.
          </Typography>
          <Typography variant='body2' className={styles.firstbody}>
            Feedback is based on general quality standards and may require
            further customization for specific client needs.
          </Typography>
        </div>
      </div>

      <div className='flex flex-col w-[80%] md:w-[50%] align-center justify-center m-auto mb-5'>
        <TextField
          className='mb-5'
          label='Job Title'
          variant='standard'
          value={jobTitle}
          onChange={(e) => setJobTitle(e.target.value)}
        />
        <TextField
          className='mb-2'
          label='Job Description'
          variant='standard'
          value={jobDescription}
          onChange={(e) => setJobDescription(e.target.value)}
        />
      </div>

      <div className={styles.uploadSection}>
        <label
          htmlFor='fileInput'
          className='flex flex-col items-center cursor-pointer'
        >
          <UploadFileIcon fontSize='large' className={styles.uploadIcon} />

          <span className='text-gray-7e text-base block mt-2'>
            Click to Upload Your Proposal File
          </span>
        </label>
        <input
          accept='.pdf'
          type='file'
          id='fileInput'
          hidden
          onChange={handleFileChange}
        />
        {file && (
          <Typography variant='body2' color='textSecondary' className='mt-2'>
            Selected File: {file.name}
          </Typography>
        )}
      </div>

      <div className='flex justify-center mt-2'>
        <button
          onClick={handleSubmit}
          disabled={isButtonDisabled}
          className={`btn btn-primary text-white rounded w-[20%] ${styles.buttonStyles}`}
        >
          {loading ? (
            <span className='flex items-center'>
              <CircularProgress size={20} color='inherit' className='mr-2' />{' '}
              Sending...
            </span>
          ) : (
            'Send'
          )}
        </button>
      </div>

      {returnData && (
        <div className={styles.returnData}>
          <TypeAnimation
            sequence={[returnData]}
            wrapper='span'
            speed={100}
            cursor={false}
            repeat={0}
          />
        </div>
      )}
    </div>
  );
}

export default AIReviewer;

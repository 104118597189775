import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  Rating,
  Snackbar,
  TextField,
} from "@mui/material";
import MuiAlert from "@mui/material/Alert";
import axios from "axios";
import React, { useRef, useState } from "react";
import Loading from "../../../Components/shared/Loading";
import CalenderIcon from "../../../Components/svgs/CalenderIcon";
import NetworkingIcon from "../../../Components/svgs/NetworkingIcon";
import Person from "../../../Components/svgs/Person";
import TimeIcon from "../../../Components/svgs/TimeIcon";
import ZoomIcon from "../../../Components/svgs/ZoomIcon";
import { useOutsideClick } from "../../../hooks/useClickOutside";
import useFetch from "../../../hooks/useFetch";
import { apiPostWebinarAttendance } from "../../../Services/apiPostWebinarAttendance";
import {
  allWebinarsFeedbackURL,
  allWebinarsURL,
} from "../../../Services/endPoints";
import dateFormat from "dateformat";
import styles from "./Webiners.module.css";

function Webiners() {
  const dropdownRef = useRef(null);
  const [filterOpen, setFilterOpen] = useState(false);
  const [feedbackOpen, setFeedbackOpen] = useState(false);
  const [rating, setRating] = useState(0);
  const [comment, setComment] = useState("");
  const [filter, setFilter] = useState("All");
  const [webinarId, setWebinarId] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [commentError, setCommentError] = useState(false);
  const [ratingError, setRatingError] = useState(false);
  useOutsideClick(dropdownRef, () => setFilterOpen(false));

  const apiURL = `${allWebinarsURL}?${
    filter === "Finished"
      ? "is_finished=true"
      : filter === "Upcoming"
      ? "is_finished=false"
      : ""
  }`;

  const {
    data: webinarData,
    loading: WebinarDataLoading,
    error: WebinarDataError,
  } = useFetch(apiURL, [snackbarOpen]);

  const toggleFilter = () => {
    setFilterOpen(!filterOpen);
  };

  const openFeedback = (id) => {
    setWebinarId(id);
    setFeedbackOpen(true);
  };

  const closeFeedback = () => {
    setFeedbackOpen(false);
    setComment("");
    setRating(0);
    setCommentError(false);
    setRatingError(false);
  };
  const handleFilterChange = (newFilter) => {
    setFilter(newFilter);
    setFilterOpen(false);
  };
  const submitFeedback = async () => {
    if (!comment) setCommentError(true);
    if (!rating) setRatingError(true);
    if (!comment || !rating) return;
    try {
      const response = await axios.post(`${allWebinarsFeedbackURL}`, {
        webinar: webinarId,
        feedback: comment,
        rating: rating,
      });
      closeFeedback();
      // setSnackbarOpen(true);
      setSnackbarOpen((prev) => !prev);
    } catch (error) {
      console.error("Error submitting feedback:", error);
    }
  };

  const handleSnackbarClose = () => {
    setSnackbarOpen(false);
  };
  const joinWebinar = async (webinar) => {
    window.open(webinar.link, "_blank");
    await apiPostWebinarAttendance(webinar.id);
  };

  return (
    <div className={styles.dashboardOverview}>
      <div className={styles.filterContainer} ref={dropdownRef}>
        <button className={styles.filterDropdown} onClick={toggleFilter}>
          {filter}
          <span className={styles.arrowDown}>&#x25BC;</span>
        </button>
        {filterOpen && (
          <div className={styles.filterOptions}>
            <p onClick={() => handleFilterChange("All")}>All</p>
            <p onClick={() => handleFilterChange("Upcoming")}>Upcoming</p>
            <p onClick={() => handleFilterChange("Finished")}>Finished</p>
          </div>
        )}
      </div>

      {WebinarDataLoading && <Loading />}
      {WebinarDataError && (
        <p className="text-gray-5f text-center font-semibold w-full text-xl absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
          Something went wrong !
        </p>
      )}

      {!WebinarDataLoading && webinarData?.results?.length === 0 && (
        <p className="text-gray-5f text-center font-semibold w-full text-xl absolute-center">
          This is no webinars yet.
        </p>
      )}
      {!WebinarDataLoading && webinarData?.results?.length > 0 && (
        <div className="flex items-center flex-wrap gap-y-[1rem] justify-between">
          {webinarData?.results?.map((webinar) => (
            <div key={webinar.id} className={styles.webinarCard}>
              <div className={styles.Feedback}>
                <h3 className="max-w-[18rem] truncate">{webinar.name}</h3>
                {!webinar.can_make_feedback ? null : (
                  <button onClick={() => openFeedback(webinar.id)}>
                    Leave Feedback
                  </button>
                )}
              </div>

              <div className={`${styles.calendarIcons} mb-3 mt-2`}>
                <p>
                  <CalenderIcon className="w-[1rem] h-[1rem] mr-2" />
                  {new Date(webinar.date).toLocaleDateString()}
                </p>
                <p className="w-[50%]">
                  <Person className="w-[1rem] h-[1rem] mr-2" />
                  {webinar.instructor_name}
                </p>
              </div>
              <div className={`${styles.calendarIcons} mb-3`}>
                <p>
                  <TimeIcon className="w-[1rem] h-[1rem] mr-2" />
                  {dateFormat(`1970-01-01T${webinar.time}`, "HH:MM")}
                </p>
                <p className="w-[50%]">
                  <ZoomIcon className="w-[1rem] h-[1rem] mr-2" /> Zoom
                </p>
              </div>
              <div className={`${styles.calendarIcons} mb-4`}>
                <p>
                  <NetworkingIcon className="w-[1.2rem] h-[1.2rem] mr-2" /> Job
                  Profile Round: {webinar.job_profile_round}
                </p>
              </div>
              {!webinar.is_finished &&
                !webinar?.link &&
                !webinar?.cant_join && (
                  <span className="text-gray-82 block bg-gray-250 py-3 rounded-lg w-full text-center">
                    Upcoming Webinar
                  </span>
                )}
              {!webinar.is_finished && webinar?.link && (
                <button
                  onClick={() => joinWebinar(webinar)}
                  className="btn-primary w-full py-3"
                >
                  Join Webinar
                </button>
              )}
              {!webinar.is_finished && webinar?.cant_join && (
                <span className="text-gray-82 block bg-gray-250 py-3 rounded-lg w-full text-center">
                  You can't join webinar now, wait for webinar record
                </span>
              )}

              {webinar.is_finished && !webinar?.is_recorded_link && (
                <span className="text-gray-82 block bg-gray-250 py-3 rounded-lg w-full text-center">
                  Webinar Record will be available soon
                </span>
              )}

              {webinar.is_finished && webinar?.is_recorded_link && (
                <a
                  href={webinar.link}
                  target="_blank"
                  rel="noopener noreferrer"
                  className="btn-primary w-full py-3"
                >
                  See Recorded Webinar
                </a>
              )}
            </div>
          ))}
        </div>
      )}

      <Dialog open={feedbackOpen} onClose={closeFeedback}>
        <h2 className="font-bold mt-5 text-2xl text-left ml-5">
          Share your experience in scaling
        </h2>
        <DialogContent>
          <Rating
            sx={{ fontSize: "3rem" }}
            name="rating"
            value={rating}
            onChange={(event, newValue) => {
              setRating(newValue);
              setRatingError(false);
            }}
          />
          {ratingError && (
            <p style={{ color: "red", fontSize: "0.8rem" }}>
              Please select a rating.
            </p>
          )}
          <TextField
            label="Add your comments..."
            multiline
            rows={5}
            variant="outlined"
            fullWidth
            value={comment}
            onChange={(e) => {
              setComment(e.target.value);
              setCommentError(false);
            }}
            style={{ marginTop: "16px", width: "100%" }}
            error={commentError}
            helperText={commentError ? "Comment is required" : ""}
            inputProps={{ dir: "auto" }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={submitFeedback} className={styles.buttonSubmit}>
            Submit
          </Button>
          <Button
            onClick={closeFeedback}
            className={styles.buttonCancel}
            color="secondary"
          >
            Cancel
          </Button>
        </DialogActions>
      </Dialog>

      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        anchorOrigin={{ vertical: "bottom", horizontal: "center" }}
      >
        <MuiAlert
          onClose={handleSnackbarClose}
          severity="success"
          sx={{ width: "100%" }}
        >
          Feedback submitted successfully!
        </MuiAlert>
      </Snackbar>
    </div>
  );
}

export default Webiners;

import { GoLink } from "react-icons/go";
import announcementImage from "../../../assets/announcement.png";
const AnnouncementItem = ({ announcement }) => {
  return (
    <div className="flex bg-primary border rounded-2xl py-5 text-white ps-[3rem] pe-[10%]  ">
      <div className="flex flex-col gap-5 mt-4">
        <h2 className="text-3xl font-semibold">{announcement?.title}</h2>
        <p className="font-light">{announcement?.description}</p>
        <a
          href={announcement?.link}
          target="_blank"
          rel="noreferrer"
          className="flex items-center text-sm"
        >
          <GoLink className="mr-2 size-[1.1rem]" />
          <p className="text-sm underline font-light">View link</p>
        </a>
      </div>

      <div className="md:block hidden ms-auto">
        <img src={announcementImage} alt="Announcement" />
      </div>
    </div>
  );
};

export default AnnouncementItem;

import axios from "axios";
import React, { useState } from "react";
import CustomButton from "../../../../Components/shared/CustomButton";
import { UserRequestSupportEndPoint } from "../../../../Services/endPoints";
import { toast } from "react-toastify";

function RequestSupport() {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    issue: "",
  });

  const [errors, setErrors] = useState({}); // State to store form errors
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = (e) => {
    const { id, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [id]: value,
    }));
    setErrors((prevErrors) => ({ ...prevErrors, [id]: "" })); // Clear specific field error
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      setIsLoading(true);
      await axios.post(`${UserRequestSupportEndPoint}`, {
        name: formData.name,
        email: formData.email,
        issue: formData.issue,
      });
      setIsLoading(false);

      toast.success("Support request submitted successfully!");
      setFormData({
        name: "",
        email: "",
        issue: "",
      });
      setErrors({}); // Clear errors on success
    } catch (error) {
      console.error("Error submitting support request:", error);
      setIsLoading(false);
      if (error.response && error.response.data) {
        // API returned validation errors
        setErrors(error.response.data);
        toast.error("Failed to submit support request.");
      } else {
        // Generic error message
        toast.error("An unexpected error occurred.");
      }
    }
  };

  const formIsValid = formData.name && formData.email && formData.issue;

  return (
    <div className="shadow-lg p-5">
      <h2 className="text-[#000000DE] font-medium text-2xl md:text-xl">
        Request Support
      </h2>
      <form
        className="my-5 flex flex-col gap-5 max-w-[30rem]"
        onSubmit={handleSubmit}
      >
        <div className="">
          <label
            htmlFor="name"
            className="block mb-2 text-base md:text-sm font-medium text-gray-900"
          >
            Name
          </label>
          <input
            type="text"
            id="name"
            value={formData.name}
            onChange={handleChange}
            className={`bg-gray-50 border ${
              errors.name ? "border-red-500" : "border-gray-300"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 max-w-[30rem]`}
            placeholder="Example"
            required
          />
          {errors.name && (
            <p className="text-red-500 text-xs mt-1">{errors.name}</p>
          )}
        </div>
        <div className="">
          <label
            htmlFor="email"
            className="block mb-2 text-base md:text-sm font-medium text-gray-900 "
          >
            Email
          </label>
          <input
            type="text"
            id="email"
            value={formData.email}
            onChange={handleChange}
            className={`bg-gray-50 border ${
              errors.email ? "border-red-500" : "border-gray-300"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 max-w-[30rem]  `}
            placeholder="example@mail.com"
            required
          />
          {errors.email && (
            <p className="text-red-500 text-xs mt-1">
              {errors.email.join(", ")}
            </p>
          )}
        </div>
        <div className="">
          <label
            htmlFor="issue"
            className="block mb-2 text-base md:text-sm font-medium text-gray-900"
          >
            Issue
          </label>
          <textarea
            dir="auto"
            rows={5}
            id="issue"
            value={formData.issue}
            onChange={handleChange}
            className={`bg-gray-50 border ${
              errors.issue ? "border-red-500" : "border-gray-300"
            } text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 max-w-[30rem] resize-none`}
            placeholder="Describe your issue"
            required
          />
          {errors.issue && (
            <p className="text-red-500 text-xs mt-1">{errors.issue}</p>
          )}
        </div>
        <CustomButton
          type="submit"
          disabled={!formIsValid}
          isLoading={isLoading}
          className="mt-5 w-fit py-3 px-6 text-lg rounded-md mx-auto block"
        >
          Submit
        </CustomButton>
      </form>
    </div>
  );
}

export default RequestSupport;

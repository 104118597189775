import { Dialog, DialogActions, DialogContent, TextField } from "@mui/material";
import axios from "axios";
import React, { memo, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Loading from "../../../../Components/shared/Loading";
import CalenderIcon from "../../../../Components/svgs/CalenderIcon";
import NetworkingIcon from "../../../../Components/svgs/NetworkingIcon";
import Person from "../../../../Components/svgs/Person";
import QuestionIcon from "../../../../Components/svgs/QuestionIcon";
import useFetch from "../../../../hooks/useFetch";
import usePagination from "../../../../hooks/usePagination";
import {
  QAAnsweEndPoint,
  QAInstructorEndPoint,
} from "../../../../Services/endPoints";
import Card from "../../Components/Common/Card/Card";
import CardSkeleton from "../Common/Card/CardSkeleton";

const QAComponent = ({ pageTitle, isPaginationDisabled = false }) => {
  const [isAnswerDialogOpen, setAnswerDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedQuestion, setSelectedQuestion] = useState(null);
  const [answer, setAnswer] = useState("");
  const [error, setError] = useState("");
  const [isStatusChanged, setIsStatusChanged] = useState(false);
  const [searchParams] = useSearchParams();

  const {
    data: QADataPaginated,
    isLoading: paginationLoading,
    error: paginationError,
  } = usePagination(
    `${QAInstructorEndPoint}?${searchParams.toString()}`,
    isStatusChanged,
    isPaginationDisabled
  );
  const {
    data: QAData,
    loading: qaLoading,
    error: fetchError,
  } = useFetch(
    `${QAInstructorEndPoint}?page=1&page_size=5`,
    [isStatusChanged],
    !isPaginationDisabled
  );
  if (qaLoading && isPaginationDisabled) {
    return <Loading />;
  }

  if (paginationError || fetchError) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-xl my-3">
        Something went wrong !
      </p>
    );
  }
  const dataToRender = isPaginationDisabled ? QAData?.results : QADataPaginated;
  if (dataToRender?.length === 0 && (!qaLoading || !paginationLoading)) {
    return (
      <p className="text-gray-5f text-center font-semibold w-full text-lg mt-[1rem]">
        No questions asked yet.
      </p>
    );
  }

  const handleAnswerClick = (question) => {
    setSelectedQuestion(question);
    setAnswer("");
    setError("");
    setAnswerDialogOpen(true);
  };

  const handleEditClick = (question) => {
    setSelectedQuestion(question); // Set the entire question object
    setAnswer(question.answer?.answer || ""); // Pre-fill with the existing answer if available
    setError("");
    setAnswerDialogOpen(true);
  };

  const handleSaveAnswer = () => {
    if (!answer.trim()) {
      setError("Answer cannot be empty.");
      return;
    }
    setError("");
    setAnswerDialogOpen(false);
    setConfirmDialogOpen(true);
  };

  const handleConfirmSave = async () => {
    setConfirmDialogOpen(false);

    const payload = {
      question: selectedQuestion?.id, // Question ID
      answer,
    };

    try {
      if (selectedQuestion?.answer?.id) {
        // PATCH request for editing an existing answer
        const response = await axios.patch(
          `${QAAnsweEndPoint}${selectedQuestion.answer.id}/`,
          payload
        );
        setIsStatusChanged((prev) => !prev);
        if (response.status === 200) {
          toast.success("Answer updated successfully!");
        } else {
          toast.error("Failed to update the answer.");
        }
      } else {
        // POST request for adding a new answer
        const response = await axios.post(`${QAAnsweEndPoint}`, payload);
        setIsStatusChanged((prev) => !prev);

        if (response.status === 201) {
          toast.success("Answer saved successfully!");
        } else {
          toast.error("Failed to save the answer.");
        }
      }
    } catch (error) {
      toast.error("Error saving answer: " + error.message);
    }
  };

  return (
    <div>
      {pageTitle && (
        <h1 className="mt-5 mb-3 font-medium text-base">{pageTitle}</h1>
      )}

      <div className="grid 2xl:grid-cols-3 sm:grid-cols-2 grid-cols-1 gap-3">
        {dataToRender?.map((question, index) => (
          <Card
            key={index}
            content={[
              {
                icon: Person,
                title: "Asked by : ",
                content: question?.student || "N/A",
              },
              {
                icon: CalenderIcon,
                title: "Asked On : ",
                content:
                  new Date(question?.created_at).toLocaleDateString("en-GB") ||
                  "N/A",
              },
              {
                icon: NetworkingIcon,
                title: "Category : ",
                content: question?.category?.name || "N/A",
              },
              {
                icon: QuestionIcon,
                title: "Question : ",
                content: question?.question_text || "N/A",
              },
            ]}
            buttonClassName={`bg-blue-800 ${
              question?.answered
                ? "opacity-50 cursor-not-allowed"
                : "hover:bg-blue-800"
            } text-white py-2 w-full text-center rounded-xl`}
            buttonContent="Answer"
            onClick={
              !question?.answered
                ? () => handleAnswerClick(question)
                : undefined
            }
            disabledButton={!!question?.answer}
            editIcon={!!question?.answer}
            onEditClick={() => handleEditClick(question)} // Pass the edit click handler
          />
        ))}
        {paginationLoading && !isPaginationDisabled && (
          <>
            <CardSkeleton />
            <CardSkeleton />
          </>
        )}
      </div>
      {/* Answer Dialog */}
      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "600px",
          },
        }}
        open={isAnswerDialogOpen}
        onClose={() => setAnswerDialogOpen(false)}
      >
        <DialogContent className="p-5">
          <p className="text-center p-5">
            <strong>Asked by:</strong> {selectedQuestion?.student}
          </p>
          <p className="py-5 text-left text-[#828282]">
            {selectedQuestion?.question_text}
          </p>
          <textarea
            rows={4}
            value={answer}
            onChange={(e) => setAnswer(e.target.value)}
            placeholder="Your Answer..."
            dir="auto"
            className={`w-full border border-gray-300 rounded-md p-2 ${
              error ? "border-red-500" : ""
            }`}
          />
        </DialogContent>
        <DialogActions>
          <button onClick={handleSaveAnswer} className="btn btn-primary">
            Save
          </button>
          <button
            type="button"
            onClick={() => setAnswerDialogOpen(false)}
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
          >
            Cancel
          </button>
        </DialogActions>
      </Dialog>

      <Dialog
        sx={{
          "& .MuiDialog-paper": {
            width: "100%",
            maxWidth: "600px",
          },
        }}
        open={isConfirmDialogOpen}
        onClose={() => setConfirmDialogOpen(false)}
      >
        <DialogContent>
          <p className="p-5">
            <strong>Asked by: </strong> {selectedQuestion?.student}
          </p>
          <p className="p-5">{selectedQuestion?.question_text}</p>
          <p className="p-5">
            <strong>Your Answer: </strong> {answer}
          </p>
        </DialogContent>
        <DialogActions>
          <button
            style={{ border: "1px solid #EE201C" }}
            className="btn bg-white hover:bg-white text-[#EE201C]"
            onClick={() => setConfirmDialogOpen(false)}
          >
            Cancel
          </button>
          <button className="btn btn-primary" onClick={handleConfirmSave}>
            Confirm
          </button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

export default memo(QAComponent);

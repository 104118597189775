import CustomButton from "../CustomButton";
import { useDialog } from "../CustomDialog";

const DeleteDialogActions = ({ deleteMutation }) => {
  const { closeDialog } = useDialog();
  return (
    <div className="flex mt-auto justify-end gap-4">
      <CustomButton
        className="bg-transparent text-gray-82 hover:bg-transparent"
        onClick={closeDialog}
      >
        Cancel
      </CustomButton>
      <CustomButton
        className="text-[#E22A2A] bg-white border border-solid border-[#EE201C] rounded-lg hover:bg-red-50 disabled:bg-red-50 py-1"
        onClick={() => deleteMutation.mutate()}
        isLoading={deleteMutation.isPending}
      >
        Delete
      </CustomButton>
    </div>
  );
};

export default DeleteDialogActions;

import { MenuItem, Select } from "@mui/material";
import React, { useState } from "react";
import { FaCalendarAlt, FaRegEdit } from "react-icons/fa";
import { HiOutlineBuildingOffice2 } from "react-icons/hi2";
import { IoIosPricetags } from "react-icons/io";
import CustomButton from "../../../Components/shared/CustomButton";
import NoteIcon from "../../../Components/svgs/NoteIcon";
import CommentsDialog from "./CommentsDialog";
import RateDialog from "./RateDialog";
import DeleteConfirmationDialog from "../../../Components/shared/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiDeleteGig } from "../../../Services/Students/apiDeleteGig";

const JobCard = ({ job, onStatusChange, onEditClick, onCommentsOpen }) => {
  const queryClient = useQueryClient();
  const [commentsOpen, setCommentsOpen] = useState(false);
  const [rateOpen, setRateOpen] = useState(false);
  const mutation = useMutation({
    mutationFn: () => apiDeleteGig(job.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["gigs"] });
    },
  });
  return (
    <div className="bg-[#fff] border border-[#ddd] rounded-lg p-5 flex flex-col shadow-md flex-1">
      <div className="w-fit ml-auto mb-1 flex items-center">
        {job.status === "Accepted" ? (
          <>
            {job.is_approved === false && job.waiting_for_approval === true ? (
              <div className="text-[#daa520] text-base font-normal text-center p-2 rounded-md border-2 border-[#daa520]">
                In Review
              </div>
            ) : job.is_approved === true &&
              job.waiting_for_approval === false ? (
              <div className="text-[#4caf50] text-base font-normal text-center p-2 rounded-md border-2 border-[#4caf50]">
                Accepted
              </div>
            ) : (
              <div className="text-[#ff4c4c] text-base font-normal text-center p-2 rounded-md border-2 border-[#ff4c4c] under">
                Rejected
              </div>
            )}
          </>
        ) : (
          <Select
            value={job.status}
            onChange={(e) => onStatusChange(e, job.id)}
            variant="outlined"
            style={{
              border: "1px solid blue",
              color: "blue",
              borderRadius: "5px",
              padding: "0px",
              marginInline: "0px",
            }}
          >
            {job.status !== "In Progress" &&
              job.status !== "Accepted" &&
              job.status !== "Rejected" && (
                <MenuItem value="Proposal Submitted">
                  Proposal Submitted
                </MenuItem>
              )}
            {job.status !== "Accepted" && job.status !== "Rejected" && (
              <MenuItem value="In Progress">In Progress</MenuItem>
            )}
            <MenuItem value="Accepted">Accepted</MenuItem>
            <MenuItem value="Rejected">Rejected</MenuItem>
          </Select>
        )}
        {job.status === "Accepted" &&
          !job.is_approved &&
          !job.waiting_for_approval && (
            <FaRegEdit
              onClick={() => onEditClick(job)}
              className="size-8 ml-2 text-gray-7e hover:cursor-pointer hover:text-primary"
            />
          )}
      </div>
      <h3 className="text-black font-medium text-[1.3rem] max-w-[100%] break-words mb-3">
        {job.title}
      </h3>

      <div className="text-[#828282] mb-4">
        <div className="flex justify-between">
          <p className="flex items-center gap-2">
            <HiOutlineBuildingOffice2 className="size-4 text-gray-7e" />
            {job.platform}
          </p>
          <p className="w-[50%] flex items-center gap-2">
            <FaCalendarAlt className="size-4 text-gray-7e" />
            {new Date(job.created_on).toLocaleDateString()}
          </p>
        </div>
        <div className="flex justify-between">
          <p className="w-[50%] flex items-center gap-2">
            <NoteIcon className="size-4 text-gray-7e" /> {job.category}
          </p>
          <p className="w-[50%] flex items-center gap-2">
            <IoIosPricetags className="size-4 text-gray-7e" />
            {job.price} USD
          </p>
        </div>
      </div>

      <div className="flex flex-col w-full gap-4 mt-auto">
        <a
          className="btn-primary w-full py-3"
          href={job.url}
          target="_blank"
          rel="noopener noreferrer"
        >
          View Link
        </a>
        {job.admin_status === "Rejected" && job.comment && (
          <CustomButton
            onClick={() => setCommentsOpen(true)}
            className="bg-transparent border-primary text-primary hover:bg-slate-200 border border-solid"
          >
            See Admin Comments
          </CustomButton>
        )}

        {job?.rate && job?.rate?.id && (
          <CustomButton
            onClick={() => setRateOpen(true)}
            className="bg-transparent border-primary text-primary hover:bg-slate-200 border border-solid "
          >
            See Instructor Comment
          </CustomButton>
        )}
        {job.status !== "In Progress" &&
          job.status !== "Accepted" &&
          job.status !== "Rejected" && (
            <DeleteConfirmationDialog
              name="gig"
              renderOpenButton={(openDialog) => (
                <CustomButton
                  onClick={openDialog}
                  className="text-[#E22A2A] bg-white border border-solid border-[#EE201C] rounded-lg hover:bg-red-50 disabled:bg-red-50 flex-1"
                >
                  Delete
                </CustomButton>
              )}
              deleteMutation={mutation}
            />
          )}
      </div>
      <CommentsDialog
        isOpen={commentsOpen}
        onClose={() => setCommentsOpen(false)}
        comment={job.comment?.comment}
      />

      <RateDialog
        isOpen={rateOpen}
        onClose={() => setRateOpen(false)}
        comment={job.rate}
      />
    </div>
  );
};

export default JobCard;

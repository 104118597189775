import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation } from "@tanstack/react-query";
import { Controller, useForm, useWatch } from "react-hook-form";
import { FiInfo } from "react-icons/fi";
import { toast } from "react-toastify";
import * as Yup from "yup";
import { useDialog } from "../../shared/CustomDialog";
import { apiPostAnnouncement } from "../../../Services/Announcements";
import LabeledInput from "../../shared/LabeledInput";
import SelectInput from "../../shared/SelectInput";
import CustomButton from "../../shared/CustomButton";
import { isArabic } from "../../../Helpers/isArabic";
import { useAdminDataContext } from "../../../providers/AdminDataProvider";

const CPPublishAnnouncementForm = ({ refetch }) => {
  const mutation = useMutation({
    mutationFn: apiPostAnnouncement,
  });
  const { coachingRounds, jobProfiles } = useAdminDataContext();

  const { closeDialog } = useDialog();
  const validationSchema = Yup.object().shape({
    title: Yup.string()
      .required("Title is required")
      .max(100, "Title too long"),
    description: Yup.string()
      .required("Announcement text is required")
      .max(500, "Announcement must be at most 500 characters"),
    link: Yup.string()
      .url("Enter a valid URL")
      .matches(/^https:\/\//, "URL must start with https://"),
    type: Yup.string()
      .oneOf(["coachingRound", "jobProfile"], "Type is required")
      .required("Type is required"),
    coaching_rounds: Yup.string().when("type", (type, schema) =>
      type === "coachingRound"
        ? schema.required("Coaching Round is required")
        : schema.notRequired()
    ),
    job_profile_rounds: Yup.string().when("type", (type, schema) =>
      type === "jobProfile"
        ? schema.required("Job Profile is required")
        : schema.notRequired()
    ),
  });

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      type: "coachingRound",
    },
  });
  const typeValue = useWatch({
    control,
    name: "type",
  });
  const onSubmit = (data) => {
    const paramName =
      typeValue === "coachingRound" ? "coaching_rounds" : "job_profile_rounds";
    const requestBody = {
      title: data.title,
      description: data.description,
      link: data.link,
      [paramName]: [data[paramName]],
    };
    mutation.mutate(requestBody, {
      onSuccess: () => {
        toast.success(`Announcement Added successfully`);
        refetch();
        closeDialog();
      },
      onError: (err) => {
        setError("root", {
          message: err.response.data.detail || "Some thing went wrong",
        });
        toast.error("Error adding announcement");
      },
    });
  };
  const title =
    typeValue === "coachingRound" ? "Coaching Round" : "Job Profile";
  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col gap-4 px-2 pb-4 py-5"
    >
      <div role="alert" className="alert bg-[#28499D17] rounded-md">
        <FiInfo className="text-primary size-[1.2rem]" />
        <p className="text-base text-[#3E3E3E] lowercase">
          Only the most recent announcement will be visible to the {title} .
          Ensure your message is clear and concise
        </p>
      </div>
      <LabeledInput
        label={"Title"}
        placeholder={"Write your title"}
        fieldName={"title"}
        register={register}
        errors={errors}
      />
      <div>
        <label className="text-[#3F3F3F] text-base font-medium mb-2 block">
          Announcement
        </label>
        <Controller
          name="description"
          control={control}
          render={({ field: { onChange, value } }) => (
            <textarea
              onChange={onChange}
              className={`textarea textarea-bordered resize-none block rounded w-full h-[10rem] ${
                errors["description"] ? "border-[red]" : ""
              } `}
              placeholder="Write your announcement"
              style={{
                direction: isArabic(value) ? "rtl" : "ltr",
                textAlign: isArabic(value) ? "right" : "left",
              }}
              defaultValue={value}
            />
          )}
        />

        {errors["description"] && (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {errors["description"].message}
          </div>
        )}
      </div>
      <LabeledInput
        label={"Link"}
        placeholder={"Insert link"}
        fieldName={"link"}
        register={register}
        errors={errors}
      />

      <div className="flex gap-2 items-center my-2">
        <label htmlFor="type" className="text-sm">
          Select Type
        </label>
        <div className="flex gap-4 ms-[1.5rem]">
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <div className="flex gap-1 items-center">
                <input
                  {...field}
                  id="coachingRound"
                  type="radio"
                  name="type"
                  className="radio radio-primary border-solid border-gray-500 lg:size-[1.2rem] size-[1.5rem]  flex-shrink-0"
                  value="coachingRound"
                  checked={field.value === "coachingRound"}
                />
                <label htmlFor="coachingRound" className="text-sm">
                  Coaching Round
                </label>
              </div>
            )}
          />
          <Controller
            name="type"
            control={control}
            render={({ field }) => (
              <div className="flex gap-1 items-center">
                <input
                  {...field}
                  id="jobProfile"
                  type="radio"
                  name="type"
                  className="radio radio-primary border-solid border-gray-500 lg:size-[1.2rem] size-[1.5rem] flex-shrink-0"
                  value="jobProfile"
                  checked={field.value === "jobProfile"}
                />
                <label htmlFor="jobProfile" className="text-sm">
                  Job Profile
                </label>
              </div>
            )}
          />
        </div>
        {errors["type"] && (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {errors["type"].message}
          </div>
        )}
      </div>
      <SelectInput
        items={typeValue === "jobProfile" ? jobProfiles : coachingRounds}
        className="border-[var(--fallback-bc,oklch(var(--bc)/0.2))]"
        name={
          typeValue === "jobProfile" ? "job_profile_rounds" : "coaching_rounds"
        }
        defaultValue={""}
        label={title}
        register={register}
        placeholderText={`Select ${title}`}
        errors={errors}
      />
      {errors.root && (
        <div className=" text-[red]  text-center">{errors.root.message}</div>
      )}
      <div className="w-full flex justify-end gap-4 items-stretch mt-4">
        <CustomButton
          className="py-3"
          type="submit"
          isLoading={mutation.isPending}
        >
          Publish
        </CustomButton>
        <CustomButton
          className="text-[#E22A2A] border border-solid border-[#EE201C] bg-white hover:bg-red-50 py-3"
          onClick={closeDialog}
        >
          Cancel
        </CustomButton>
      </div>
    </form>
  );
};

export default CPPublishAnnouncementForm;

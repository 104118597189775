import React from 'react';
import EmailIcon from '../../../Components/svgs/EmailIcon';
import Person from '../../../Components/svgs/Person';
import PhoneIconSVG from '../../../Components/svgs/PhoneIconeSVG';
import { IoLogoLinkedin } from 'react-icons/io';

const InfoCard = ({
  profileImage,
  name,
  phone,
  email,
  linkedin,
  city,
  track,
  stats = [],
}) => {
  return (
    <div className='md:col-span-1 bg-white rounded-xl shadow-lg p-6 flex flex-col'>
      <div className='flex gap-4 items-center mb-6 flex-wrap'>
        {profileImage ? (
          <img
            src={profileImage}
            alt={name}
            className='w-16 h-16 rounded-full object-cover'
          />
        ) : (
          <div className='bg-gray-500 w-16 h-16 rounded-full flex items-center justify-center flex-shrink-0'>
            <Person className='text-white w-8 h-8' />
          </div>
        )}
        <div>
          <h2 className='text-[#273C97] w-max font-semibold text-2xl'>
            {name}
          </h2>
          <div className='flex gap-2 items-center justify-between'>
            <h2 className='text-[#5F5F5F] font-medium text-lg'>{city}</h2>
            <h2 className='text-[#5F5F5F] font-medium text-lg'>{track}</h2>
          </div>
          <div className='flex gap-2 items-center'>
            <PhoneIconSVG />
            <h3 className='text-[#5F5F5F] font-medium text-lg'>{phone}</h3>
          </div>
          <div className='flex items-center gap-x-2 w-full'>
            <EmailIcon />
            <h3 className='text-[#5F5F5F] font-medium text-lg w-[98%] break-words'>
              {email}
            </h3>
          </div>
          <div className='flex items-center gap-x-2 w-full'>
            <IoLogoLinkedin />
            <h3 className='text-[#5F5F5F] font-medium text-lg w-[98%] break-words'>
              {linkedin}
            </h3>
          </div>
        </div>
      </div>

      <div className='grid grid-cols-2 gap-4'>
        {stats.map((stat, index) => (
          <div key={index} className='flex gap-4 items-center'>
            <div className='bg-[#273C97] p-2 rounded-lg'>
              <stat.icon className='text-white w-6 h-6' />
            </div>
            <div>
              <span className='text-[#A0AEC0] font-semibold text-base'>
                {stat.label}
              </span>
              <h4 className='text-[#202020] font-medium text-base'>
                {stat.value}
              </h4>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default InfoCard;

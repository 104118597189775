import React from 'react';
import { GoPersonFill } from 'react-icons/go';
import CustomButton from '../../../Components/shared/CustomButton';
import PasswordInput from './PasswordInput';
import { useLoginForm } from './useLoginForm';

const LoginForm = () => {
  const {
    register,
    handleSubmit,
    onSubmit,
    isPending,
    formState: { errors },
  } = useLoginForm();

  return (
    <form onSubmit={handleSubmit(onSubmit)} className='w-full'>
      <div className='input border-b border-[#00000070] border-0 rounded-none flex items-center gap-2 mt-4 focus:border-none focus-within:border-none focus-visible:border-none focus:outline-none focus-within:outline-none focus-visible:outline-none bg-transparent'>
        <label htmlFor='email'>{/* <MdEmail /> */}</label>
        <input
          id='email'
          className='w-full'
          placeholder='Email'
          type='email'
          {...register('email')}
        />
        <GoPersonFill className='text-[#00000070]' />
      </div>
      {errors.email && (
        <p className='text-[red] ms-2 text-sm mt-3 w-full'>
          {errors.email.message}
        </p>
      )}

      <PasswordInput
        name='password'
        placeholder='Password'
        register={register}
        passwordError={errors.password}
      />

      <CustomButton
        type='submit'
        isLoading={isPending}
        className='w-full mt-[2rem]'
      >
        Login
      </CustomButton>
      {errors.root && (
        <p className='text-[red] text-sm mt-3 w-fit mx-auto'>
          {errors.root.message}
        </p>
      )}
    </form>
  );
};

export default LoginForm;

import { format, parseISO } from 'date-fns';
import React, { useEffect, useState } from 'react';
import ReactDatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import { useSearchParams } from 'react-router-dom';

const DateFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const initialDate = searchParams.get('date')
    ? parseISO(searchParams.get('date'))
    : null;

  const [selectedDate, setSelectedDate] = useState(initialDate);

  const handleDateChange = (date) => {
    setSelectedDate(date);
    const updatedParams = new URLSearchParams(searchParams);
    if (date) {
      const formattedDate = format(date, 'yyyy-MM-dd');
      updatedParams.set('date', formattedDate);
    } else {
      updatedParams.delete('date');
    }
    setSearchParams(updatedParams);
  };

  useEffect(() => {
    const dateParam = searchParams.get('date');
    setSelectedDate(dateParam ? parseISO(dateParam) : null);
  }, [searchParams]);

  return (
    <div className='px-4 py-2 bg-gray-100 border border-gray-300 rounded-full text-sm'>
      <ReactDatePicker
        selected={selectedDate}
        onChange={handleDateChange}
        dateFormat='yyyy-MM-dd'
        placeholderText='Select Date'
        className='w-full outline-none bg-transparent border-none'
      />
    </div>
  );
};

export default DateFilter;

import React, { useEffect, useRef, useState } from 'react';
import ArrowDown from '../../../Components/svgs/ArrowDown';
import { useSearchParams } from 'react-router-dom';

const CourseProgressStudentRange = () => {
  const [isDropDownOpen, setIsDropDownOpen] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const initialFrom = searchParams.get('course_progress_from') || '';
  const initialTo = searchParams.get('course_progress_to') || '';
  const [courseProgressFrom, setCourseProgressFrom] = useState(initialFrom);
  const [courseProgressTo, setCourseProgressTo] = useState(initialTo);

  const dropdownRef = useRef();

  const toggleDropdown = () => {
    setIsDropDownOpen(!isDropDownOpen);
  };

  const closeDropdown = (e) => {
    if (dropdownRef.current && !dropdownRef.current.contains(e.target)) {
      setIsDropDownOpen(false);
    }
  };

  const applyProgressFilter = () => {
    if (courseProgressFrom) {
      searchParams.set('course_progress_from', courseProgressFrom);
    } else {
      searchParams.delete('course_progress_from');
    }
    if (courseProgressTo) {
      searchParams.set('course_progress_to', courseProgressTo);
    } else {
      searchParams.delete('course_progress_to');
    }
    setSearchParams(searchParams);
    setIsDropDownOpen(false);
  };

  useEffect(() => {
    document.addEventListener('click', closeDropdown);
    return () => {
      document.removeEventListener('click', closeDropdown);
    };
  }, []);

  return (
    <div className='relative dropdown' ref={dropdownRef}>
      <button
        className='px-6 py-4 bg-white border border-gray-300 rounded-full text-sm font-medium flex items-center gap-4'
        onClick={toggleDropdown}
      >
        <span className='block'>Course Progress</span>
        <ArrowDown className='size-[1rem] text-[#868686]' />
      </button>
      {isDropDownOpen && (
        <div className='absolute top-12 left-0 w-48 bg-white border border-gray-200 rounded-lg shadow-md z-10 py-4 px-2'>
          <div className='flex w-full justify-between gap-2'>
            <div className='mb-2 w-1/2'>
              <label
                htmlFor='courseProgressFrom'
                className='text-sm text-[#9E9E9E] block'
              >
                From
              </label>
              <input
                id='courseProgressFrom'
                type='number'
                min={0}
                max={10}
                value={courseProgressFrom}
                onChange={(e) => setCourseProgressFrom(e.target.value)}
                className='outline-none border border-[#CCD1D2] rounded-md w-full text-center mt-1 h-[2.5rem] px-0'
              />
            </div>
            <div className='mb-2 w-1/2'>
              <label
                htmlFor='courseProgressTo'
                className='text-sm text-[#9E9E9E] block'
              >
                To
              </label>
              <input
                id='courseProgressTo'
                type='number'
                min={0}
                max={10}
                value={courseProgressTo}
                onChange={(e) => setCourseProgressTo(e.target.value)}
                className='outline-none border border-[#CCD1D2] rounded-md w-full text-center mt-1 h-[2.5rem] px-0'
              />
            </div>
          </div>
          <button
            className='mx-auto bg-primary text-white mt-3 w-[70%] py-2 rounded-xl text-sm block hover:bg-primary-light'
            onClick={applyProgressFilter}
          >
            Apply
          </button>
        </div>
      )}
    </div>
  );
};

export default CourseProgressStudentRange;

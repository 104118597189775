import { useEffect, useState } from 'react';
import FilterGroup from '../../../../Components/AdminDashboard/shared/Filters/FilterGroup';
import useFetch from '../../../../hooks/useFetch';
import DateFilter from '../../../../NewPages/AdminDashboard/components/DateFilter';
import { jobProfileRoundsEndPoint } from '../../../../Services/endPoints';

const initialFilters = [
  {
    name: 'Job Profile',
    key: 'job_profile_round',
  },
  {
    name: 'Status',
    key: 'status',
    options: [
      { name: 'True', value: 'True' },
      { name: 'False', value: 'False' },
    ],
  },
  {
    name: 'Date',
    key: 'date',
    component: <DateFilter />,
  },
];

export default function WebinarsFilter({ count }) {
  const [filters, setFilters] = useState(initialFilters);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case 'job_profile_round':
            return {
              ...filter,
              options: jobProfileRounds
                ? jobProfileRounds.map((round, index) => ({
                    name: round.code,
                    value: round.code,
                  }))
                : [],
            };
          default:
            return filter;
        }
      })
    );
  }, [jobProfileRounds]);

  return <FilterGroup filters={filters} count={count} />;
}

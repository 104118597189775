const successStatus = ["Active", "Accepted", "Approved", "Yes", "Completed"];
const rejectStatus = ["Rejected", "Inactive", "No", "Expired"];
const successStyles = "bg-[#F0FEED] text-[#259800]";
const rejectedStyles = "bg-[#FEEDED] text-[#F13E3E]";
const pendingStyles = "text-[#FFC107] bg-[#FFF5D7]";

const StatusColumn = ({ col }) => {
  if (col === "") {
    return (
      <div
        className={`inline-flex items-center gap-1 px-3 py-2 rounded-full text-base font-bold`}
      >
        &#8211;
      </div>
    );
  }
  const getStyles = (column) => {
    if (successStatus.includes(column)) return successStyles;
    else if (rejectStatus.includes(column)) return rejectedStyles;
    else return pendingStyles;
  };
  return (
    <div
      className={`inline-flex items-center gap-1 px-3 py-2 rounded-full text-sm font-semibold ${getStyles(
        col
      )}`}
    >
      &#9679;
      <span>{col}</span>
    </div>
  );
};

export default StatusColumn;

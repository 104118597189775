import { createContext, useContext, useRef, useState } from "react";
import { IoClose } from "react-icons/io5";
import { twMerge } from "tailwind-merge";

const DialogContext = createContext(undefined);

export const useDialog = () => {
  const context = useContext(DialogContext);
  if (!context) {
    throw new Error("useDialog must be used within a DialogProvider");
  }
  return context;
};

export const DialogProvider = ({
  children,
  className,
  headerClassName,
  titleClassName,
  contentClassName,
  title,
  renderOpenButton,
}) => {
  const ref = useRef(null);
  const [isOpen, setIsOpen] = useState(false);

  const openDialog = () => {
    ref?.current?.showModal();
    setIsOpen(true);
  };

  const closeDialog = () => {
    ref?.current?.close();
    setIsOpen(false);
  };

  return (
    <DialogContext.Provider value={{ openDialog, closeDialog }}>
      {renderOpenButton(openDialog)}

      <dialog
        ref={ref}
        open={isOpen}
        className={twMerge(
          `custom-dialog fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] min-w-[30rem] min-h-[10rem] border-none bg-[#fff] rounded-xl outline-none pb-4 overflow-visible md:w-[35rem] z-[999999] shadow-md`,
          className
        )}
      >
        <div
          className={twMerge(
            `w-full flex items-center px-5 rounded-t-lg ${
              title ? "bg-gray-50 border-b border-gray-100 pt-2 pb-2" : "pt-4"
            }`,
            headerClassName
          )}
        >
          {title && (
            <h2
              className={twMerge(
                "text-primary text-base font-semibold py-3",
                titleClassName
              )}
            >
              {title}
            </h2>
          )}
          <IoClose
            className="ms-auto w-[1.6rem] h-[1.6rem] text-gray-82 cursor-pointer"
            onClick={closeDialog}
          />
        </div>
        <div className={twMerge("px-5", contentClassName)}>
          {isOpen && children}
        </div>
      </dialog>
    </DialogContext.Provider>
  );
};

import React from 'react';

const JobStatusGuide = () => {
  return (
    <div>
      <div className='w-full flex flex-col gap-2 justify-start items-start rounded-2xl p-4 bg-[#f7f9fb]'>
        <h2 className='text-[#000000] font-normal text-base'>
          Job Status Guide:
        </h2>
        <div className='flex gap-2 flex-wrap'>
          <div className='size-4 bg-[#daa520]'></div>
          <h3 className='text-[#000000] font-normal text-sm'>
            Pending Review: Job submitted, awaiting instructor review.
          </h3>
        </div>
        <div className='flex gap-2 flex-wrap'>
          <div className='size-4 bg-[#4caf50]'></div>
          <h3 className='text-[#000000] font-normal text-sm'>
            Completed: Reviewed and approved by instructor.
          </h3>
        </div>
        <div className='flex gap-2 flex-wrap'>
          <div className='size-4 bg-[#ff4c4c]'></div>
          <h3 className='text-[#000000] font-normal text-sm'>
            Rejected: Reviewed by instructor, not approved.
          </h3>
        </div>
      </div>
    </div>
  );
};

export default JobStatusGuide;

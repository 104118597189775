import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useRef, useState } from "react";
import { apiGetSingleStudentWebinar } from "../../../../Services/apiGetSingleStudentWebinar";
import DonutChart from "../DonutChart";
import CollapsibleCard from "./CollapsibleCard";
import StudentFeedbackDialog from "./StudentFeedbackDialog";
import SkeletonCard from "../../../../Components/shared/SkelltonCard";
import { Skeleton } from "@mui/material";

export default function StudentDetailsWebinars({
  id,
  statistics,
  loadingChart,
}) {
  const studentFeedbackDialog = useRef(null);
  const [studentFeedbackData, setStudentFeedbackData] = useState(null);

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: ["studentWebinars", id],
    queryFn: ({ pageParam = 1 }) => apiGetSingleStudentWebinar(id, pageParam),
    getNextPageParam: (lastPage) => {
      if (!lastPage.next) {
        return undefined;
      }
      const nextURL = new URL(lastPage.next);
      const nextPage = nextURL.searchParams.get("page");
      return nextPage;
    },
  });

  const openStudentFeedbackDialog = (feedbackData) => {
    setStudentFeedbackData(feedbackData);
    studentFeedbackDialog.current?.showModal();
  };

  const closeStudentFeedbackDialog = () => {
    setStudentFeedbackData(null);
    studentFeedbackDialog.current?.close();
  };

  const { total_webinars, joined_webinars, missed_webinars } = statistics || {};
  const chartColors = ["#273C97", "#F13E3E"];

  if (isLoading || isFetching) {
    return (
      <div className="flex flex-row gap-4 lg:flex-nowrap flex-wrap">
        <div className="flex flex-row gap-4 mt-4 flex-grow basis-3/4">
          <div className="flex-grow basis-3/4">
            <SkeletonCard />
            <SkeletonCard />
            <SkeletonCard />
          </div>
        </div>
      </div>
    );
  }

  if (isError) {
    return <p>Something went wrong while loading webinars.</p>;
  }

  return (
    <div className="flex flex-row gap-4 lg:flex-nowrap flex-wrap">
      <div className="flex flex-row gap-4 mt-4 flex-grow basis-3/4">
        <div className="flex-grow basis-3/4">
          {data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.map((webinar) => (
                <CollapsibleCard
                  key={webinar.id}
                  title={webinar.name}
                  subtitle={webinar.date}
                  status={webinar.has_attendance ? "Joined" : "Missed"}
                  statusColor={
                    webinar.has_attendance ? "text-[#259800]" : "text-[#F13E3E]"
                  }
                  details={[
                    { label: "Coach ID", value: webinar.coach_id },
                    { label: "Coach Name", value: webinar.coach_name },
                    {
                      label: "Given Rate",
                      value:
                        webinar.feedback?.[0]?.rating ?? "No rating available",
                    },
                  ]}
                  buttonText="Student feedback"
                  onButtonClick={() =>
                    openStudentFeedbackDialog(webinar.feedback)
                  }
                />
              ))}
            </React.Fragment>
          ))}

          {hasNextPage && (
            <button
              onClick={() => fetchNextPage()}
              disabled={isFetchingNextPage}
              className="text-[#2D7CDE] text-base mt-2"
            >
              {isFetchingNextPage ? "Loading more..." : "Show more..."}
            </button>
          )}
        </div>
      </div>
      <div className="flex-grow basis-1/4">
        <h2 className="text-[#000000] text-xl font-medium mb-2">
          Join webinars
        </h2>
        <h3 className="text-lg text-[#273C97] font-normal mb-4">
          Total number of Webinars:
          <span className="text-lg text-[#263238] font-medium">
            {total_webinars}
          </span>
        </h3>
        {loadingChart ? (
          <Skeleton width={300} height={300} />
        ) : (
          <DonutChart
            data={[joined_webinars, missed_webinars]}
            labels={["Joined Webinars", "Missed Webinars"]}
            customOptions={{
              colors: chartColors,
            }}
          />
        )}
      </div>

      <StudentFeedbackDialog
        dialogRef={studentFeedbackDialog}
        onClose={closeStudentFeedbackDialog}
        dialogData={studentFeedbackData}
      />
    </div>
  );
}

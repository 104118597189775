import React, { useEffect, useState } from "react";
import { GoArrowLeft, GoArrowRight } from "react-icons/go";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
} from "../../../Components/ui/carousel";
import NextWebinarCard from "../../Common/NextWebinarCard/NextWebinarCard";
import AnnouncementItem from "./AnnouncementItem";

const AnnouncementCarousel = ({ slides }) => {
  const [mainApi, setMainApi] = useState();

  useEffect(() => {
    if (!mainApi) return;

    const autoplay = setInterval(() => {
      mainApi.scrollNext();
    }, 4000);

    return () => clearInterval(autoplay);
  }, [mainApi]);
  return (
    <Carousel
      setApi={setMainApi}
      className="mt-6 max-w-full relative"
      showPagination={false}
      opts={{
        loop: true,
      }}
    >
      <CarouselContent className="">
        {slides.map((slide) => (
          <CarouselItem key={`${slide.name}`}>
            {slide.name === "nextWebinar" ? (
              <NextWebinarCard webinar={slide.data} />
            ) : (
              <AnnouncementItem announcement={slide.data} />
            )}
          </CarouselItem>
        ))}
      </CarouselContent>
      <button
        className={
          "absolute left-[1%] top-[50%] flex size-[1.5rem] flex-shrink-0 -translate-y-1/2 items-center justify-center rounded-full bg-white text-colors-primary-colors-500 shadow-lg transition-colors duration-300 ease-in-out hover:bg-colors-primary-colors-500"
        }
        onClick={() => mainApi?.scrollPrev()}
      >
        <GoArrowLeft className="size-[1.2rem]" />
      </button>
      <button
        className={
          "absolute right-[1%] top-[50%] flex size-[1.5rem] flex-shrink-0 -translate-y-1/2 items-center justify-center rounded-full bg-white text-colors-primary-colors-500 shadow-lg transition-colors duration-300 ease-in-out hover:bg-colors-primary-colors-500"
        }
        onClick={() => mainApi?.scrollNext()}
      >
        <GoArrowRight className="size-[1.2rem]" />
      </button>
    </Carousel>
  );
};

export default AnnouncementCarousel;

import { useState } from 'react';
import LabeledInput from '../../../../../shared/LabeledInput';
import { useCreateJobProfileForm } from './useCreateJobProfileForm';
import ChooseFromExisting from '../ChooseFromExisting';
import CustomButton from '../../../../../shared/CustomButton';
import CreateNewCoach from '../CreateNewCoach';

const CreateJobProfileForm = () => {
  const [activeView, setActiveView] = useState('existing');
  const {
    register,
    handleSubmit,
    onSubmit,
    formState: { errors },
    isPending,
    control,
    coachEmailList,
  } = useCreateJobProfileForm(activeView);

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className='flex flex-col w-full mt-[2rem]'
    >
      <div className='flex flex-wrap justify-between gap-y-[1rem] xl:w-[60%] lg:w-[70%] '>
        <div className='max-w-[20rem]'>
          <label
            htmlFor={'job-profile-code'}
            className='text-primary text-base font-semibold mb-2 block'
          >
            Job Profile Code
          </label>
          <p className='text-[#7C8493] text-xs'>
            Important Note: Once you create and save the Job profile code, it
            cannot be changed. You can use this code to search and manage the
            Webinars in the future.
          </p>
        </div>
        <LabeledInput
          wrapperClassName='w-[20rem]'
          placeholder='e.g. YTH1_SWD_WG2'
          id={'job-profile-code'}
          fieldName='code'
          register={register}
          errors={errors}
        />
      </div>
      <h2 className='text-primary text-lg font-bold mt-[1.5rem] mb-[1rem]'>
        Add Coach:
      </h2>
      <ChooseFromExisting
        activeView={activeView}
        setActiveView={setActiveView}
        register={register}
        errors={errors}
        coachEmailList={coachEmailList}
      />
      <CreateNewCoach
        activeView={activeView}
        setActiveView={setActiveView}
        register={register}
        errors={errors}
        control={control}
      />
      <CustomButton
        className='py-3 mx-auto mt-[3rem] w-[15rem] font-semibold text-base'
        type='submit'
        isLoading={isPending}
      >
        Save
      </CustomButton>
    </form>
  );
};

export default CreateJobProfileForm;

import React from "react";
import { Link, useParams } from "react-router-dom";
import peopleImage from "../../../assets/people.png";
import student from "../../../assets/student.png";
import QAAllAnswer from "../../../Components/svgs/QAAllAnswer";
import QAanswered from "../../../Components/svgs/QAanswered";
import useFetch from "../../../hooks/useFetch";
import { dashboardSingleStudentStatisticsEndPoint } from "../../../Services/endPoints";
import CardsInfo from "../components/CardsInfo";
import Header from "../components/Header";
import StudentInfo from "../components/StudentDetailsPage/StudentInfo";
import StudentDetailsDelivers from "../components/StudentDetailsPage/StudentDetailsDelivers";
import StudentDetailsCharts from "../components/StudentDetailsPage/StudentDetailsCharts";
import BackBtn from "../../../Components/svgs/BackBtn";

export default function StudentDetails() {
  const { id } = useParams();
  const { data: singleStudentStatistics } = useFetch(
    `${dashboardSingleStudentStatisticsEndPoint}${id}/`
  );

  const studentCards = [
    {
      img: <img src={peopleImage} alt="people-image" />,
      title: "Joined Webinars ",
      value: singleStudentStatistics?.joined_webinars,
    },
    {
      img: <QAAllAnswer />,
      title: "Asked Questions",
      value: singleStudentStatistics?.asked_questions,
    },
    {
      img: <QAanswered />,
      title: "Delivers",
      value: `${singleStudentStatistics?.done_deliverables} / 6`,
    },
    {
      img: <img src={student} alt="student" />,
      title: "Graduated Students",
      value: (
        <span
          className={`font-bold ${
            singleStudentStatistics?.completed_freelance
              ? "text-[#259800]"
              : "text-red-600"
          }`}
        >
          {singleStudentStatistics?.completed_freelance ? "Done" : "False"}
        </span>
      ),
    },
  ];

  return (
    <>
      <div className="flex justify-between gap-4 items-center">
        <Link
          to="/students"
          className="flex gap-3 items-center btn-primary px-4 py-2 text-xl font-semibold"
        >
          <BackBtn />
          Back
        </Link>
      </div>
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>
      <div className="grid grid-cols-1 lg:grid-cols-4 gap-6">
        {/* Delivers above Info on small screens, on the right on large screens */}
        <div className="order-1 lg:order-2 lg:col-span-1">
          <StudentDetailsDelivers id={id} />
        </div>

        {/* Student Info below Delivers on small screens, on the left (3/4) on large screens */}
        <div className="order-2 lg:order-1 lg:col-span-3">
          <StudentInfo />
          <StudentDetailsCharts />
        </div>
      </div>
    </>
  );
}

import { useSearchParams } from "react-router-dom";
import {
  CoachingRoundEndPoint,
  dashboardGigsStatusEndpoint,
} from "../../../../Services/endPoints";
import useFetch from "../../../../hooks/useFetch";

function GigsProposalsFilter() {
  const [searchParams, setSearchParams] = useSearchParams();

  const { data: statusData } = useFetch(dashboardGigsStatusEndpoint);

  const statusOptions = Array.isArray(statusData)
    ? statusData.map((status) => ({
        label: status,
        value: status,
      }))
    : [];

  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const coachingRounds = Array.isArray(coachingRoundsData)
    ? coachingRoundsData.map((round) => ({
        label: round.code,
        value: round.code,
      }))
    : [];

  const setFilterValue = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.set(key, value);
    setSearchParams(updatedParams);
  };

  const removeFilter = (key) => {
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete(key);
    setSearchParams(updatedParams);
  };

  const getParamValue = (key) => searchParams.get(key);

  const clearAll = () => {
    const updatedParams = new URLSearchParams();
    setSearchParams(updatedParams);
  };

  return (
    <div className="space-y-4 divide-y-2">
      <div className="flex items-center gap-4">
        <SelectFilter
          options={coachingRounds}
          placeHolder={"Coaching Rounds"}
          onSelectValue={(value) => setFilterValue("coaching_round", value)}
        />
        <SelectFilter
          options={statusOptions}
          placeHolder={"Status"}
          onSelectValue={(value) => setFilterValue("status", value)}
        />
      </div>

      <div className="flex items-center justify-between flex-wrap pt-4">
        <div className="flex items-center gap-4">
          <FilterValue
            name={"Coaching Rounds"}
            value={getParamValue("coaching_round")}
            onRemove={() => removeFilter("coaching_round")}
          />
          <FilterValue
            name={"Status"}
            value={getParamValue("status")}
            onRemove={() => removeFilter("status")}
          />
        </div>

        {(getParamValue("coaching_round") || getParamValue("status")) && (
          <button
            onClick={clearAll}
            className="bg-[#EFEFEF] text-black px-4 py-2 rounded-xl"
          >
            Clear All
          </button>
        )}
      </div>
    </div>
  );
}

export default GigsProposalsFilter;

const SelectFilter = ({ placeHolder, options, onSelectValue }) => {
  return (
    <div className="border px-2 bg-white max-w-[198px] border-gray-300 text-gray-900 text-sm rounded-xl">
      <select
        onChange={(e) => onSelectValue(e.target.value)}
        className="bg-transparent w-full h-full py-2 focus:outline-none"
      >
        <option value="" selected>
          {placeHolder}
        </option>
        {options.map((option) => (
          <option key={option.value} value={option.value}>
            {option.label}
          </option>
        ))}
      </select>
    </div>
  );
};

const FilterValue = ({ name, value, onRemove }) => {
  if (!name || !value) return null;
  return (
    <span className="border text-gray-700 px-3 py-1 rounded-full flex items-center gap-2">
      {value ? value : name}
      <button
        onClick={() => onRemove(name)}
        className="text-gray-500 hover:text-gray-800"
      >
        &times;
      </button>
    </span>
  );
};

import { Dialog, DialogContent } from "@mui/material";
import { Link } from "react-router-dom";
import AddRateForm from "./AddRateForm";

function ModalGigDetails({ open, onClose, data }) {
  return (
    <>
      <Dialog open={open} onClose={onClose}>
        <DialogContent className="!p-0">
          <div className="mb-4 grid grid-cols-1 gap-y-4 md:grid-cols-2">
            <div className="space-y-4 border-r-2 px-6 pt-4">
              <h4 className="font-semibold">Student Details</h4>

              <div className="space-y-3 overflow-hidden rounded-xl border px-2 py-3">
                <span className="grid grid-cols-2 gap-2">
                  <strong className="font-medium">Name</strong>
                  <p className="max-w-full truncate" title={data?.student_name}>
                    {data?.student_name}
                  </p>
                </span>

                <span className="grid grid-cols-2 gap-2">
                  <strong className="font-medium">Email</strong>
                  <p
                    className="max-w-full truncate"
                    title={data?.student_email}
                  >
                    {data?.student_email}
                  </p>
                </span>
              </div>

              <h4 className="text-text-primary">Gig Links</h4>

              <div className="space-y-3 overflow-hidden rounded-xl border px-2 py-3">
                <span className="flex flex-col gap-1">
                  <strong className="font-medium">URL</strong>
                  <Link
                    target="_blank"
                    to={data?.url || ""}
                    className="truncate text-[#2D7CDE]"
                  >
                    {data?.url}
                  </Link>
                </span>

                <span className="flex flex-col gap-1">
                  <strong className="font-medium">Proposal</strong>
                  <Link
                    target="_blank"
                    to={data?.proposal_file || ""}
                    className="truncate text-[#2D7CDE]"
                  >
                    {data?.proposal_file}
                  </Link>
                </span>
              </div>
            </div>

            <div className="space-y-4 px-6 pt-4">
              <h4 className="font-semibold">Gig Details</h4>

              <div className="space-y-3 overflow-hidden rounded-xl border px-2 py-3">
                <span className="grid grid-cols-2 gap-2">
                  <strong className="font-medium">Gig Title</strong>
                  <p className="max-w-full truncate" title={data?.title}>
                    {data?.title}
                  </p>
                </span>

                <span className="grid grid-cols-2 gap-2">
                  <strong className="font-medium">Date</strong>
                  <p className="max-w-full truncate">
                    {String(data?.created_on).split("T")[0]}
                  </p>
                </span>

                <span className="grid grid-cols-2 gap-2">
                  <strong className="font-medium">price</strong>
                  <p className="max-w-full truncate" title={data?.price}>
                    {data?.price}
                  </p>
                </span>

                {data?.platform && (
                  <span className="grid grid-cols-2 gap-2">
                    <strong className="font-medium">Platform</strong>
                    <p className="max-w-full truncate" title={data.platform}>
                      {data.platform}
                    </p>
                  </span>
                )}

                {data?.description && (
                  <span className="flex flex-col gap-1">
                    <strong className="font-medium">Description </strong>
                    <p
                      className="max-w-full truncate"
                      title={data?.description}
                    >
                      {data?.description}
                    </p>
                  </span>
                )}
              </div>
            </div>
          </div>

          <AddRateForm onClose={onClose} id={data?.id} />
        </DialogContent>
      </Dialog>
    </>
  );
}

export default ModalGigDetails;

import React from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import userPlaceholderImage from '../../assets/defultUserImage.svg';
import { pageRoutes } from '../../constants';
import { useGetStudentProfile } from '../../hooks/useGetStudentProfile';
import MobileNavBar from '../Common/MobileNavBar/MobileNavBar';
import NavSideBar from '../Common/NavSideBar';
import ProfileCard from '../Common/Profile/ProfileCard';
import ProfileMobileMenu from '../Common/Profile/ProfileMobileMenu';
import FreelanceAccounts from './StudentProfile/FreelanceAccounts/FreelanceAccounts';
import StudentInfo from './StudentProfile/StudentInfo';

const StudentLayout = () => {
  const location = useLocation();
  const { userProfileData, userSystemInfo } = useGetStudentProfile();

  return (
    <>
      <div className='tablet:bg-[#f8f8f8] bg-white gap-x-7 flex tablet:flex-nowrap flex-wrap'>
        <NavSideBar />
        <MobileNavBar>
          <ProfileMobileMenu
            placeholderImage={userPlaceholderImage}
            userImage={userProfileData?.profile_image}
          >
            <StudentInfo
              userProfileData={userProfileData}
              userSystemInfo={userSystemInfo}
            />
          </ProfileMobileMenu>
        </MobileNavBar>
        <div className='tablet:hidden flex flex-wrap items-center gap-3 mt-[6rem] px-[5%] relative w-full'>
          <FreelanceAccounts />
        </div>
        <div
          className={`relative z-[98] tablet:border border-gray-100 tablet:px-[1rem] px-[5%] py-[1.5rem] tablet:shadow-md tablet:mt-[2rem] mt-[2rem] bg-white tablet:rounded-xl tablet:h-fit h-full min-h-[90vh] flex-1 overflow-hidden `}
        >
          <Outlet />
        </div>
        {!location.pathname.includes(pageRoutes.course) && (
          <ProfileCard>
            <StudentInfo
              userProfileData={userProfileData}
              userSystemInfo={userSystemInfo}
            />
            <FreelanceAccounts />
          </ProfileCard>
        )}
      </div>
    </>
  );
};

export default StudentLayout;

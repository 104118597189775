import { useQuery } from "@tanstack/react-query";
import React from "react";
import { useSearchParams } from "react-router-dom";
import ProfileRoundFilter from "../../../NewPages/AdminDashboard/components/ProfileRoundFilter";
import { apiGetDashboardStats } from "../../../Services/AdminDashboard/apiGetDashboardStats";
import Loading from "../../shared/Loading";
import AIInterviewStats from "./AIInterviewStats";
import GigsStats from "./GigsPage/GigsStats";
import ProposalsStats from "./ProposalsStats";
import QAStats from "./QAStats";
import RevenueStats from "./RevenueStats";
import ReviewsStats from "./ReviewsStats";
import StudentProgress from "./StudentProgress";
import WebinarChart from "./WebinarsChart";

const AdminDashboardPage = () => {
  const [searchParams] = useSearchParams();
  const jobProfileRounds = searchParams.get("job_profile_round") || "";
  const { data, isLoading } = useQuery({
    queryKey: ["dashboardStats", jobProfileRounds],
    queryFn: () =>
      apiGetDashboardStats("job_profile_round=" + jobProfileRounds),
  });
  if (isLoading) {
    <Loading />;
  }
  return (
    <>
      <div className="w-full flex items-center">
        <ProfileRoundFilter />
        {/* <AdminUserInfo className='ms-auto tablet:block hidden' /> */}
      </div>
      <StudentProgress data={data} />
      <div className="w-full flex flex-wrap items-stretch gap-[2rem] mt-[2rem]">
        <WebinarChart webinars={data?.webinars} />
        <QAStats data={data} />
        <AIInterviewStats data={data} />
        <ReviewsStats data={data} />
        <ProposalsStats data={data} />
        <RevenueStats data={data} />
        <GigsStats data={data} />
      </div>
    </>
  );
};

export default AdminDashboardPage;

import { FaChevronRight } from "react-icons/fa6";
import { Link } from "react-router-dom";
import { pageRoutes } from "../../../constants";
import SettingsIcon from "../../svgs/SettingsIcon";
import { useWindowSize } from "../../../hooks/useWindowSize";
const routes = [
  {
    name: "Create New Group",
    href: pageRoutes.controlPanelCreateNewGroup,
  },
  {
    name: "Webinars",
    href: pageRoutes.controlPanelWebinars,
  },
  {
    name: "Gigs",
    href: pageRoutes.controlPanelGigs,
  },
  {
    name: "Announcements",
    href: pageRoutes.controlPanelAnnouncements,
  },
];
const ControlPanelTab = ({ openSideBar, isSideBarOpen, closeDrawer }) => {
  const { width } = useWindowSize();
  return (
    <div className={`w-full list-none`} onClick={openSideBar}>
      <div
        className={`hover:bg-blue-600 hover:bg-opacity-25 flex gap-2 items-center h-10 px-3 rounded-lg transition-colors duration-150 ease-in-out cursor-pointer group/menu relative ${
          !isSideBarOpen ? "overflow-hidden" : ""
        } ${width <= 992 ? "overflow-visible" : ""}`}
      >
        <SettingsIcon className="w-[1.5rem] h-[1.5rem] flex-shrink-0 text-[#202020]" />
        <span
          className={`duration-300 ease-in-out ${
            isSideBarOpen || width <= 992 ? "opacity-100" : "opacity-0"
          }`}
        >
          Control Panel
        </span>
        <div className="hidden absolute tablet:top-[50%] top-[100%] tablet:-translate-y-1/2 tablet:right-[-100%] right-0 flex-col gap-2 w-full group-hover/menu:flex bg-white shadow-lg z-[1000] py-3 rounded-md px-2">
          <div className="cursor-pointer px-3 py-2 w-full flex items-center justify-between hover:bg-blue-600 hover:bg-opacity-25 rounded relative group">
            <span className="block">Users</span>
            <FaChevronRight className="size-[0.9rem] text-inherit" />

            <ul className="shadow-md bg-white rounded-lg px-3 py-4 group-hover:flex hidden absolute top-[0] tablet:right-[-70%] right-[-60%] flex-col z-[100] w-[10rem]">
              <li className="w-full list-none" onClick={closeDrawer}>
                <Link
                  className="hover:bg-blue-600 hover:bg-opacity-25 rounded px-4 py-2 w-full block"
                  to={`/${pageRoutes.controlPanelStudents}`}
                >
                  Students
                </Link>
              </li>
              <li className="w-full list-none" onClick={closeDrawer}>
                <Link
                  className="hover:bg-blue-600 hover:bg-opacity-25 rounded px-4 py-2 w-full block"
                  to={`/${pageRoutes.controlPanelInstructors}`}
                >
                  Instructors
                </Link>
              </li>
            </ul>
          </div>
          {routes.map((route, index) => (
            <li className="w-full list-none" onClick={closeDrawer} key={index}>
              <Link
                className="hover:bg-blue-600 w-full block text-nowrap hover:bg-opacity-25 rounded px-4 py-2"
                to={`/${route.href}`}
              >
                {route.name}
              </Link>
            </li>
          ))}
        </div>
      </div>
    </div>
  );
};

export default ControlPanelTab;

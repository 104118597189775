const MicIcon = ({ className }) => {
  return (
    <svg
      className={className}
      width="18"
      height="19"
      viewBox="0 0 18 19"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M3.11504 10.2783C2.75306 9.9163 2.55371 9.43506 2.55371 8.92308C2.55371 8.41121 2.75306 7.92997 3.11504 7.568C3.47703 7.20604 3.95829 7.00669 4.47018 7.00669H4.58131V10.8396H4.47018C3.95829 10.8396 3.47701 10.6402 3.11504 10.2783ZM7.1762 14.7264C7.11684 14.7928 7.03513 14.8293 6.94612 14.8293H6.3121C6.17422 14.8293 6.04049 14.78 5.93555 14.6906C5.83061 14.6011 5.76082 14.4769 5.73902 14.3407L5.28357 11.4999H6.92232L7.25309 14.4865C7.26287 14.5749 7.23557 14.6601 7.1762 14.7264ZM7.13214 11.2843H5.10363C4.93446 11.2843 4.79685 11.1467 4.79685 10.9775V10.9475C4.79685 10.9474 4.79685 10.9474 4.79685 10.9473C4.79685 10.9473 4.79685 10.9472 4.79685 10.9472V6.89903C4.79685 6.89899 4.79685 6.89894 4.79685 6.89888C4.79685 6.89884 4.79685 6.8988 4.79685 6.89873V6.86868C4.79685 6.69954 4.93446 6.5619 5.10363 6.5619H7.13214C7.30131 6.5619 7.43892 6.69951 7.43892 6.86868V10.9775C7.43892 11.1467 7.30129 11.2843 7.13214 11.2843ZM12.3525 12.7107C11.9289 12.2886 11.3839 11.9269 10.7327 11.6359C9.86439 11.2478 8.82918 10.9988 7.65446 10.8951V6.95113C8.57726 6.86967 9.41828 6.69763 10.1556 6.43932C10.7699 6.22411 11.3145 5.94872 11.7741 5.62081C12.2031 5.31483 12.4788 5.0193 12.6434 4.80894V13.037C12.5694 12.9424 12.4738 12.8318 12.3525 12.7107ZM13.5391 13.3912C13.5391 13.5787 13.3865 13.7313 13.199 13.7313C13.0114 13.7313 12.8589 13.5787 12.8589 13.3912V4.45511C12.8589 4.26758 13.0114 4.11499 13.199 4.11499C13.3865 4.11499 13.5391 4.26756 13.5391 4.45509V13.3912ZM14.5076 9.21635C14.4987 9.25936 14.4686 9.29422 14.4272 9.30958L13.7546 9.55909V8.28724L14.4272 8.53675C14.4686 8.55215 14.4987 8.58701 14.5076 8.6299C14.522 8.69958 14.5392 8.80704 14.5392 8.92317C14.5392 9.03939 14.522 9.14679 14.5076 9.21635Z"
        fill="currentcolor"
      />
      <path
        d="M9 0.890167C10.1285 0.890167 11.2229 1.11104 12.2528 1.54666C13.2479 1.96755 14.1417 2.57022 14.9094 3.33792C15.6771 4.10563 16.2798 4.99944 16.7007 5.99452C17.1363 7.02442 17.3571 8.11882 17.3571 9.24731C17.3571 10.3758 17.1363 11.4702 16.7007 12.5001C16.2798 13.4952 15.6771 14.389 14.9094 15.1567C14.1417 15.9244 13.2479 16.5271 12.2528 16.948C11.2229 17.3836 10.1285 17.6045 9 17.6045C7.8715 17.6045 6.77713 17.3836 5.74723 16.948C4.75215 16.5271 3.85833 15.9244 3.09063 15.1567C2.32292 14.389 1.72025 13.4952 1.29937 12.5001C0.863768 11.4702 0.642874 10.3758 0.642874 9.24731C0.642874 8.11882 0.863747 7.02444 1.29937 5.99452C1.72025 4.99944 2.32292 4.10563 3.09063 3.33792C3.85833 2.57022 4.75215 1.96755 5.74723 1.54666C6.77711 1.11104 7.8715 0.890167 9 0.890167ZM9 0.247314C4.02943 0.247314 0 4.27675 0 9.24731C0 14.2179 4.02943 18.2473 9 18.2473C13.9706 18.2473 18 14.2179 18 9.24729C18 4.27673 13.9705 0.247314 9 0.247314Z"
        fill="currentcolor"
      />
    </svg>
  );
};

export default MicIcon;

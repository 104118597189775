import React from "react";
import StarRatings from "react-star-ratings";

export default function StudentFeedbackDialog({
  dialogRef,
  onClose,
  dialogData,
}) {
  // Safely extract the rating and feedback
  const rating = dialogData?.[0]?.rating || 0;
  const feedback = dialogData?.[0]?.feedback || "No feedback provided";

  return (
    <dialog
      ref={dialogRef}
      className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-white rounded-xl outline-none p-5 overflow-visible shadow-lg"
    >
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg font-bold text-[#202020]">
          Freelance Platforms
        </h2>
        <button
          onClick={onClose}
          className="text-[#71747B] hover:text-gray-900"
        >
          ✖
        </button>
      </div>
      <div className="mt-4">
        <h2>Student Rating:</h2>
        <StarRatings
          rating={rating}
          starRatedColor="orange"
          numberOfStars={5}
          name="rating"
          starDimension="1.9rem"
          starSpacing="5px"
        />

        <h2>Student Comment:</h2>
        <p className="text-[#71747B]" dir="auto">
          {feedback}
        </p>
      </div>
    </dialog>
  );
}

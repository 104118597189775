import CustomButton from "../../../Components/shared/CustomButton";
import { DialogProvider } from "../../../Components/shared/CustomDialog";
import QAIcon from "../../../Components/svgs/QAIcon";
import AskQuestionForm from "./AskQuestionForm";
const AskQuestionDialog = ({ categoryData }) => {
  return (
    <DialogProvider
      title="Ask your question"
      className="w-[90%] pb-[1.5rem] sm:w-[35rem] min-w-[20rem] flex-col"
      titleClassName="text-2xl font-bold text-[#3E3E3E] pb-2"
      contentClassName="flex-1 flex flex-col"
      renderOpenButton={(openDialog) => (
        <CustomButton
          className="w-fit px-6 py-5 flex items-center justify-center gap-2 whitespace-nowrap rounded-md "
          onClick={openDialog}
        >
          <QAIcon className="size-[1.6rem]" />
          Ask Question
        </CustomButton>
      )}
    >
      <AskQuestionForm categoryData={categoryData} />
    </DialogProvider>
  );
};

export default AskQuestionDialog;

import CalenderIcon from "../../../../Components/svgs/CalenderIcon";
import Person from "../../../../Components/svgs/Person";
import BuildinTwoIcon from "../../../../Components/svgs/BuildingTwoIcon";
import NetworkingIcon from "../../../../Components/svgs/NetworkingIcon";

function CardGigsProposals({ gig, onShowDetails }) {
  return (
    <div className="basis-[377px] border rounded-lg px-4 py-2 grow">
      <div className="flex justify-end">
        <span className="border-2 px-2 py-1 font-medium border-primary rounded-lg text-primary">
          {gig?.status}
        </span>
      </div>

      <div className="space-y-3">
        <span className="flex items-center gap-1">
          <Person />
          <span>Student:</span>
          <span className="text-[#828282] truncate">{gig?.student_name}</span>
        </span>

        <span className="flex items-center gap-1">
          <CalenderIcon />
          <span className="text-[#828282] truncate">
            {new Date(gig?.created_on).toLocaleDateString()}
          </span>
        </span>

        <span className="flex items-center gap-1">
          <NetworkingIcon />
          <span>Couching Round:</span>
          <span className="text-[#828282] truncate">
            {gig?.student_coaching_round}
          </span>
        </span>

        <span className="flex items-center gap-1">
          <BuildinTwoIcon />
          <span className="text-[#828282] truncate">{gig?.category}</span>
        </span>
      </div>

      <button
        onClick={() => onShowDetails(gig)}
        className="bg-primary text-white py-2 px-4 mt-4 w-full rounded-lg font-semibold"
      >
        View more details
      </button>
    </div>
  );
}

export default CardGigsProposals;

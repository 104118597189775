import axios from "axios";
import { announcementsEndpoint } from "../endPoints";

export const apiGetAllAnnouncements = async (page, params = "") => {
  const response = await axios.get(
    `${announcementsEndpoint}?page=${page}&page_size=2${
      params ? `&${params}` : ""
    }`
  );
  return response;
};

export const apiPostAnnouncement = async (data) => {
  const response = await axios.post(`${announcementsEndpoint}`, data);
  return response;
};
export const apiPatchAnnouncement = async (id, data) => {
  const response = await axios.patch(`${announcementsEndpoint}${id}/`, data);
  return response;
};

export const apiDeleteAnnouncement = async (id) => {
  const response = await axios.delete(`${announcementsEndpoint}${id}/`);
  return response;
};

import groupImage from "../../../assets/Group2.png";
const stats = [
  {
    title: "Submit 10 proposals",
    name: "submit_ten_gigs_percntage",
  },
  {
    title: "Landed 1 Gig",
    name: "landed_one_gig_percntage",
  },
  {
    title: "Complete 1 Gig",
    name: "complete_one_gig_percntage",
  },
  {
    title: "Deliver all Tasks",
    name: "deliver_all_tasks_percntage",
  },
  {
    title: "Course Progress",
    name: "complete_course",
  },
];
const StudentProgress = ({ data }) => {
  return (
    <section className="px-[2.5rem] pt-[1rem] pb-[3rem] rounded-[2rem] bg-white drop-shadow-[#E2ECF980] gap-[1.5rem] w-full">
      <h2 className="text-xl font-semibold text-center mb-4">
        Student Progress
      </h2>
      <div className="w-full flex justify-evenly sm:flex-nowrap flex-wrap gap-x-[1rem] gap-y-[1.5rem]">
        {stats.map((stat, index) => (
          <>
            <div key={index} className="flex items-center gap-2 w-[12rem]">
              <img
                src={groupImage}
                alt="Group"
                className="w-[3.5rem] aspect-square"
              />
              <div className="flex flex-col gap-1">
                <span className="text-[#ACACAC] text-xs">{stat.title}</span>
                <h2 className="text-[#333333] text-[1.5rem] font-semibold ms-1">
                  {data?.[stat.name]?.num}
                </h2>
                <h3 className="text-[#259800] text-sm font-medium ms-1">
                  {data?.[stat.name]?.prec} %
                </h3>
                <span className="text-[#259800] text-xs font-bold ms-1">
                  {stat.subtitle}
                </span>
              </div>
            </div>
            {index < stats.length - 1 && (
              <div className="w-[1px] bg-[#F0F0F0] tablet:block hidden"></div>
            )}
          </>
        ))}
      </div>
    </section>
  );
};

export default StudentProgress;

import React from 'react';
import LabeledInput from '../../../../Components/shared/LabeledInput';
import useAddNewGigsForm from './useAddNewGigsForm';
import { useGetSelectionData } from './useGetSelectionData';
import SelectInput from '../../../../Components/shared/SelectInput';
import CustomButton from '../../../../Components/shared/CustomButton';

const AddNewGigForm = ({ closeDialogHandle }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    onSubmit,
    isPending,
  } = useAddNewGigsForm(closeDialogHandle);

  const { selectionOptions } = useGetSelectionData();

  return (
    <div>
      <form onSubmit={handleSubmit(onSubmit)} id='add-new-gig-form'>
        <LabeledInput
          id={'title'}
          register={register}
          placeholder={'Job Title'}
          fieldName='title'
          errors={errors}
          className={'mb-4'}
          label={'Job Title'}
        />

        <LabeledInput
          id={'url'}
          register={register}
          placeholder={'Job URL'}
          fieldName='url'
          errors={errors}
          label={'Job URL'}
          className={'mb-4'}
        />

        <SelectInput
          items={selectionOptions.category}
          defaultValue={''}
          label={'Category'}
          name='category'
          register={register}
          placeholderText='Category'
          errors={errors}
          className={'mb-4'}
        />

        <LabeledInput
          id={'price'}
          register={register}
          placeholder={'Price (USD)'}
          fieldName='price'
          errors={errors}
          label={'Job Price'}
          className={'mb-4'}
        />

        <SelectInput
          items={selectionOptions.platform ?? []}
          defaultValue={''}
          label={'Platform'}
          name='platform'
          register={register}
          placeholderText='Platforms'
          errors={errors}
          className={'mb-4'}
        />

        <div className='mb-4'>
          <label
            htmlFor='proposal_file'
            className='text-[#3F3F3F] text-base font-medium mb-2 block'
          >
            Upload Proposal File
          </label>
          <input
            id='proposal_file'
            type='file'
            accept='application/pdf'
            {...register('proposal_file')}
            className='file-input border border-gray-300 w-full py-2 px-3 rounded focus:outline-none focus:border-blue-400'
          />
          {errors.proposal_file && (
            <p className='text-red-500 text-sm mt-1'>
              {errors.proposal_file?.message}
            </p>
          )}
        </div>
      </form>
      <div className='flex justify-end mt-6 gap-4'>
        <CustomButton
          form='add-new-gig-form'
          type='submit'
          className='font-bold px-4 text-sm block py-4 mt-5'
          isLoading={isPending}
        >
          Add Job
        </CustomButton>
        <CustomButton
          onClick={() => closeDialogHandle()}
          className='font-bold px-4 text-sm block py-4 mt-5 bg-[#FFFFFF] border border-solid border-[#EE201C] text-[#EE201C] hover:bg-red-100'
        >
          Cancel
        </CustomButton>
      </div>
    </div>
  );
};

export default AddNewGigForm;

import React, { useRef, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import {
  dashboardSingleStudentDeliverableFeedbackEndPoint,
  dashboardSingleStudentDeliverablesEndPoint,
  dashboardSingleStudentFreelanceProfilesEndPoint,
} from "../../../../Services/endPoints";
import FreelancePlatformsDialog from "./FreelancePlatformsDialog";
import DeliverableFeedbackDialog from "./DeliverableFeedbackDialog";
import axios from "axios";

export default function StudentDetailsDelivers({ id }) {
  const freelanceDialogRef = useRef(null);
  const feedbackDialogRef = useRef(null);

  const [freelanceDialogData, setFreelanceDialogData] = useState(null);
  const [feedbackDialogData, setFeedbackDialogData] = useState(null);

  const { data: studentDeliversData } = useFetch(
    `${dashboardSingleStudentDeliverablesEndPoint}${id}/`
  );

  const { data: studentFreelanceProfilesData } = useFetch(
    `${dashboardSingleStudentFreelanceProfilesEndPoint}${id}/`
  );

  const getWeekColor = (completed) => {
    if (completed === true) return "text-[#259800] bg-[#F0FEED]";
    if (completed === null) return "text-[#F13E3E] bg-[#FEEDED]";
    if (!completed) return "text-[#FFC107] bg-[#FFF5D7]";
  };

  const openFreelanceDialog = () => {
    setFreelanceDialogData(studentFreelanceProfilesData);
    freelanceDialogRef.current?.showModal();
  };
  const closeFreelanceDialog = () => {
    setFreelanceDialogData(null);
    freelanceDialogRef.current?.close();
  };

  const openFeedbackDialog = async (deliverableId) => {
    try {
      const url = `${dashboardSingleStudentDeliverableFeedbackEndPoint}${id}/${deliverableId}/`;
      const { data } = await axios.get(url);

      setFeedbackDialogData(data);
      feedbackDialogRef.current?.showModal();
    } catch (error) {
      console.error("Failed to fetch deliverable feedback:", error);

      setFeedbackDialogData([]);
      feedbackDialogRef.current?.showModal();
    }
  };

  const closeFeedbackDialog = () => {
    setFeedbackDialogData(null);
    feedbackDialogRef.current?.close();
  };

  return (
    <div>
      <h1 className="font-medium text-lg text-[#273C97] mb-4">Delivers</h1>
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-1 gap-4">
        {studentDeliversData?.map((deliverable, index) => {
          return (
            <div
              key={deliverable.id}
              className="bg-white rounded-xl shadow-lg p-6 flex flex-col items-start"
            >
              {/* Week with Conditional Styling */}
              <span
                className={`text-sm font-medium p-2 rounded-md self-end ${getWeekColor(
                  deliverable.completed
                )}`}
              >
                {deliverable.week}
              </span>

              {/* Task Name */}
              <h2 className="text-base font-semibold text-gray-800 mb-4 mt-3 text-center">
                {deliverable.task_name}
              </h2>

              {index === 0 && (
                <button
                  onClick={openFreelanceDialog}
                  className="btn-primary px-4 py-2 rounded-lg m-auto"
                >
                  View
                </button>
              )}

              {(index === 1 || index === 2) && (
                <button
                  onClick={() => openFeedbackDialog(deliverable.id)}
                  className="btn-primary px-4 py-2 rounded-lg m-auto"
                >
                  View
                </button>
              )}
            </div>
          );
        })}
      </div>

      {/* Dialog for Q1 */}
      <FreelancePlatformsDialog
        dialogRef={freelanceDialogRef}
        dialogData={freelanceDialogData}
        onClose={closeFreelanceDialog}
      />

      {/* Dialog for Q2, Q3 */}
      <DeliverableFeedbackDialog
        dialogRef={feedbackDialogRef}
        feedbackData={feedbackDialogData}
        onClose={closeFeedbackDialog}
      />
    </div>
  );
}

import { DialogProvider } from "../../Components/shared/CustomDialog";
import MutateAnnouncementForm from "./MutateAnnouncementForm";

const MutateAnnouncementsDialog = (props) => {
  return (
    <DialogProvider
      title={props.dialogTitle}
      className="w-[90%] pb-[1rem] sm:w-[35rem] min-w-[20rem] flex-col"
      titleClassName="text-2xl font-bold text-[#3E3E3E] pb-2"
      contentClassName="flex-1 flex flex-col"
      renderOpenButton={props.renderOpenButton}
    >
      <MutateAnnouncementForm announcement={props.announcement} {...props} />
    </DialogProvider>
  );
};

export default MutateAnnouncementsDialog;

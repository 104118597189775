import React from 'react';
import { CiFilter } from 'react-icons/ci';
import { useNavigate, useSearchParams } from 'react-router-dom';
import group from '../../../assets/Group2.png';
import student from '../../../assets/student.png';
import ExportStudentsSuccessDialog from '../../../Components/AdminDashboard/StudentPage/ExportStudents/ExportStudentsSuccessDialog';
import StudentFilters from '../../../Components/AdminDashboard/StudentPage/StudentFilters';
import CustomButton from '../../../Components/shared/CustomButton';
import { pageRoutes } from '../../../constants';
import useFetch from '../../../hooks/useFetch';
import usePaginationNumbers from '../../../hooks/usePaginationNumbers';
import {
  dashboardStudentsEndPoint,
  dashboardStudentsStatisticsEndPoint,
} from '../../../Services/endPoints';
import SearchInput from '../Common/SearchInput/SearchInput';
import Table from '../Common/Table/Table';
import TableSkeleton from '../Common/Table/TableSkeleton';
import CardsInfo from '../components/CardsInfo';
import Header from '../components/Header';

function Students() {
  const [searchParams, setSearchParams] = useSearchParams();
  const navigate = useNavigate();

  const dynamicStudentsEndpoint = `${dashboardStudentsEndPoint}?${searchParams.toString()}`;

  const { data: count } = useFetch(dynamicStudentsEndpoint);

  const {
    data: dashboardStudentsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicStudentsEndpoint);

  const results = dashboardStudentsData || [];

  const handleRowClick = (row) => {
    navigate(`/${pageRoutes.students}/${pageRoutes.studentDetails}/${row.id}`);
  };

  const { data: dashboardStudentStatisticsData } = useFetch(
    dashboardStudentsStatisticsEndPoint
  );

  const isFilteringGraduates = searchParams.has('completed_one_gig');

  let graduateStudentsIndicator = null;
  if (isFilteringGraduates) {
    graduateStudentsIndicator = (
      <h2 className='bg-[#49991c] text-base rounded-full text-center text-white px-4 py-2 flex items-center gap-2'>
        🎓 Graduate Students
      </h2>
    );
  }

  const handleShowStudentsWithCompletedGig = () => {
    const updatedSearchParams = new URLSearchParams(searchParams);
    updatedSearchParams.set('completed_one_gig', 'true');
    setSearchParams(updatedSearchParams);
  };

  // Cards Data
  const studentCards = [
    {
      img: <img src={group} alt='group' />,
      title: 'Students',
      value: dashboardStudentStatisticsData?.total_students,
      active: dashboardStudentStatisticsData?.joined_students,
    },
    {
      img: <img src={student} alt='student' />,
      title: 'Completed course',
      value: dashboardStudentStatisticsData?.complete_course,
    },
    {
      img: <img src={student} alt='student' />,
      title: 'Graduated Students',
      value: (
        <div className='flex gap-2 flex-col justify-items-start'>
          <>{dashboardStudentStatisticsData?.completed_gigs}</>
          <CustomButton
            className='w-fit p-2 text-sm rounded-2xl flex items-center gap-2'
            onClick={handleShowStudentsWithCompletedGig}
          >
            <CiFilter className='text-white' />
            Show Students
          </CustomButton>
        </div>
      ),
    },
  ];

  // Table Columns
  const tableColumns = [
    { header: 'Student ID', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
    { header: 'Email', accessor: 'email' },
    { header: 'Phone', accessor: 'phone' },
    { header: 'City', accessor: 'city' },
    { header: 'Job Profiles', accessor: 'jobProfiles' },
    { header: 'Coaching Rounds', accessor: 'coachingRounds' },
    {
      header: 'Course Progress',
      accessor: 'course-progress',
    },
    { header: 'Status', accessor: 'status' },
  ];

  const tableData = results.map((student) => ({
    id: student.id,
    name: student.full_name,
    email: student.user.email,
    phone: student.phone,
    city: student.city ?? <>&#8211;</>,
    'course-progress': student.course_progress_precentage ?? '',
    jobProfiles: student.job_profile_round || 'None',
    coachingRounds: student.coaching_rounds || 'None',
    status: student.user.is_active_two_weeks ? 'Active' : 'Inactive',
  }));

  return (
    <>
      <Header>
        <CardsInfo cards={studentCards} />
      </Header>

      {/* Filters Component */}
      <div className='w-full flex justify-between'>
        <StudentFilters
          count={count?.count}
          customIndicator={graduateStudentsIndicator}
        />
        <SearchInput placeholder='Search by student email' />
      </div>

      <div className='mt-6'>
        <ExportStudentsSuccessDialog />
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
            onRowClick={handleRowClick}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}

export default Students;

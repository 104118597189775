import React from 'react';
import { useTranslation } from 'react-i18next';
import eyouthLogo from '../../../assets/EYouthLogo.png';
import ItidaNewLogo from '../../../Components/svgs/ItidaNewLogo';

export default function LandingFooter() {
  const { t } = useTranslation();

  return (
    <div className='custom-container m-auto text-center py-8 my-8 bg-gradient-to-r from-[#9DCCFFB2] to-[#A3B4C8B2]'>
      <div className='flex flex-col gap-4 justify-center items-center'>
        <div>
          <h1 className='text-[#273C97] text-xl sm:text-3xl font-semibold mb-2'>
            {t('providedBy')}
          </h1>
          <div className='w-44 h-24'>
            <ItidaNewLogo className='cover-image h-auto w-auto' />
          </div>
        </div>

        <div>
          <h2 className='text-[#273C97] text-xl sm:text-3xl font-semibold mb-4'>
            {t('footerTitle')}
          </h2>
          <img src={eyouthLogo} alt='EYouth logo' className='cover-image' />
        </div>
      </div>
    </div>
  );
}

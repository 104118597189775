import { GoCheckCircleFill } from "react-icons/go";
import CustomButton from "../../../shared/CustomButton";
import { DialogProvider, useDialog } from "../../../shared/CustomDialog";
import ExportStudentButton from "./ExportStudentButton";

const ExportStudentsSuccessDialog = () => {
  return (
    <DialogProvider
      className="pb-[1rem] aspect-[4/3] min-w-[0] w-[26rem] flex-col"
      contentClassName="flex-1 flex flex-col items-center px-5"
      renderOpenButton={(openDialog) => <ExportStudentButton />}
    >
      <GoCheckCircleFill className="mt-[1rem] size-[4rem]" fill="#259800" />
      <h2 className="text-xl font-bold my-2">Success</h2>
      <p className="text-gray-5f text-lg text-center">
        Check your email for the exported students file.
      </p>
      <OkButton />
    </DialogProvider>
  );
};

export default ExportStudentsSuccessDialog;

const OkButton = () => {
  const { closeDialog } = useDialog();
  return (
    <CustomButton
      className="btn-primary w-full py-3 mb-3 flex items-center justify-center gap-2 whitespace-nowrap mt-auto"
      onClick={closeDialog}
    >
      Ok
    </CustomButton>
  );
};

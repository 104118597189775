import React from 'react';
import { useSearchParams } from 'react-router-dom';
import ReviewIcon from '../../../Components/svgs/QAanswered';
import Header from '../components/Header';

import useFetch from '../../../hooks/useFetch';
import usePaginationNumbers from '../../../hooks/usePaginationNumbers';
import {
  dashboardReviewsEndPoint,
  dashboardReviewsStatisticsEndPoint,
} from '../../../Services/endPoints';
import Table from '../Common/Table/Table';
import TableSkeleton from '../Common/Table/TableSkeleton';
import ReviewerFilters from '../components/ReviewsFilter';
import ReviewsCardInfo from '../../../Components/AdminDashboard/ReviewsPage/ReviewsCardInfo';

function Reviews() {
  const [searchParams] = useSearchParams();

  const dynamicReviewsEndpoint = `${dashboardReviewsEndPoint}?${searchParams.toString()}`;
  const { data: count } = useFetch(dynamicReviewsEndpoint);

  const {
    data: dashboardReviewsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicReviewsEndpoint);

  const { data: dashboardReviewsStatisticsData } = useFetch(
    dashboardReviewsStatisticsEndPoint
  );

  const tableData = dashboardReviewsData.map((review) => ({
    id: review.id,
    student: review.student,
    studentEmail: review.student_email,
    instructorEmail: review.instructor_email,
    deliverable: review.deliverable,
    deliverableTaskName: review.deliverable_task_name,
    coachingRounds: review.coaching_rounds,
    reviewFeedBackStatus: review.has_feedback ? 'Yes' : 'No',
    createdOn: new Date(review.created_on).toLocaleDateString(),
  }));

  const tableColumns = [
    { header: 'Review ID', accessor: 'id' },
    { header: 'Student ID', accessor: 'student' },
    { header: 'Student Email', accessor: 'studentEmail' },
    { header: 'Instructor Email', accessor: 'instructorEmail' },
    { header: 'Deliverable', accessor: 'deliverable' },
    { header: 'Task Name', accessor: 'deliverableTaskName' },
    { header: 'Coaching Rounds', accessor: 'coachingRounds' },
    { header: 'Reviewed', accessor: 'reviewFeedBackStatus' },
    { header: 'Created On', accessor: 'createdOn' },
  ];

  const reviewCards = [
    {
      img: <ReviewIcon className='size-[3rem] flex-shrink-0' />,
      title: 'Total Reviewed Proposals',
      value: dashboardReviewsStatisticsData?.all_proposal_count,
      aiReviewedCount: dashboardReviewsStatisticsData?.ai_proposal_count,
    },
    {
      img: <ReviewIcon className='size-[3rem] flex-shrink-0' />,
      title: 'Mockups Proposals',
      value: dashboardReviewsStatisticsData?.deliverables_count,
    },
    {
      img: <ReviewIcon className='size-[3rem] flex-shrink-0' />,
      title: 'Pending Mockups Proposals',
      value: dashboardReviewsStatisticsData?.pending_deliverables,
    },
    {
      img: <ReviewIcon className='size-[3rem] flex-shrink-0' />,
      title: 'Average Rating',
      value:
        dashboardReviewsStatisticsData?.average_feedback_degree?.toFixed(2) ||
        0,
    },
  ];

  return (
    <>
      <Header>
        <ReviewsCardInfo cards={reviewCards} />
      </Header>

      <ReviewerFilters count={count?.count} />

      <div className='mt-6'>
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : dashboardReviewsData.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </>
  );
}

export default Reviews;

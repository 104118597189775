import React, { useEffect, useState } from 'react';
import DateFilter from './DateFilter';
import FilterGroup from '../../../Components/AdminDashboard/shared/Filters/FilterGroup';
import { jobProfileRoundsEndPoint } from '../../../Services/endPoints';
import useFetch from '../../../hooks/useFetch';

const initialFilters = [
  {
    name: 'Date',
    key: 'date',
    component: <DateFilter />,
  },
  {
    name: 'Status',
    key: 'status',
    options: [
      { name: 'Completed', value: 'True' },
      { name: 'Upcoming', value: 'False' },
    ],
  },
  {
    name: 'Job Profile Code',
    key: 'job_profile_round',
  },
];

const WebinarsFilter = ({ count }) => {
  const [filters, setFilters] = useState(initialFilters);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case 'job_profile_round':
            return {
              ...filter,
              options: jobProfileRounds?.map((round) => round.code) || [],
            };
          default:
            return filter;
        }
      })
    );
  }, [jobProfileRounds]);

  return <FilterGroup filters={filters} count={count} />;
};

export default WebinarsFilter;

import dateFormat from "dateformat";
import { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Table from "../../../../NewPages/AdminDashboard/Common/Table/Table";
import TableSkeleton from "../../../../NewPages/AdminDashboard/Common/Table/TableSkeleton";
import Header from "../../../../NewPages/AdminDashboard/components/Header";
import { announcementsEndpoint } from "../../../../Services/endPoints";
import usePaginationNumbers from "../../../../hooks/usePaginationNumbers";
import { useAdminDataContext } from "../../../../providers/AdminDataProvider";
import CPAnnouncementsFilter from "../../../Announcements/CPAnnouncementsFilter";
import CPEditAnnouncementDialog from "../../../Announcements/CPEditAnnouncementDialog/CPEditAnnouncementDialog";
import CPPublishAnnouncementDialog from "../../../Announcements/CPPublishAnnouncementDialog/CPPublishAnnouncementDialog";

export default function CPAnnouncementsPage() {
  const [searchParams] = useSearchParams();
  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const { coachingRounds, jobProfiles } = useAdminDataContext();

  const announcementsEndpointWithParams = `${announcementsEndpoint}?${searchParams.toString()}`;
  const refetch = () => setRefetchTrigger((prev) => !prev);
  const {
    data: dashboardAnnouncementsData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(announcementsEndpointWithParams, refetchTrigger);

  const tableColumns = [
    { header: "ID", accessor: "id" },
    { header: "Title", accessor: "announcementTitle" },
    { header: "Date", accessor: "announcementDate" },
    { header: "Coaching Round", accessor: "coachingRound" },
    { header: "Job Profile", accessor: "jobProfile" },
    { header: "Status", accessor: "status" },
    { header: "Edit", accessor: "edit" },
  ];

  // Format table data
  const tableData =
    dashboardAnnouncementsData?.map((announcement) => ({
      id: announcement.id,
      announcementTitle: announcement.title,
      announcementDate: dateFormat(
        announcement?.created_at,
        "dddd, dd mmmm yyyy"
      ),
      coachingRound:
        announcement.coaching_rounds.length > 0 ? (
          announcement.coaching_rounds[0]
        ) : (
          <div
            className={`inline-flex items-center gap-1 px-3 py-2 rounded-full text-base font-bold`}
          >
            &#8211;
          </div>
        ),
      jobProfile:
        announcement.job_profile_rounds.length > 0 ? (
          announcement.job_profile_rounds[0]
        ) : (
          <div
            className={`inline-flex items-center gap-1 px-3 py-2 rounded-full text-base font-bold`}
          >
            &#8211;
          </div>
        ),
      status: announcement.status,
      edit: (
        <CPEditAnnouncementDialog
          announcement={announcement}
          codes={
            announcement.job_profile_rounds.length > 0
              ? jobProfiles
              : coachingRounds
          }
          title={
            announcement.job_profile_rounds.length > 0
              ? "Job Profile"
              : "Coaching Round"
          }
          paramName={
            announcement.job_profile_rounds.length > 0
              ? "job_profile_rounds"
              : "coaching_rounds"
          }
          refetch={refetch}
        />
      ),
    })) || [];

  return (
    <div className="p-4">
      <Header>
        <CPPublishAnnouncementDialog refetch={refetch} />
      </Header>
      {/* Filters Component */}
      <div className="w-full flex justify-between flex-wrap mt-6">
        <CPAnnouncementsFilter />
      </div>
      <div className="mt-6">
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        )}
      </div>
    </div>
  );
}

import { useMutation, useQueryClient } from "@tanstack/react-query";
import { apiPatchEditGig } from "../../../Services/Students/apiPatchEditGig";

export const useEditJob = () => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: apiPatchEditGig,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["gigs"] });
    },
  });
  return mutation;
};

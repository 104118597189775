import { Link, useSearchParams } from 'react-router-dom';
import SearchInput from '../../../../NewPages/AdminDashboard/Common/SearchInput/SearchInput';
import Table from '../../../../NewPages/AdminDashboard/Common/Table/Table';
import TableSkeleton from '../../../../NewPages/AdminDashboard/Common/Table/TableSkeleton';
import { dashboardStudentsEndPoint } from '../../../../Services/endPoints';
import { pageRoutes } from '../../../../constants/index';
import useFetch from '../../../../hooks/useFetch';
import usePaginationNumbers from '../../../../hooks/usePaginationNumbers';
import StudentFilters from '../../StudentPage/StudentFilters';

function CPStudentsPage() {
  const [searchParams] = useSearchParams();

  const dynamicStudentsPageEndpoint = `${dashboardStudentsEndPoint}?${searchParams.toString()}`;
  const { data: count } = useFetch(dynamicStudentsPageEndpoint);

  const {
    data: dashboardStudentsPageData,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
  } = usePaginationNumbers(dynamicStudentsPageEndpoint);

  const results = dashboardStudentsPageData || [];

  // Table Columns
  const tableColumns = [
    { header: 'Student ID', accessor: 'id' },
    { header: 'Name', accessor: 'name' },
    { header: 'Email', accessor: 'email' },
    { header: 'Phone', accessor: 'phone' },
    // { header: 'Technical Provider', accessor: 'techProvider' },
    { header: 'Job Profiles', accessor: 'jobProfiles' },
    { header: 'Coaching Rounds', accessor: 'coachingRounds' },
    { header: 'Status', accessor: 'status' },
    { header: 'Edit', accessor: 'edit' },
  ];

  // Map API Data to Table Rows
  const tableData = results.map((student) => ({
    id: student.id, // Student ID
    name: student.full_name, // Full Name
    email: student.user.email, // Email from user object
    phone: student.phone, // Phone
    // techProvider: student.technical_provider || 'N/A', // Technical Provider
    jobProfiles: student.job_profile_round || 'None', // Job Profile Round
    coachingRounds: student.coaching_rounds || 'None', // Coaching Rounds
    status: student.user.is_joined ? 'Active' : 'Inactive', // Status
    edit: (
      <Link
        to={`/${pageRoutes.controlPanelStudents}/${student.id}`}
        className='btn-primary px-[2rem] py-3'
      >
        Edit
      </Link>
    ),
  }));

  return (
    <div className='p-4'>
      {/* Filters Component */}
      <div className='flex items-start justify-between w-full'>
        <StudentFilters count={count?.count} />
        {/* <AdminUserInfo /> */}
      </div>

      <SearchInput placeholder='Search by student email' />
      <div className='mt-6'>
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : results.length > 0 ? (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
          />
        ) : (
          <p>No data available</p>
        )}
      </div>
    </div>
  );
}

export default CPStudentsPage;

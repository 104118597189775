import React, { useRef, useEffect } from "react";

const CommentsDialog = ({ isOpen, onClose, comment }) => {
  const dialogRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      dialogRef.current?.showModal();
    } else {
      dialogRef.current?.close();
    }
  }, [isOpen]);

  return (
    <dialog
      ref={dialogRef}
      className="fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[35rem] min-h-[10rem] border-none bg-[#fff] rounded-xl  outline-none p-7 overflow-visible shadow-lg"
    >
      {/* Header */}
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-lg text-[#3F3F3F] font-bold">Instructor Comment</h2>
        <button
          onClick={onClose}
          className="text-[#71747B] hover:text-gray-900"
        >
          ✖
        </button>
      </div>

      {/* Comment Content */}
      <p className="text-gray-700" dir="auto">
        {comment ? comment : "No additional comments provided."}
      </p>

      {/* Actions */}
      <div className="flex justify-end gap-2 mt-5">
        <button
          onClick={onClose}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600 transition"
        >
          OK
        </button>
      </div>
    </dialog>
  );
};

export default CommentsDialog;

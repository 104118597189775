import axios from "axios";
import { getFreelanceJobMyJobsEndPoint } from "./endPoints";

export const apiGetGigs = async (pageParam, params = {}) => {
  const { data } = await axios.get(`${getFreelanceJobMyJobsEndPoint}`, {
    params: {
      page: pageParam,
      ...params,
    },
  });
  return data;
};

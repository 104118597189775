import { useSearchParams } from "react-router-dom";
import { twMerge } from "tailwind-merge";
import Dropdown from "../../Components/shared/Dropdown";
import ArrowDown from "../../Components/svgs/ArrowDown";
import CloseIcon from "../../Components/svgs/CloseIcon";

const CodesFilter = ({ codes, paramName, title }) => {
  const [searchParams, setSearchParams] = useSearchParams();

  const selectedOption = searchParams.get(paramName);

  const handleSelectFilter = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value);
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };
  const deleteFilter = (e) => {
    e.stopPropagation();
    const updatedParams = new URLSearchParams(searchParams);
    updatedParams.delete(paramName);
    setSearchParams(updatedParams);
  };
  return (
    <div>
      <div className={twMerge("w-fit flex items-center gap-4 flex-wrap")}>
        <Dropdown
          menuClassName="z-[50]"
          renderOpenButton={(toggleMenu) => (
            <button
              onClick={toggleMenu}
              className="px-4 py-3 bg-white border border-solid border-gray-300 rounded-full text-sm font-medium flex items-center gap-4"
            >
              <span className="block">{title}</span>
              <ArrowDown className="size-[1rem] text-[#868686]" />
            </button>
          )}
        >
          {codes?.map((code) => (
            <div
              key={code}
              onClick={() => handleSelectFilter(paramName, code)}
              className="px-4 py-2 text-sm cursor-pointer hover:bg-gray-100"
            >
              {code}
            </div>
          ))}
        </Dropdown>
      </div>
      {selectedOption && (
        <div className="mt-4 flex items-center flex-wrap gap-3 pt-[1.5rem] ms-4 border-t border-[#CCD1D2]">
          <div className="flex items-center px-3 py-2 text-sm border border-gray-5f rounded-full">
            {selectedOption}
            <button
              onClick={deleteFilter}
              className="ml-2 text-gray-500 hover:text-red-500"
            >
              <CloseIcon className="size-[1rem]" />
            </button>
          </div>
        </div>
      )}
    </div>
  );
};

export default CodesFilter;

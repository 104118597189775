import React from 'react';

function Header({ children }) {
  return (
    <div className='mb-4 w-full'>
      <div className='mb-4 ms-auto w-fit tablet:block hidden'>
        {/* <AdminUserInfo /> */}
      </div>
      <div className='w-full'> {children}</div>
    </div>
  );
}

export default Header;

import axios from "axios";
import { Link, useNavigate, useParams } from "react-router-dom";
import YouTube from "react-youtube";
import LeftArrow from "../../../../Components/svgs/LeftArrow";
import RightArrow from "../../../../Components/svgs/RightArrow";
import { pageRoutes } from "../../../../constants";
import { CompleteLessonEndPoint } from "../../../../Services/endPoints";
import { useCourseLayoutContext } from "../CourseLayoutProvider";
import style from "./YoutubeLessonViewer.module.css";

function YoutubeLessonViewer() {
  const { id } = useParams();
  const navigate = useNavigate();
  const { courseData, courseLoading, courseError, setRevalidate } =
    useCourseLayoutContext();

  const courseLectures = courseData?.lessons || [];
  const currentLecture = courseLectures.find(
    (lecture) => lecture.id === parseInt(id)
  );
  const currentLectureIndex = courseLectures.findIndex(
    (lecture) => lecture.id === parseInt(id)
  );

  const handleVideoEnd = async () => {
    if (!currentLecture?.progress?.completed) {
      await axios.post(CompleteLessonEndPoint, {
        lesson: currentLecture.id,
      });
      setRevalidate((prev) => !prev);
    }
    if (currentLectureIndex < courseLectures.length - 1) {
      const nextLectureId = courseLectures[currentLectureIndex + 1].id;
      navigate(`/${pageRoutes.course}/${pageRoutes.lesson}/${nextLectureId}`);
    }
  };

  const handleNextLecture = async () => {
    if (currentLectureIndex < courseLectures.length - 1) {
      const nextLectureId = courseLectures[currentLectureIndex + 1].id;
      navigate(`/${pageRoutes.course}/${pageRoutes.lesson}/${nextLectureId}`);
    }
    if (!currentLecture?.progress?.completed) {
      await axios.post(CompleteLessonEndPoint, {
        lesson: currentLecture.id,
      });
      setRevalidate((prev) => !prev);
    }
  };

  const handlePrevLecture = () => {
    if (currentLectureIndex > 0) {
      const prevLectureId = courseLectures[currentLectureIndex - 1].id;
      navigate(`/${pageRoutes.course}/${pageRoutes.lesson}/${prevLectureId}`);
    }
  };
  if (!currentLecture?.video?.includes("youtube")) return null;
  if (!currentLecture) return <div>No lecture found.</div>;
  if (courseLoading) return <div>Loading...</div>;
  if (courseError) return <div>Error loading course data.</div>;

  const videoOptions = {
    playerVars: {
      autoplay: 1,
      controls: 1,
      rel: 0,
    },
  };
  function getYouTubeVideoId(url) {
    const urlParams = new URL(url).searchParams;
    return urlParams.get("v");
  }

  return (
    <div className={style.courseContainer}>
      <Link to="/" className={`w-fit hover:no-underline ${style.exitButton}`}>
        Exit Lesson
      </Link>

      <div className={style["youtube-video"]}>
        <YouTube
          videoId={getYouTubeVideoId(currentLecture?.video)}
          opts={videoOptions}
          onEnd={handleVideoEnd}
        />

        <button
          className={`py-2 px-1 bg-[#FFFFFF] absolute top-[50%] translate-y-[-50%] start-0  ${
            currentLectureIndex === 0 ? "cursor-not-allowed opacity-50" : ""
          }`}
          onClick={handlePrevLecture}
          disabled={currentLectureIndex === 0}
        >
          <LeftArrow className="size-[2rem]" />
        </button>
        <button
          className={`py-2 px-1 bg-[#FFFFFF] absolute top-[50%] translate-y-[-50%] end-0  ${
            currentLectureIndex === courseLectures.length - 1
              ? "cursor-not-allowed opacity-50"
              : ""
          }`}
          onClick={handleNextLecture}
        >
          <RightArrow className="size-[2rem]" />
        </button>
      </div>

      <div className={style.overviewSection}>
        <h2>{currentLecture.title}</h2>
        <p>{courseData.overview}</p>
      </div>
    </div>
  );
}

export default YoutubeLessonViewer;

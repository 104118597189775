import { Navigate, Route, Routes } from "react-router-dom";
import { pageRoutes } from "../constants";
import NotFoundPage from "./Common/NotFoundPage";
import InstructorLayout from "./InsturctorDashboard/Components/Common/InstructorLayout";
import Dashboard from "./InsturctorDashboard/Pages/Dashboard/Dashboard";
import QA from "./InsturctorDashboard/Pages/QA/QA";
import Reviews from "./InsturctorDashboard/Pages/Reviews/Reviews";
import Settings from "./Students/Settings/Settings";
import AnnouncementsPage from "./InsturctorDashboard/Pages/Announcements/AnnouncementsPage";
import GigsProposals from "./InsturctorDashboard/Pages/GigsProposals/GigsProposals";

const QAInstructorRoutes = () => {
  return (
    <Routes>
      <Route index element={<Navigate to={`/${pageRoutes.dashboard}`} />} />
      <Route element={<InstructorLayout />}>
        <Route path={pageRoutes.dashboard} element={<Dashboard />} />
        <Route path={pageRoutes.qa} element={<QA />} />
        <Route path={pageRoutes.reviews} element={<Reviews />} />
        <Route path={pageRoutes.gigsProposals} element={<GigsProposals />} />
        <Route
          path={pageRoutes.announcements}
          element={<AnnouncementsPage />}
        />
        <Route path={pageRoutes.settings} element={<Settings />} />
        <Route path="*" element={<NotFoundPage />} />
      </Route>
    </Routes>
  );
};

export default QAInstructorRoutes;

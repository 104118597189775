import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import { toast } from "react-toastify";
import * as yup from "yup";
import { getErrorMessage } from "../../../../Helpers/responseError";
import { apiPostNewGigs } from "../../../../Services/Students/apiPostNewGigs";

const schema = yup.object({
  title: yup.string().required("Title is required"),
  url: yup
    .string()
    .url("Enter a valid URL https://....")
    .matches(/^https:\/\//, "URL must start with https://")
    .required("URL is required"),
  category: yup.string().required("Category is required"),
  status: yup.string().required("Status is required"),
  price: yup.string().required("Price is required"),
  platform: yup.string().required("Platform is required"),
  proposal_file: yup.mixed().required("Proposal file is required"),
});

const useAddNewGigsForm = (closeDialogHandle) => {
  const queryClient = useQueryClient();
  const mutation = useMutation({
    mutationFn: apiPostNewGigs,
  });
  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(schema),
    mode: "onChange",
    defaultValues: {
      status: "Proposal Submitted",
    },
  });
  const onSubmit = (data) => {
    const formData = new FormData();

    formData.append("title", data.title);
    formData.append("url", data.url);
    formData.append("category", data.category);
    formData.append("status", "Proposal Submitted");
    formData.append("price", data.price);
    formData.append("platform", data.platform);

    formData.append("proposal_file", data.proposal_file[0]);

    mutation.mutate(formData, {
      onSuccess: () => {
        toast.success("Gig added successfully");
        queryClient.invalidateQueries({ queryKey: ["gigs"] });
        form.reset();
        if (typeof closeDialogHandle === "function") {
          closeDialogHandle();
        } else {
          console.error("closeDialog is not a function");
        }
      },
      onError: (err) => {
        const errors = getErrorMessage(err);
        console.error("Error: ", errors);
        if (!errors?.length) return;
        errors.forEach((error) => {
          form.setError(error.key, { message: error.message });
        });
      },
    });
  };

  return { ...form, isPending, onSubmit };
};

export default useAddNewGigsForm;

import { createContext, useContext } from "react";
import useFetch from "../hooks/useFetch";
import {
  CoachingRoundEndPoint,
  jobProfileRoundsEndPoint,
} from "../Services/endPoints";

const AdminDataContext = createContext(undefined);

export const AdminDataProvider = ({ children }) => {
  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfilesData } = useFetch(jobProfileRoundsEndPoint);
  const coachingRounds = coachingRoundsData?.map((round) => round.code) || [];

  const jobProfiles = jobProfilesData?.map((round) => round.code) || [];

  return (
    <AdminDataContext.Provider value={{ coachingRounds, jobProfiles }}>
      {children}
    </AdminDataContext.Provider>
  );
};

export const useAdminDataContext = () => {
  const context = useContext(AdminDataContext);
  if (!context) {
    throw new Error(
      "useAdminDataContext must be used within a AdminDataProvider"
    );
  }
  return context;
};

import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect, useState } from "react";
import { useInView } from "react-intersection-observer";
import { useSearchParams } from "react-router-dom";
import arrow from "../../../assets/arrow-down.svg";
import Loading from "../../../Components/shared/Loading";
import { isArabic } from "../../../Helpers/isArabic";
import useFetch from "../../../hooks/useFetch";
import { apiGetQuestions } from "../../../Services/apiGetQuestions";
import {
  categoryEndPoint,
  questionEndPoint,
} from "../../../Services/endPoints";
import AskQuestionDialog from "./AskQuestionDialog";
import DeleteQuestionDialog from "./DeleteQuestionDialog";
import styles from "./QA.module.css";
import {
  Accordion,
  AccordionContent,
  AccordionItem,
  AccordionTrigger,
} from "../../../Components/shared/Accordion";

export default function QA() {
  const { ref, inView } = useInView();
  const [searchParams, setSearchParams] = useSearchParams();
  const [selectedCategory, setSelectedCategory] = useState("");

  const filter = searchParams.get("filter") === "all" ? "all" : "my";
  const category = searchParams.get("category") || "";

  const filteredQuestionEndPoint =
    filter === "all"
      ? `${questionEndPoint}?all=true${category ? `&category=${category}` : ""}`
      : `${questionEndPoint}${category ? `?category=${category}` : "?"}`;

  const { data: categoryData } = useFetch(categoryEndPoint);

  const {
    data,
    isLoading,
    isFetching,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [
      "questions",
      searchParams.get("filter"),
      searchParams.get("category"),
    ],
    queryFn: ({ pageParam = "1" }) =>
      apiGetQuestions(pageParam, filteredQuestionEndPoint),
    initialPageParam: "1",
    refetchOnWindowFocus: false,
    getNextPageParam: (lastPage) => {
      if (lastPage.next === null) return undefined;
      const parsedUrl = new URL(lastPage.next);
      return parsedUrl.searchParams.get("page");
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);

  return (
    <div className="w-full mx-auto my-[2rem] md:px-[5%]">
      <AskQuestionDialog categoryData={categoryData} />
      <div className="flex mt-3 mb-6 gap-4 w-full justify-end items-end">
        <div>
          <label className="mb-2 block text-gray-500 font-medium">
            Filter by category
          </label>
          <select
            className="select-input w-60"
            style={{ backgroundImage: `url(${arrow})` }}
            onChange={(e) => {
              const updatedParams = new URLSearchParams(searchParams);
              updatedParams.set("category", e.target.value);
              setSearchParams(updatedParams);
              setSelectedCategory(e.target.value);
            }}
            value={selectedCategory}
          >
            <option value="">All</option>
            {categoryData?.map((category) => (
              <option key={category?.id} value={category?.id}>
                {category?.name}
              </option>
            ))}
          </select>
        </div>
        <div>
          <label className="mb-2 block text-gray-500 font-medium">
            Filter by type
          </label>
          <select
            className="select-input w-60"
            style={{ backgroundImage: `url(${arrow})` }}
            onChange={(e) => {
              const updatedParams = new URLSearchParams(searchParams);
              updatedParams.set("filter", e.target.value);
              setSearchParams(updatedParams);
            }}
            value={filter}
          >
            <option value="my">My Questions</option>
            <option value="all">All Questions</option>
          </select>
        </div>
      </div>
      <div className="w-full px-[2rem] py-5 rounded-lg flex flex-col gap-5 bg-[#f5f5f5]">
        {!isLoading &&
          data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page.results.length === 0 && (
                <p className="text-gray-5f text-center text-lg font-semibold mt-5">
                  No questions.
                </p>
              )}
              {page.results.map((question) => (
                <Accordion type="single" collapsible key={question.id}>
                  <AccordionItem
                    value={`panel${question.id}`}
                    className="bg-white p-4 rounded-md"
                  >
                    {question.answer && (
                      <>
                        <h3 className="border border-primary rounded text-[#273C97] px-3 py-1 flex items-center h-8 text-base ms-auto mb-3 w-fit">
                          {question.category.name}
                        </h3>
                        <AccordionTrigger
                          className="flex justify-between items-center w-fit hover:no-underline py-0"
                          style={{
                            direction: isArabic(question.question_text)
                              ? "rtl"
                              : "ltr",
                            textAlign: isArabic(question.question_text)
                              ? "right"
                              : "left",
                          }}
                        >
                          <p className="w-full">{question.question_text}</p>
                        </AccordionTrigger>
                      </>
                    )}
                    {!question.answer && (
                      <>
                        <div className="flex flex-wrap items-center gap-2 w-fit ms-auto mb-3">
                          <h3 className="border border-primary rounded text-[#273C97] px-3 p-1 flex items-center h-8 text-base">
                            {question.category.name}
                          </h3>
                          <h4 className="border-red-600 border rounded text-red-600 py-1 px-2 h-8 text-base">
                            Not Answered
                          </h4>
                          <DeleteQuestionDialog questionId={question.id} />
                        </div>
                        <p
                          className="w-full"
                          style={{
                            direction: isArabic(question.question_text)
                              ? "rtl"
                              : "ltr",
                            textAlign: isArabic(question.question_text)
                              ? "right"
                              : "left",
                          }}
                        >
                          {question.question_text}
                        </p>
                      </>
                    )}
                    <AccordionContent>
                      {question.answer && (
                        <p
                          className="mt-4"
                          style={{
                            direction: isArabic(question.answer.answer)
                              ? "rtl"
                              : "ltr",
                            textAlign: isArabic(question.answer.answer)
                              ? "right"
                              : "left",
                          }}
                        >
                          {question.answer.answer}
                        </p>
                      )}
                    </AccordionContent>
                  </AccordionItem>
                </Accordion>
              ))}
            </React.Fragment>
          ))}
        {(isFetchingNextPage || isFetching || isLoading) && <Loading />}
        {isError && (
          <p className="text-gray-5f text-center font-semibold w-full text-xl">
            Something went wrong!
          </p>
        )}
        <div ref={ref} style={{ height: "20px", background: "transparent" }} />
      </div>
    </div>
  );
}

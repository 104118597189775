import { useEffect, useState } from "react";
import useFetch from "../../../../hooks/useFetch";
import { freelancePlatformsEndpoint, getJobsCategoryEndPoint } from "../../../../Services/endPoints";

const initialOptions = {
  category: [],
  platform: [],
}

export const useGetSelectionData = () => {
  const { data: jobCategoryData } = useFetch(getJobsCategoryEndPoint);
  const { data: UserFreelancePlatforms } = useFetch(freelancePlatformsEndpoint);
  const [selectionOptions, setSelectionOptions] = useState(initialOptions);

  useEffect(() => {
    setSelectionOptions({
      category: jobCategoryData?.map((category) => ({
        name: category.name,
        value: category.id,
      })) ?? [],
      platform: UserFreelancePlatforms?.map((platform) => ({
        name: platform.name,
        value: platform.id,
      })) ?? [],
    });
  }, [jobCategoryData, UserFreelancePlatforms]);

  return {
    selectionOptions
  };
}
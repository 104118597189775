import React, { useRef, useState } from 'react';
import PlusIcon from '../../../svgs/PlusIcon';
import AddWebinarForm from './AddWebinarForm/AddWebinarForm';

export default function AddWebinarDialog() {
  const dialogRef = useRef(null);
  const [dialogOpen, setDialogOpen] = useState(false);

  const openDialog = () => {
    setDialogOpen(true);
    dialogRef.current?.showModal();
  };

  const closeDialog = () => {
    setDialogOpen(false);
    dialogRef.current?.close();
  };

  return (
    <>
      <span>
        <button
          onClick={openDialog}
          className='btn-primary font-bold text-lg p-6 text-[#F0F0F0] rounded-xl flex items-center justify-center gap-3'
        >
          <PlusIcon />
          Add New Webinar
        </button>
      </span>
      <dialog
        ref={dialogRef}
        className='fixed top-[50%] left-[50%] translate-x-[-50%] translate-y-[-50%] w-[30rem] min-h-[15rem] border-none bg-[#fff] rounded-xl outline-none p-5 overflow-visible'
      >
        <div className='flex justify-between items-center mb-4'>
          <h1 className='text-[#3F3F3F] font-bold text-base'>
            Add New Webinar
          </h1>
          <button
            onClick={closeDialog}
            className='text-[#71747B] hover:text-gray-900'
          >
            ✖
          </button>
        </div>
        {dialogOpen && (
          <div className='w-full text-left'>
            <AddWebinarForm dialogOpen={dialogOpen} closeDialog={closeDialog} />
          </div>
        )}
      </dialog>
    </>
  );
}

import { useSearchParams } from "react-router-dom";
import { jobProfileRoundsEndPoint } from "../../../Services/endPoints";
import useFetch from "../../../hooks/useFetch";
import { Chip, Input, MenuItem, Select, Stack } from "@mui/material";

const ProfileRoundFilter = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const { data: roundData } = useFetch(jobProfileRoundsEndPoint);

  const handleSelect = (key, value) => {
    const updatedParams = new URLSearchParams(searchParams);
    if (value) {
      updatedParams.set(key, value.join(","));
    } else {
      updatedParams.delete(key);
    }
    setSearchParams(updatedParams);
  };

  const selectedRoundCode =
    searchParams.get("job_profile_round")?.split(",")?.filter(Boolean) || [];

  return (
    <div className="w-fit mx-auto my-6">
      <Select
        multiple
        value={selectedRoundCode}
        displayEmpty
        onChange={(e) => handleSelect("job_profile_round", e.target.value)}
        input={
          <Input className="px-4 py-2 bg-white border border-gray-300 text-base font-medium flex items-center gap-4 before:hidden after:hidden min-w-[12rem] rounded-lg" />
        }
        renderValue={(selected) =>
          selected.length > 0 ? (
            <Stack gap={1} direction="row" flexWrap="wrap">
              {selected.map((value) => (
                <Chip key={value} label={value} />
              ))}
            </Stack>
          ) : (
            <span className="text-gray-400">Select Rounds</span>
          )
        }
      >
        {roundData?.map((round, index) => (
          <MenuItem key={index} value={round.code}>
            {round.code}
          </MenuItem>
        ))}
      </Select>
    </div>
  );
};

export default ProfileRoundFilter;

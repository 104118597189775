import React from 'react';
import LoginForm from './LoginForm';
import logo1 from '../../../assets/EYouthLogo.png';
import ItidaLogo from '../../../assets/logo.png';
import loginBg from '../../../assets/LoginBG.png';

const Login = () => {
  // const { i18n } = useTranslation();
  // useEffect(() => {
  //   i18n.changeLanguage('en');
  //   localStorage.setItem('i18nextLng', 'en');
  // }, [i18n]);

  return (
    <main
      className='bg-cover bg-no-repeat bg-center w-full h-screen flex'
      style={{ backgroundImage: `url(${loginBg})` }}
    >
      <div className='flex flex-col w-[30rem] mx-auto px-[2rem] py-[2.5rem] bg-gray-50 shadow-md rounded-lg m-auto'>
        <h1 className='text-primary text-[1.5rem] text-center font-bold mb-2'>
          LOGIN
        </h1>
        <h2 className='text-lg font-medium text-center'>Welcome Back!</h2>
        <p className='font-medium mb-3 text-base text-center'>
          Ready to Learn and Grow?
        </p>
        <LoginForm />
        <div className='flex gap-4 justify-center items-center mt-[2rem] mb-3'>
          <img className='w-32' src={logo1} alt='e-youth logo' />
          <img className='w-24' src={ItidaLogo} alt='mahara logo' />
        </div>
      </div>
    </main>
  );
};

export default Login;

import { useCookies } from "react-cookie";
import { useInView } from "react-intersection-observer";
import { useGetInstructorProfile } from "../../../../hooks/useGetInstructorProfile";

import { useInfiniteQuery } from "@tanstack/react-query";
import React, { useEffect } from "react";
import { queryKeys, roles } from "../../../../constants";
import { apiGetAllAnnouncements } from "../../../../Services/Announcements";

import CardSkeleton from "../../Components/Common/Card/CardSkeleton";
import { useSearchParams } from "react-router-dom";

import CustomButton from "../../../../Components/shared/CustomButton";
import { BsFillPlusSquareFill } from "react-icons/bs";
import CodesFilter from "../../../../Components/Announcements/CodesFilter";
import MutateAnnouncementsDialog from "../../../../Components/Announcements/MutateAnnouncementsDialog";
import AnnouncementsCard from "../../../../Components/Announcements/AnnouncementsCard";

const AnnouncementsPage = () => {
  const [searchParams] = useSearchParams();

  const [cookies] = useCookies(["role"]);
  const { instructorProfileData } = useGetInstructorProfile();
  const coachingCodes =
    instructorProfileData?.coaching_rounds?.map((round) => round.code) ?? [];
  const profileCodes =
    instructorProfileData?.job_profile_rounds?.map((round) => round.code) ?? [];
  const filter = {
    [roles.qaInstructorRole]: {
      codes: coachingCodes,
      title: "Coaching Round",
      paramName: "coaching_rounds",
    },
    [roles.webinarInstructorRole]: {
      codes: profileCodes,
      title: "Job Profile",
      paramName: "job_profile_rounds",
    },
    [roles.instructorRole]: {
      codes: profileCodes,
      title: "Job Profile",
      paramName: "job_profile_rounds",
    },
  };

  const codeParam = searchParams.get(filter[cookies.role].paramName)
    ? `${filter[cookies.role].paramName}=${searchParams.get(
        filter[cookies.role].paramName
      )}`
    : "";

  const dialogProps = {
    codes: filter[cookies.role].codes,
    title: filter[cookies.role].title,
    paramName: filter[cookies.role].paramName,
  };
  const { ref, inView } = useInView();

  const {
    data,
    isLoading,
    isError,
    fetchNextPage,
    hasNextPage,
    isFetchingNextPage,
  } = useInfiniteQuery({
    queryKey: [queryKeys.getAllAnnouncements, codeParam],
    queryFn: ({ pageParam = "1" }) =>
      apiGetAllAnnouncements(pageParam, codeParam),
    initialPageParam: "1",
    getNextPageParam: (lastPage) => {
      if (lastPage?.data?.next === null) return undefined;
      const parsedUrl = new URL(lastPage?.data?.next);
      const pageNumber = parsedUrl.searchParams.get("page");
      return pageNumber;
    },
  });

  useEffect(() => {
    if (inView && hasNextPage) {
      fetchNextPage();
    }
  }, [inView, hasNextPage, fetchNextPage]);
  if (isError) return <h1 className="text-center">Something went wrong</h1>;
  return (
    <div className="flex items-start justify-between flex-wrap md:px-[1rem] ">
      <CodesFilter
        codes={filter[cookies.role].codes}
        title={filter[cookies.role].title}
        paramName={filter[cookies.role].paramName}
      />
      <MutateAnnouncementsDialog
        dialogTitle="Publish Announcement"
        renderOpenButton={(openDialog) => (
          <CustomButton
            className="w-fit px-6 py-7 flex items-center justify-center gap-2 whitespace-nowrap rounded-md "
            onClick={openDialog}
          >
            <BsFillPlusSquareFill className="size-[1.2rem]" />
            Publish Announcement
          </CustomButton>
        )}
        {...dialogProps}
      />
      <div className="grid sm:grid-cols-2 grid-cols-1 w-full gap-[2rem]">
        {isLoading &&
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton />)}
        {!isLoading &&
          data?.pages?.map((page, pageIndex) => (
            <React.Fragment key={pageIndex}>
              {page?.data?.results?.map((announcement) => (
                <AnnouncementsCard
                  key={announcement.id}
                  dialogProps={dialogProps}
                  announcement={announcement}
                  codeName={filter[cookies.role].paramName}
                />
              ))}
            </React.Fragment>
          ))}

        {isFetchingNextPage &&
          Array(10)
            .fill(0)
            .map((_, index) => <CardSkeleton key={index} />)}
        <div ref={ref} style={{ height: "20px", background: "transparent" }} />
      </div>
    </div>
  );
};

export default AnnouncementsPage;

import React, { useRef, useState } from "react";
import { useOutsideClick } from "../../hooks/useClickOutside";

const Dropdown = ({
  renderOpenButton,
  containerClassName,
  menuClassName,
  children,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const dropdownRef = useRef(null);

  useOutsideClick(dropdownRef, () => setIsOpen(false));

  const handleToggle = () => setIsOpen((prev) => !prev);

  const handleItemClick = () => {
    setIsOpen(false);
  };

  return (
    <div
      ref={dropdownRef}
      className={`relative flex items-center justify-center ${containerClassName}`}
    >
      {renderOpenButton(handleToggle)}
      {isOpen && (
        <ul
          className={`list-none w-[12rem] flex flex-col gap-3 absolute top-[3.5rem] right-0 left-4 border border-gray-250 rounded-md shadow py-4 px-2 bg-white overflow-y-auto max-h-[25rem] ${menuClassName}`}
        >
          {React.Children.map(children, (child) =>
            React.cloneElement(child, {
              onClick: () => {
                child.props.onClick();
                handleItemClick();
              },
            })
          )}
        </ul>
      )}
    </div>
  );
};

export default Dropdown;

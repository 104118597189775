import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQueryClient } from "@tanstack/react-query";
import { Controller, useForm } from "react-hook-form";
import * as Yup from "yup";
import CustomButton from "../../../Components/shared/CustomButton";
import SelectInput from "../../../Components/shared/SelectInput";
import { toast } from "react-toastify";
import { apiPostQuestion } from "../../../Services/apiPostQuestion";
import { useDialog } from "../../../Components/shared/CustomDialog";
import { isArabic } from "../../../Helpers/isArabic";

const AskQuestionForm = ({ categoryData }) => {
  const mutation = useMutation({
    mutationFn: apiPostQuestion,
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
    },
  });
  const queryClient = useQueryClient();
  const { closeDialog } = useDialog();

  const validationSchema = Yup.object().shape({
    question_text: Yup.string()
      .required("Question text is required")
      .max(500, "Question must be at most 500 characters"),
    category: Yup.string().required("Category is required"),
  });

  const categoryOptions =
    categoryData?.map((category) => ({
      value: category.id,
      name: category.name,
    })) ?? [];

  const {
    register,
    control,
    handleSubmit,
    setError,
    formState: { errors },
  } = useForm({
    resolver: yupResolver(validationSchema),
    defaultValues: {
      question_text: "",
      category: "",
    },
  });

  const onSubmit = (data) => {
    mutation.mutate(
      data,

      {
        onSuccess: () => {
          toast.success("Question submitted successfully");
          closeDialog();
        },
        onError: (err) => {
          setError("root", {
            message: err.response.data.detail || "Some thing went wrong",
          });
          toast.error("Error submitting question");
        },
      }
    );
  };

  return (
    <form
      onSubmit={handleSubmit(onSubmit)}
      className="w-full flex flex-col gap-4 px-2 pb-4 py-5"
    >
      <SelectInput
        items={categoryOptions}
        className="border-[var(--fallback-bc,oklch(var(--bc)/0.2))]"
        name={"category"}
        label={
          <>
            Category <span className="text-[red]">*</span>
          </>
        }
        register={register}
        placeholderText="Select Category"
        errors={errors}
      />

      <div>
        <label className="text-[#3F3F3F] text-base font-medium mb-2 block">
          Question<span className="text-[red]">*</span>
        </label>
        <Controller
          name="question_text"
          control={control}
          render={({ field: { onChange, value } }) => (
            <textarea
              dir="auto"
              onChange={onChange}
              className={`textarea textarea-bordered resize-none block rounded w-full h-[10rem] ${
                errors["question_text"] ? "border-[red]" : ""
              } `}
              placeholder="Write your Question"
              style={{
                direction: isArabic(value) ? "rtl" : "ltr",
                textAlign: isArabic(value) ? "right" : "left",
              }}
            />
          )}
        />

        {errors["question_text"] && (
          <div className="text-[red] text-[0.8rem] font-light ml-3">
            {errors["question_text"].message}
          </div>
        )}
      </div>

      <CustomButton
        type="submit"
        isLoading={mutation.isPending}
        className="mt-4"
      >
        Submit
      </CustomButton>

      <p className="text-gray-5f self-start text-base font-semibold mt-1 mx-auto">
        <strong>Note:</strong> The response will be within 48 hours.
      </p>

      {errors.root && (
        <div className="border border-red-900 p-5 text-red-700">
          {errors.root.message}
        </div>
      )}
    </form>
  );
};

export default AskQuestionForm;

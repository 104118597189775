import { useMutation, useQueryClient } from "@tanstack/react-query";
import DeleteConfirmationDialog from "../../../Components/shared/DeleteConfirmationDialog/DeleteConfirmationDialog";
import { MdDelete } from "react-icons/md";
import { apiDeleteQuestion } from "../../../Services/Students/apiDeleteQuestion";

const DeleteQuestionDialog = ({ questionId }) => {
  const queryClient = useQueryClient();
  const deleteQuestionMutation = useMutation({
    mutationFn: () => apiDeleteQuestion(questionId),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ["questions"] });
    },
  });
  return (
    <DeleteConfirmationDialog
      name="question"
      renderOpenButton={(openDialog) => (
        <MdDelete
          className="text-[#E22A2A] size-[1.2rem] !cursor-pointer"
          onClick={openDialog}
        />
      )}
      deleteMutation={deleteQuestionMutation}
    />
  );
};

export default DeleteQuestionDialog;

import React, { useEffect, useMemo, useState } from 'react';
import FilterGroup from '../../../Components/AdminDashboard/shared/Filters/FilterGroup';
import useFetch from '../../../hooks/useFetch';
import {
  CoachingRoundEndPoint,
  jobProfileRoundsEndPoint,
} from '../../../Services/endPoints';

const initialFilters = [
  {
    name: 'Coaching Round',
    key: 'coaching_round',
  },
  {
    name: 'Mockups',
    key: 'deliverable',
  },
  {
    name: 'Reviewed',
    key: 'has_feedback',
    options: [
      {
        name: 'Yes',
        value: 'True',
      },
      {
        name: 'No',
        value: 'False',
      },
    ],
  },
  {
    name: 'Job Profile Code',
    key: 'job_profile_round',
  },
];

export default function ReviewsFilter({ count }) {
  const [filters, setFilters] = useState(initialFilters);
  const { data: coachingRoundsData } = useFetch(CoachingRoundEndPoint);
  const { data: jobProfileRounds } = useFetch(jobProfileRoundsEndPoint);
  const coachingRounds = Array.isArray(coachingRoundsData)
    ? coachingRoundsData
    : [];

  const staticMockups = useMemo(() => ['Q2', 'Q3'], []);

  useEffect(() => {
    setFilters((prevFilters) =>
      prevFilters.map((filter) => {
        switch (filter.key) {
          case 'coaching_round':
            return {
              ...filter,
              options: coachingRounds.map((round, index) => ({
                name: round.code || `Unknown ${index}`,
                value: round.code || `unknown_${index}`,
              })),
            };
          case 'job_profile_round':
            return {
              ...filter,
              options: jobProfileRounds?.map((round) => round.code) || [],
            };
          case 'deliverable':
            return {
              ...filter,
              options: staticMockups.map((mockup) => ({
                name: mockup,
                value: mockup,
              })),
            };
          default:
            return filter;
        }
      })
    );
  }, [
    JSON.stringify(coachingRounds),
    JSON.stringify(staticMockups),
    jobProfileRounds,
  ]);

  return <FilterGroup filters={filters} count={count} />;
}

import axios from "axios";
import React, { useState } from "react";
import { useCookies } from "react-cookie";
import { useNavigate, useSearchParams } from "react-router-dom";
import peopleImage from "../../../assets/people.png";
import DownloadCsvButton from "../../../Components/AdminDashboard/shared/DownloadCsvButton";
import { roles } from "../../../constants";
import useFetch from "../../../hooks/useFetch";
import usePaginationNumbers from "../../../hooks/usePaginationNumbers";
import {
  dashboardLeadsEndPoint,
  dashboardLeadsStatisticsEndPoint,
} from "../../../Services/endPoints";
import SearchInput from "../Common/SearchInput/SearchInput";
import Table from "../Common/Table/Table";
import TableSkeleton from "../Common/Table/TableSkeleton";
import AIReviewerFilter from "../components/AiReviwer/AIReviewerFilter";
import CardsInfo from "../components/CardsInfo";
import Header from "../components/Header";
import TakeActionDialog from "../../../Components/AdminDashboard/AIReviwer/TakeActionDialog/TakeActionDialog";

function AIReviewer() {
  const [cookies] = useCookies(["role"]);
  const [searchParams] = useSearchParams();

  const [refetchTrigger, setRefetchTrigger] = useState(false);
  const navigate = useNavigate();
  const dynamicLeadsEndpoint = `${dashboardLeadsEndPoint}?${searchParams.toString()}`;

  const {
    data: dashboardLeadsData,
    response,
    page,
    totalPages,
    isLoading,
    error,
    rowsPerPage,
    handlePageChange,
    setCurrentPage,
    nextPage,
    prevPage,
    setRowsPerPage: handleRowsPerPageChange,
    refetch,
  } = usePaginationNumbers(dynamicLeadsEndpoint, refetchTrigger);

  const { data: dashboardStudentStatisticsData } = useFetch(
    dashboardLeadsStatisticsEndPoint,
    [refetchTrigger]
  );
  const reviewerCards = [
    {
      img: <img src={peopleImage} alt="people-image" />,
      title: "Leads",
      value: dashboardStudentStatisticsData?.lead_count,
    },
    {
      img: <img src={peopleImage} alt="people-image" />,
      title: "Pending",
      value: dashboardStudentStatisticsData?.pending_leads,
    },
    {
      img: <img src={peopleImage} alt="people-image" />,
      title: "Accepted",
      rejectedNumber: dashboardStudentStatisticsData?.rejected_leads,
      value: dashboardStudentStatisticsData?.accepted_leads,
    },
  ];

  const handleStatusUpdate = async (id, newStatus) => {
    try {
      const response = await axios.patch(
        `https://dfppback.eyouthlearning.com/api/leads-status/${id}/`,
        { status: newStatus }
      );

      if (response.status === 200) {
        setRefetchTrigger((prev) => !prev);
        refetch();
      }
    } catch (error) {
      console.error("Error updating status:", error);
    }
  };

  const handleRowClick = (row) => {
    navigate(`/ai-interviewing/${row.id}`);
  };

  const tableColumns = [
    { header: "ID", accessor: "id" },
    { header: "Student Name", accessor: "name" },
    { header: "Email", accessor: "email" },
    { header: "Phone", accessor: "phone" },
    { header: "Technical Track", accessor: "track" },
    {
      header: "English Score (Video)",
      accessor: "score_video",
    },
    {
      header: "English Score (Text)",
      accessor: "score_text",
    },
    {
      header: "Technical Score",
      accessor: "score_questions",
    },
    {
      header: "Status",
      accessor: "status",
    },
  ];
  const actionsColumn = {
    header: "Action",
    accessor: "action",
    Cell: ({ row }) => (
      <select
        value={row.original.status}
        onClick={(e) => e.stopPropagation()} // Prevent row click event
        onChange={(e) => {
          e.stopPropagation(); // Prevent row click event
          handleStatusUpdate(row.original.id, e.target.value); // Update status
          setRefetchTrigger((prev) => !prev);
        }}
        className="border border-gray-300 rounded p-1 text-sm"
      >
        <option value="Pending">Pending</option>
        <option value="Accepted">Accepted</option>
        <option value="Rejected">Rejected</option>
      </select>
    ),
  };
  if (cookies.role === roles.adminEditor) {
    tableColumns.push(actionsColumn);
  }

  const tableData =
    dashboardLeadsData?.map((lead) => ({
      id: lead.id,
      name: lead.name,
      email: lead.email,
      phone: lead.phone,
      track: lead.track,
      score_questions: lead.score_questions,
      score_video: lead.score_video,
      score_text: lead.score_text,
      status: lead.status,
    })) || [];

  return (
    <>
      <Header>
        <CardsInfo cards={reviewerCards} />
      </Header>
      <SearchInput placeholder="Search by student email" />
      <div className="flex justify-between flex-wrap gap-y-5 my-3">
        <AIReviewerFilter count={response?.count} />
      </div>

      <div className="w-full flex items-center justify-between mt-6">
        {searchParams.toString() !== "" && (
          <TakeActionDialog leadsCount={response?.count} />
        )}
        <DownloadCsvButton
          text={"Export Leads"}
          endpoint={dashboardLeadsEndPoint}
          csvFileName={"leads"}
        />
      </div>
      <>
        {isLoading ? (
          <TableSkeleton columns={tableColumns.length} rows={5} />
        ) : error ? (
          <p>Error fetching data</p>
        ) : (
          <Table
            columns={tableColumns}
            data={tableData}
            rowsPerPageOptions={[5, 10, 20]}
            isLoading={isLoading}
            currentPage={page}
            totalPages={totalPages}
            rowsPerPage={rowsPerPage}
            setRowsPerPage={handleRowsPerPageChange}
            setCurrentPage={setCurrentPage}
            handlePageChange={handlePageChange}
            prevPage={prevPage}
            nextPage={nextPage}
            handleActionChange={handleStatusUpdate}
            onRowClick={handleRowClick}
          />
        )}
      </>
    </>
  );
}

export default AIReviewer;

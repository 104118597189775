import { yupResolver } from "@hookform/resolvers/yup";
import { useMutation, useQuery } from "@tanstack/react-query";
import { useForm } from "react-hook-form";
import * as yup from "yup";

import { toast } from "react-toastify";
import { apiPostCreateJobProfileCode } from "../../../../../../Services/ControlPanel/apiPostCreateJobProfileCode";
import { apiGetInstructors } from "../../../../../../Services/AdminDashboard/apiGetInstrcutors";
import useFetch from "../../../../../../hooks/useFetch";
import { instructorChoicesEndPoint } from "../../../../../../Services/endPoints";

const createNewCoachSchema = yup.object({
  code: yup.string().required("Job Profile Code is required"),
  coachCode: yup.string().required("Coach Code is required"),
  name: yup
    .string()
    .required("Name is required")
    .min(3, "Name must be at least 3 characters"),
  email: yup
    .string()
    .required("Email is required")
    .email("Invalid email address"),
  phoneNumber: yup
    .string()
    .required("Phone number is required")
    .min(10, "Phone number must be at least 11 characters")
    .max(13, "Phone number must be at most 13 characters"),
  password: yup
    .string()
    .required("Password is required")
    .min(8, "Too short password")
    .max(30, "Too long password"),
  confirm_password: yup
    .string()
    .oneOf([yup.ref("password"), null], "Passwords must match")
    .required("Confirm Password is required"),
});

const existingCoachSchema = yup.object({
  code: yup.string().required("Job Profile Code is required"),
  id: yup.string().required("Email is required"),
});

export function useCreateJobProfileForm(activeView) {
  const { data: coachList } = useFetch(instructorChoicesEndPoint);
  const mutation = useMutation({
    mutationFn: apiPostCreateJobProfileCode,
  });
  const { data } = useQuery({
    queryKey: ["instructors"],
    queryFn: apiGetInstructors,
  });

  const coachEmailList =
    coachList?.map((item) => ({
      name: item.email,
      value: item.id,
    })) ?? [];

  const { isPending } = mutation;
  const form = useForm({
    resolver: yupResolver(
      activeView === "existing" ? existingCoachSchema : createNewCoachSchema
    ),
    mode: "onChange",
  });
  const onSubmit = async (data) => {
    let requestBody = {};
    if (activeView === "existing") {
      requestBody = {
        code: data.code,
        instructor: {
          id: data.id,
          role: "1",
        },
      };
    } else {
      requestBody = {
        code: data.code,
        email: data.email,
        phoneNumber: data.phoneNumber,
        password: data.password,
        confirm_password: data.confirm_password,
        instructor: {
          id: data.coachCode,
          name: data.name,
          email: data.email,
          phone: data.phoneNumber,
          role: "1",
        },
      };
    }
    mutation.mutate(requestBody, {
      onSuccess: () => {
        toast.success("Job Profile created successfully");
        form.reset();
      },
      onError: (err) => {
        form.setError("root", {
          message: err.response.data.message || "Some thing went wrong",
        });
      },
    });
  };
  return { ...form, isPending, onSubmit, coachEmailList };
}
